import memoized from '../memoized';

const VALID_EMAIL = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)+$/;

export const EmailFactory = memoized(message => input => {
  if (!input) {
    return undefined;
  }
  return VALID_EMAIL.test(input) ? undefined : message || true;
});

export default EmailFactory;
