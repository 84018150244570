import React from 'react';
import { PageSection } from 'adc-ui-components';
import PageTitle from '../layout/PageTitle';

const Ineligible = () => {
  return (
    <PageSection className="page-section--banner">
      <PageTitle
        title="You're not currently eligible for a Regional Sports Network courtesy adjustment"
        titleClass="mb12"
        noFocus
      />
      <p className="body2 mb24">
        You can learn more about customer eligibility on our FAQ page
      </p>
      <a
        href="https://xfinity.com/sportsadjustments"
        rel="noopener noreferrer"
        className="button button--primary"
      >
        Learn More
      </a>
    </PageSection>
  );
};

export default Ineligible;
