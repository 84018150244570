import React from 'react';
import { useSelector } from 'react-redux';
import { PageSection } from 'adc-ui-components';
import PageTitle from '../../../components/layout/PageTitle';
import RefundHistoryCard from '../../../components/refund/RefundHistoryCard';
import { fallbackCancelUrl } from '../../../helpers/SportsRefundHelpers';

const Confirmation = () => {
  const {
    cancelUrl,
    states: { refund = [], authorization = 'LITE' } = {},
  } = useSelector(state => state.sportsRefund);
  const doneDestination = cancelUrl || fallbackCancelUrl;

  const requestedRefunds = refund.filter(item => item.status === 'requested');
  const sortedPendingRequests = requestedRefunds.sort(
    (a, b) => new Date(b.request.date) - new Date(a.request.date),
  );

  return (
    <PageSection className="page-section--banner">
      <PageTitle
        title="Your Regional Sports Network courtesy adjustment has been requested!"
        subtitle="The adjustment will be issued within 4-6 weeks from the request date."
        titleClass="mb12"
        noFocus
      />
      <div className="text-center">
        <div className="action__item">
          <a className="button button--primary" href={doneDestination}>
            Done
          </a>
        </div>
      </div>
      <RefundHistoryCard
        refundHistory={sortedPendingRequests}
        authorization={authorization !== 'LITE'}
      />
    </PageSection>
  );
};
export default Confirmation;
