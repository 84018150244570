import {
  GET_USER,
  GET_USER_SUCCESS,
  GET_USER_FAILURE,
} from '../actions/userActions';

const INITIAL_STATE = { loading: true, error: false };

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_USER:
      return { ...state, loading: true, error: false };
    case GET_USER_SUCCESS:
      return { ...state, loading: false, data: action.payload };
    case GET_USER_FAILURE:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
}
