import { combineReducers } from 'redux';
import session from './sessionReducer';
import uploadDocuments from '../../apps/Bereavement/uploadDocumentsReducer';
import bereavement from '../../apps/Bereavement/bereavementReducer';
import typeAhead from './typeAheadReducer';
import user from './user';
import userMessages from './userMessagesReducer';
import reportIssue from '../../apps/ReportIssue/reducers/ReportIssueReducer';
import cms from './cms';
import credits from '../../apps/Credits/CreditsReducer';
import sportsRefund from '../../apps/SportsRefund/Reducer/SportsRefundReducer';

const rootReducer = combineReducers({
  session,
  bereavement,
  uploadDocuments,
  typeAhead,
  user,
  userMessages,
  reportIssue,
  cms,
  credits,
  sportsRefund,
});

export default rootReducer;
