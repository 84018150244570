import React from 'react';

const ConfirmationIcon = () => (
  <span className="mb24">
    <svg
      width="42"
      height="42"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
      aria-hidden="true"
    >
      <g fill="none" fillRule="evenodd">
        <path
          d="M21 0C9.402 0 0 9.402 0 21s9.402 21 21 21 21-9.402 21-21A21 21 0 0021 0"
          fill="#65D0A7"
        />
        <path
          d="M31.493 12.793a1 1 0 011.497 1.32l-.083.094-15 15a1 1 0 01-1.32.083l-.094-.083-6-6a1 1 0 011.32-1.497l.094.083 5.293 5.292 14.293-14.292z"
          fill="#FFF"
          fillRule="nonzero"
        />
      </g>
    </svg>
  </span>
);
export default ConfirmationIcon;
