import React from 'react';
import { joinClass } from '../helpers/component';

const CardGroup = ({ children, className }) => {
  const makeItem = (child, index) =>
    child && (
      <div className="card-group__item" key={index}>
        {child}
      </div>
    );

  return (
    <div className={joinClass('card-group', className)}>
      {Array.isArray(children) &&
        children.map((child, index) => makeItem(child, index))}
      {!Array.isArray(children) && makeItem(children)}
    </div>
  );
};

export default CardGroup;
