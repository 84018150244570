import React from 'react';
import { FormControl } from 'adc-ui-components';
import { Field } from 'formik';

import SelectField from '../form-elements/SelectField';
import { RequiredFactory } from '../../helpers/validation/required';
import issueDetailsData from '../../apps/ReportIssue/data/issueDetails';

const vRequired = RequiredFactory();

const SelectIssueDropdown = ({
  validateSelection = vRequired('Please select an option to continue'),
  label = 'Select the option closest to your issue',
}) => {
  const issueOptions = issueDetailsData['HAZARDOUS WIRES'];
  return (
    <div className="select-issue-dropdown">
      <FormControl
        inputId="issueDetails"
        label={label}
        className="form-control"
      >
        <Field
          id="issueDetails"
          name="issueDetails"
          component={SelectField}
          validate={validateSelection}
        >
          <option value="">Select one</option>
          {issueOptions.map(option => (
            <option key={option.id} value={option.option}>
              {option.option}
            </option>
          ))}
        </Field>
      </FormControl>
    </div>
  );
};

export default SelectIssueDropdown;
