import mtmClient from '../../../fetch/apiClient';
import { getIdmAuth } from '../../../helpers/auth';
import { SportsRefund } from '../../../api/apis';
import { dispatchSetModal } from '../../../shared/actions/userMessagesActions';
import { RETRY_ERROR } from '../../../components/modals/ModalHandler';

export const ISSUE_SPORTS_REFUND = 'ISSUE_SPORTS_REFUND';
export const ISSUE_SPORTS_REFUND_SUCCESS = 'ISSUE_SPORTS_REFUND_SUCCESS';
export const ISSUE_SPORTS_REFUND_FAILURE = 'ISSUE_SPORTS_REFUND_FAILURE';

export const issueSportsRefund = payload => async dispatch => {
  try {
    const { accessToken } = await getIdmAuth();
    const response = await dispatch(
      dispatchIssueSportsRefund(accessToken, payload),
    ).payload;
    return await dispatch(dispatchIssueSportsRefundSuccess(response));
  } catch (error) {
    await dispatch(dispatchIssueSportsRefundFailure(error));
    dispatch(dispatchSetModal({ showModal: true, type: RETRY_ERROR }));
    throw error;
  }
};

const dispatchIssueSportsRefund = (accessToken, payload) => {
  return {
    type: ISSUE_SPORTS_REFUND,
    payload: mtmClient.fetch(SportsRefund, {
      sessionRequired: false,
      body: JSON.stringify(payload),
      method: 'POST',
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }),
  };
};

const dispatchIssueSportsRefundSuccess = payload => ({
  type: ISSUE_SPORTS_REFUND_SUCCESS,
  payload,
});

const dispatchIssueSportsRefundFailure = payload => ({
  type: ISSUE_SPORTS_REFUND_FAILURE,
  payload,
});
