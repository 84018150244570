import React from 'react';
import { FormControl } from 'adc-ui-components';
import { Field } from 'formik';
import SelectField from '../../form-elements/SelectField';

const Country = ({ label, className, ...props }) => (
  <FormControl inputId="country" label={label} className={className}>
    <Field
      id="country"
      type="text"
      name="country"
      value="USA"
      disabled
      component={SelectField}
      {...props}
    >
      <option value="USA">United States</option>
    </Field>
  </FormControl>
);

export default Country;
