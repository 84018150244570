import React from 'react';
import { PageSection } from 'adc-ui-components';
import IconCircleExclamation from '../../components/svgs/IconCircleExclamation';

const PermsError = () => {
  return (
    <PageSection className="ui-grey page-section--banner">
      <IconCircleExclamation className="mb6 svg-icon--42" />

      <div className="hgroup">
        <h1 tabIndex="-1">
          This option is currently available to primary account holders
        </h1>
        <p>
          Please reach out to your primary account holder to report an issue.
        </p>
      </div>
      <a
        className="button button--primary"
        href="https://customer.xfinity.com/"
      >
        Go to My Account
      </a>
    </PageSection>
  );
};

export default PermsError;
