import mtmClient from '../../../fetch/apiClient';
import { CreateIssueEROneTicketAPI } from '../../../api/apis';
import { getOauth } from '../../../helpers/auth';

export const SUBMIT_USER_INFO = 'SUBMIT_USER_INFO';
export const SUBMIT_ISSUE_TYPE = 'SUBMIT_ISSUE_TYPE';
export const POST_ISSUE_TICKET = 'POST_ISSUE_TICKET';
export const POST_ISSUE_TICKET_SUCCESS = 'POST_ISSUE_TICKET_SUCCESS';
export const POST_ISSUE_TICKET_FAILURE = 'POST_ISSUE_TICKET_FAILURE';

// Returning payloads for the respective actions.
export const submitIssueType = value => ({
  type: SUBMIT_ISSUE_TYPE,
  payload: { ...value },
});

export const submitUserInfo = userInfo => {
  return {
    type: SUBMIT_USER_INFO,
    payload: { ...userInfo },
  };
};

export const dispatchPostIssueTicket = (issueInfo, accessToken) => {
  return {
    type: POST_ISSUE_TICKET,
    payload: mtmClient.fetch(CreateIssueEROneTicketAPI, {
      method: 'POST',
      body: JSON.stringify(issueInfo),
      sessionRequired: false,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }),
  };
};

const dispatchPostIssueTicketSuccess = payload => ({
  type: POST_ISSUE_TICKET_SUCCESS,
  payload,
});
const dispatchPostIssueTicketFailure = payload => ({
  type: POST_ISSUE_TICKET_FAILURE,
  payload,
});

export const postIssueTicket = data => async dispatch => {
  try {
    const { accessToken } = await getOauth();
    const response = await dispatch(dispatchPostIssueTicket(data, accessToken))
      .payload;
    return dispatch(dispatchPostIssueTicketSuccess(response));
  } catch (error) {
    dispatch(dispatchPostIssueTicketFailure(error));
    throw error;
  }
};

export const submitIssuePassedByChannels = () => async (dispatch, getState) => {
  try {
    const issueType = 'HAZARDOUS WIRES';

    const {
      user: {
        data: { contactEmail, preferredEmail, mobileNumber = '' } = {},
      } = {},
      cms: { reportIssue: { selectIntent: { intents } = [] } } = {},
    } = getState();

    const getIntentFromCMS = intents.find(item => item.variable === issueType);

    if (Object.keys(getIntentFromCMS).length === 0) {
      throw new Error('Could not find the issue type passed by the channel');
    }

    const { text: problemLabel, variable: problemType } = getIntentFromCMS;

    // passing deeplinked here so we can check it on the details form
    dispatch(submitIssueType({ problemType, problemLabel, deeplinked: true }));
    dispatch(
      submitUserInfo({
        email: contactEmail || preferredEmail || '',
        mobile: mobileNumber || '',
      }),
    );
  } catch (error) {
    throw error;
  }
};
