import React from 'react';

export default ({ className = '', ...props }) => (
  <span className={`svg-icon ${className}`} {...props}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      data-name="Layer 1"
      viewBox="0 0 48.5 48.5"
      role="img"
      aria-hidden="true"
    >
      <defs>
        <linearGradient
          id="a"
          x1="31.33"
          x2="-5.83"
          y1="18.67"
          y2="55.83"
          gradientTransform="matrix(1 0 0 -1 0 50)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#41e6d1" />
          <stop offset="1" stopColor="#a9f4ea" />
        </linearGradient>
        <linearGradient
          id="b"
          x1="23.51"
          x2="-13.63"
          y1="10.85"
          y2="48"
          gradientTransform="matrix(1 0 0 -1 0 50)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#1ee2c9" />
          <stop offset="1" stopColor="#41e6d1" />
        </linearGradient>
      </defs>
      <circle cx="24.5" cy="24.5" r="21.9" fill="url(#a)" />
      <path
        fill="url(#b)"
        d="M39.8 40.1l-31-31a21.85 21.85 0 0 0 15.6 37.2 21.44 21.44 0 0 0 15.4-6.2z"
      />
      <path
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="3"
        d="M15.1 26.4l4.9 5 13.8-13.8"
      />
    </svg>
  </span>
);
