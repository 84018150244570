import React from 'react';
import { Formik, Form } from 'formik';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import FirstLastName from '../form-controls/FirstLastNameField';
import Email from '../form-controls/fields/Email';
import AccountNumber from '../form-controls/fields/AccountNumber';
import MobilePhone from '../form-controls/fields/MobilePhone';
import Address from '../form-controls/fields/Address';
import NavigationButtons from '../NavigationButtons';
import { submitAccountHolderInfo } from '../../apps/Bereavement/bereavementActions';
import { formatPhone } from '../../helpers/validation/phone';
import FocusErrorField from '../../helpers/FocusErrorField';

import {
  BEREAVEMENT_UPLOAD_DOCUMENTS,
  BEREAVEMENT_REPRESENTATIVE_INFO,
} from '../../helpers/routes';

const AccountHolderForm = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const bereavementDetails = useSelector(state => state.bereavement);
  const { addressResults, loading } = useSelector(state => state.typeAhead);
  const { customer: accInfo } = bereavementDetails || null;
  // Order required to show the focus in particular error field
  const initialFormValues = {
    accountNumber: '',
    firstName: '',
    lastName: '',
    phone: '',
    email: '',
    fullStreetAddress: '',
  };
  let initialValues;
  if (!accInfo) {
    initialValues = initialFormValues;
  } else {
    initialValues = {
      ...accInfo,
      phone: accInfo.phone !== '' && formatPhone(accInfo.phone),
    };
  }
  const onSubmit = accountHolderFormValues => {
    let formValuesToSubmit = {};
    // Filtering out the blank key values.
    for (const key of Object.keys(accountHolderFormValues)) {
      if (accountHolderFormValues[key] !== '') {
        formValuesToSubmit[key] = accountHolderFormValues[key];
      }
    }
    // if the user types a phone number it will be in xxx-xxx-xxxx format. We want to put it
    // in xxxxxxxxxx format before we submit
    if (formValuesToSubmit.phone) {
      formValuesToSubmit = {
        ...formValuesToSubmit,
        phone: formValuesToSubmit.phone.replace(/-/g, ''),
      };
    }
    // Dispatching the form values to get it saved into the store.
    dispatch(submitAccountHolderInfo(formValuesToSubmit));
    return history.push(`${BEREAVEMENT_REPRESENTATIVE_INFO}`);
  };

  const validate = formValues => {
    const errors = {};
    const values = {
      firstName: formValues.firstName?.trim(),
      lastName: formValues.lastName?.trim(),
      fullStreetAddress: formValues.fullStreetAddress?.trim(),
      phone: formValues.phone?.trim(),
    };
    if (!values.firstName) {
      errors.firstName = 'Please enter the first name';
    }
    if (!values.lastName) {
      errors.lastName = 'Please enter the last name';
    }
    if (values.phone && values.phone.length < 12) {
      errors.phone = 'Please enter a valid phone number';
    }
    if (!values.fullStreetAddress) {
      errors.fullStreetAddress = 'Please enter the service address';
    } else if (
      values.fullStreetAddress &&
      addressResults &&
      !addressResults.length
    ) {
      errors.fullStreetAddress = 'Please select a valid address';
    } else if (
      values.fullStreetAddress &&
      addressResults &&
      addressResults.length &&
      !addressResults.some(
        address => address.trim() === values.fullStreetAddress,
      )
    ) {
      errors.fullStreetAddress =
        'Please select an address from the list provided';
    }
    return errors;
  };

  return (
    <Formik
      initialValues={initialValues}
      validate={validate}
      onSubmit={onSubmit}
      validateOnBlur={false}
    >
      <Form noValidate>
        <div className="card-group text-align-left mb30">
          <div className="card-group__item">
            <div className="card">
              <div className="card__content">
                <AccountNumber label="Account number" optional />
                <FirstLastName
                  classLastName="mb0"
                  labelFirstName="Account holder's first name"
                  labelLastName="Account holder's last name"
                />
              </div>
            </div>
          </div>
          <div className="card-group__item">
            <div className="card">
              <div className="card__content">
                <div className="form-control-group form-control-group--flex-at-768">
                  <div className="form-control-group__item">
                    <MobilePhone
                      label="Account holder's phone number"
                      optional
                      formatPhone={formatPhone}
                    />
                  </div>
                  <div className="form-control-group__item">
                    <Email
                      className="mb0"
                      label="Account holder's email address"
                      optional
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="card-group__item">
            <div className="card">
              <div className="card__content">
                <div
                  className={`${loading &&
                    'form-control-group form-control-group-loading--flex-at-768'}`}
                >
                  <div className={`${loading && 'form-control-group__item'}`}>
                    <Address
                      className="pb6"
                      label="Service address"
                      origin="eloc"
                    />
                  </div>
                  {loading && (
                    <div className="form-control-group__item-loading">
                      <div className="loading-circle" />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <FocusErrorField fieldOrders={Object.keys(initialFormValues)} />
        <NavigationButtons
          labelForNext="Continue"
          labelForPrevious="Back"
          navLinkForBack={BEREAVEMENT_UPLOAD_DOCUMENTS}
        />
      </Form>
    </Formik>
  );
};

export default AccountHolderForm;
