import React from 'react';
import { useSelector } from 'react-redux';
import RefundIneligible from '../../../components/refund/RefundIneligible';
import ActiveUserIneligible from '../../../components/refund/ActiveUserIneligible';
import getConfig from '../../../config';

const InEligible = () => {
  const {
    states: { accountStatus = '' } = {},
    segments: { data: xapiSegments = [] } = {},
  } = useSelector(state => state.sportsRefund);

  const { env } = getConfig();

  const getXapiEligibility = seg =>
    seg && seg.some(item => item.name === 'helpandsupport-issue-sports-refund');

  const isEnvEligible = env !== 'pre-prod' || env !== 'production';

  const isSegmentEligible = !isEnvEligible
    ? getXapiEligibility(xapiSegments)
    : true;

  return accountStatus === 'CURRENT' && isSegmentEligible ? (
    <ActiveUserIneligible />
  ) : (
    <RefundIneligible />
  );
};

export default InEligible;
