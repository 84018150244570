import React from 'react';
import { PageSection, FormBoolean } from 'adc-ui-components';
import { useHistory } from 'react-router-dom';
import { Field, Formik, Form } from 'formik';
import { useSelector } from 'react-redux';
import NavigationButtons from '../../../components/NavigationButtons';
import BooleanField from '../../../components/form-elements/BooleanField';
import {
  VIDEO_DETAILS,
  OUTAGE_DETAILS,
  // LATE_FEE_CREDITS,
  REPORT_ISSUE,
} from '../../../helpers/routes';
import CardGroup from '../../../components/CardGroup';
import FocusErrorFirstBoolean from '../../../helpers/FocusErrorFirstBoolean';
import PageTitle from '../../../components/layout/PageTitle';

const CreditSelection = () => {
  const history = useHistory();
  const { eligibility = {} } = useSelector(state => state.credits);
  const { showOutageOption, showVideoOption } = eligibility;
  const creditOptions = [
    {
      creditLabel: 'On Demand purchase or rental',
      creditId: 'videoCredits',
      isDisabled: !showVideoOption,
    },
    {
      creditLabel: 'Outage or loss of service',
      creditId: 'outageCredits',
      isDisabled: !showOutageOption,
    },
    // {
    //   creditLabel: 'Late fee',
    //   creditId: 'lateFeeCredits',
    //   isDisabled: !showLateFeeOption, //showLateFeeOption is destructured from eligibility
    // },
  ];

  const optionsCreditIds = creditOptions.map(option => option.creditId);

  const validateForm = ({ selectedCreditOption }) => {
    const errors = {};
    if (!selectedCreditOption) {
      errors.chooseCreditOption = 'Please choose an option to continue';
    }
    return errors;
  };
  const handleSubmit = ({ selectedCreditOption }) => {
    if (selectedCreditOption === 'videoCredits') {
      history.push(VIDEO_DETAILS);
    }
    if (selectedCreditOption === 'outageCredits') {
      history.push(OUTAGE_DETAILS);
    }
    // if (selectedCreditOption === 'lateFeeCredits') {
    //   history.push(LATE_FEE_CREDITS);
    // }
  };

  return (
    <PageSection className="page-section--banner">
      <PageTitle title="What kind of credit are you requesting?" noFocus />
      <Formik
        initialValues={{ selectedCreditOption: '' }}
        validate={validateForm}
        onSubmit={handleSubmit}
        validateOnChange
        validateOnBlur={false}
      >
        {({ errors }) => (
          <Form noValidate>
            {errors.chooseCreditOption && (
              <div
                className="form-control__error error-top"
                id="err-msg"
                tabIndex="-1"
              >
                {errors.chooseCreditOption}
              </div>
            )}

            <CardGroup>
              {creditOptions.map(({ creditId, creditLabel, isDisabled }) => (
                <div className="card text-align-left" key={creditId}>
                  <div className="card__content">
                    <FormBoolean
                      label={creditLabel}
                      inputId={creditId}
                      labelClass="body2"
                    >
                      <Field
                        id={creditId}
                        name="selectedCreditOption"
                        component={BooleanField}
                        type="radio"
                        value={creditId}
                        disabled={isDisabled}
                        forceError={!!errors.chooseCreditOption}
                        aria-invalid={!!errors.chooseCreditOption}
                        aria-describedby="err-msg"
                      />
                    </FormBoolean>
                  </div>
                </div>
              ))}
            </CardGroup>

            <FocusErrorFirstBoolean booleanValues={optionsCreditIds} />
            <NavigationButtons
              navExternalLink={false}
              navLinkForBack={REPORT_ISSUE}
              labelForNext="Continue"
              labelForPrevious="Back"
              handleSubmit={handleSubmit}
            />
          </Form>
        )}
      </Formik>
    </PageSection>
  );
};
export default CreditSelection;
