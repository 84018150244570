import * as Oauth from '@dotcom/oauthjs';

import logger from './logger';
import getConfig from '../config';

// Helper to merge in clientId and authorizeUrl to any other options we need for oauthjs.
const makeOauthOptions = (opts = {}) => {
  const {
    oauthOptions: { clientId, authorizeUrl },
  } = getConfig();

  return {
    clientId,
    authorizeUrl,
    ...opts,
  };
};

const OAUTH_LOGIN_REDIRECT = { forceRedirect: true };

// Wrapper around Oauth.login for ease of use.
// Merges required options into the hash passed to Oauth.login
// and adds some error messaging and logging.
const login = async (opts = {}) => {
  const loginOpts = makeOauthOptions(opts);

  try {
    return await Oauth.login(loginOpts);
  } catch (error) {
    // When Oauth.login rejects due to a redirect
    // it does not reject with an error or object,
    // but with undefined instead.
    // Perhaps this is an oversight/accident here:
    //    https://github.comcast.com/xfinity-web/oauthjs/blob/v2.3.0/src/index.js#L156
    // Fix by returning an OAUTH_LOGIN_REDIRECT object.
    if (typeof error === 'undefined') {
      logger.log('Oauth.login redirect', {
        loginOpts,
        error: OAUTH_LOGIN_REDIRECT,
      });
      return OAUTH_LOGIN_REDIRECT;
    }

    logger.log('Oauth.login failed', { loginOpts, error });

    throw error;
  }
};

const forceRedirect = async (opts = {}, url = null) => {
  const logoutOpts = makeOauthOptions({
    ...opts,
    // Set `redirect: false` so that we don't destroy the isAuth cookie
    // and so that Oauth doesn't try to redirect, we will handle that ourselves.
    redirect: false,
  });
  const redirectUrl = url || Oauth.getLoginUrl(logoutOpts);

  // Oauth.logout() clears the auth info that Oauth has set in local storage
  logger.log('Oauth.logout force redirect', { logoutOpts, redirectUrl });

  Oauth.logout(logoutOpts);
  window.location.assign(redirectUrl);

  return OAUTH_LOGIN_REDIRECT;
};

// When the Oauth prompt param is multi-valued the values are separated by a space, not a comma.
const SELECT_ACCOUNT_PROMPT = 'select_billing_account';
const PASSIVE_AUTH_SELECT_ACCOUNT_PROMPT = `none ${SELECT_ACCOUNT_PROMPT}`;
const FORCE_AUTH_SELECT_ACCOUNT_PROMPT = `login ${SELECT_ACCOUNT_PROMPT}`;

const passiveAuth = async redirectUri => {
  const {
    oauthOptions: { iframeRedirectUri },
  } = getConfig();

  try {
    return await login({
      redirect: false,
      useIframe: true,
      iframeRedirectUri,
      allowIframeLoginWithoutPromptNone: true,
      authOpts: {
        prompt: PASSIVE_AUTH_SELECT_ACCOUNT_PROMPT,
      },
    });
  } catch (err) {
    if (err.error === 'login required') {
      // The passive auth attempt failed because the customer isn't logged in to CIMA
      // Do a force auth to ask the customer to enter their credentials.
      return forceAuth(redirectUri);
    }

    throw err;
  }
};

const forceAuth = redirectUri => {
  return forceRedirect({
    authOpts: {
      prompt: FORCE_AUTH_SELECT_ACCOUNT_PROMPT,
      redirect_uri: redirectUri,
    },
  });
};

export const idmLogin = redirectTo => {
  const {
    oauthOptions: { idmClientId },
    apis: { ssm },
  } = getConfig();

  return forceRedirect(
    {
      clientId: idmClientId,
    },
    `${ssm}/account-selector?continue=${window.location.origin}/support/account-management${redirectTo}&p=rsn-courtesy`,
  );
};

const { oauthOptions: { defaultRedirectUri } = {} } = getConfig();

export const getOauth = (redirectUri = defaultRedirectUri) => {
  return passiveAuth(redirectUri);
};

Oauth.onAuthRevoke(() => passiveAuth(defaultRedirectUri));

export const getIdmAuth = async redirectUrl => {
  const {
    oauthOptions: { idmClientId },
  } = getConfig();

  const defaultConfig = {
    redirect: false,
    useIframe: false,
    clientId: idmClientId,
  };

  try {
    const loginOpts = makeOauthOptions(defaultConfig);
    const authInfo = await Oauth.login(loginOpts);
    if (authInfo.accessToken) {
      return authInfo;
    }
  } catch (err) {
    // eslint-disable-next-line no-console
    console.warn(err);
  }
  return idmLogin(redirectUrl);
};
