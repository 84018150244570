import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { PageSection } from 'adc-ui-components';
import KeyValue from '../../../components/KeyValue';
import NavigationButtons from '../../../components/NavigationButtons';
import { postIssueTicket } from '../actions/ReportIssueActions';
import { dispatchSetModal } from '../../../shared/actions/userMessagesActions';
import { UNHANDLED_ERROR } from '../../../components/modals/ModalHandler';
import { formatPhone } from '../../../helpers/validation/phone';
import PageTitle from '../../../components/layout/PageTitle';

import { ISSUE_DETAILS, ISSUE_CONFIRMATION } from '../../../helpers/routes';

const Review = props => {
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    problemDetails: { problemLabel, problemType },
    userDetails: { email, mobile, comment },
    loading,
  } = useSelector(state => state.reportIssue);
  const {
    reportIssue: {
      reviewInfo: { title = '' },
    },
  } = useSelector(state => state.cms);

  const handleSubmit = async () => {
    const finalIssueData = {
      problemType,
      email,
      mobile,
      comment,
    };
    try {
      await dispatch(postIssueTicket(finalIssueData));
      history.push(ISSUE_CONFIRMATION);
    } catch (error) {
      dispatch(dispatchSetModal({ showModal: true, type: UNHANDLED_ERROR }));
    }
  };

  return (
    <PageSection className="page-section--banner">
      <PageTitle title={title} />
      <div className="card-group card-group--final-review mb24">
        <div className="card-group__item">
          <div className="card">
            <div className="card__content">
              <KeyValue
                className="key-value--inline-at-768"
                label="Request"
                labelClass="fw500"
                value={problemLabel}
              />
            </div>
          </div>
        </div>
        <div className="card-group__item">
          <div className="card card--action-right">
            <div className="card__content">
              <KeyValue
                className="key-value--inline-at-768"
                label="Email address"
                labelClass="fw500"
                value={email}
              />
            </div>
            <div className="card__action body2 fw500">
              <Link to={ISSUE_DETAILS}>Edit</Link>
            </div>
          </div>
        </div>
        {mobile && (
          <div className="card-group__item">
            <div className="card card--action-right">
              <div className="card__content">
                <KeyValue
                  className="key-value--inline-at-768"
                  label="Mobile phone number"
                  labelClass="fw500"
                  value={formatPhone(mobile)}
                />
              </div>
              <div className="card__action body2 fw500">
                <Link to={ISSUE_DETAILS}>Edit</Link>
              </div>
            </div>
          </div>
        )}
        {comment && (
          <div className="card-group__item">
            <div className="card card--action-right">
              <div className="card__content">
                <KeyValue
                  className="key-value--inline-at-768"
                  label="Issue"
                  labelClass="fw500"
                  value={comment}
                />
              </div>
              <div className="card__action body2 fw500">
                <Link to={ISSUE_DETAILS}>Edit</Link>
              </div>
            </div>
          </div>
        )}
      </div>
      <NavigationButtons
        labelForNext="Submit"
        labelForPrevious="Back"
        handleSubmit={handleSubmit}
        navLinkForBack={ISSUE_DETAILS}
        submitting={loading}
      />
    </PageSection>
  );
};

export default Review;
