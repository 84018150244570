import getConfig from '../config';
import { isComcastURL } from './parseUrl';

export const getRefundDetails = data => {
  const {
    authorization = '',
    updatedEmail,
    updatedMailingAddress,
    refund: [refundDetails] = [],
  } = data;

  const {
    amount = '',
    id = '',
    identity: {
      emailAddress = '',
      serviceAddress = '',
      mailingAddress = '',
    } = {},
  } = refundDetails || {};

  let updatedMailingString = null;
  if (updatedMailingAddress) {
    updatedMailingString = addressToString(updatedMailingAddress);
  }

  return {
    amount,
    id,
    emailAddress: updatedEmail || emailAddress,
    serviceAddress,
    mailingAddress: updatedMailingString || mailingAddress,
    isLite: authorization === 'LITE',
  };
};

export const validateEmail = formValues => {
  const errors = {};
  const values = {
    email: formValues.email?.toLowerCase().trim(),
    confirmEmailAddress: formValues.confirmEmailAddress?.toLowerCase().trim(),
  };

  if (!values.email) {
    errors.email = 'Please enter a valid email address';
  }
  if (!values.confirmEmailAddress) {
    errors.confirmEmailAddress = 'Please enter a matching email address';
  }
  if (values.email && values.confirmEmailAddress) {
    if (values.email !== values.confirmEmailAddress) {
      errors.confirmEmailAddress = 'Please enter matching email addresses';
    }
  }
  return errors;
};

export const handleUrlParams = location => {
  const { env } = getConfig();
  if (env === 'prod') {
    return {
      mockResponse: false,
      ineligible: false,
      lite: false,
      email: false,
      error: false,
      postRSNError: false,
    };
  }

  const params = new URLSearchParams(location.search);
  return {
    mockResponse: params.get('mockResponse') === 'true' || false,
    ineligible: params.get('ineligible') === 'true' || false,
    lite: params.get('lite') === 'true' || false,
    email: params.get('email') === 'true' || false,
    error: params.get('error') === 'true' || false,
    postRSNError: params.get('postRSNError') === 'true' || false,
  };
};

/**
 * Build QA Params Query string for API concatenation
 * @param qaParams Object with values from handleUrlParams()
 * @returns {string} Query string to be appended to a URL
 */
export const getQueryString = (qaParams = {}) => {
  let queryString = '';
  Object.keys(qaParams)
    .filter(k => qaParams[k])
    .forEach(item => {
      queryString === ''
        ? (queryString = `?${item}=true`)
        : (queryString += `&${item}=true`);
    });
  return queryString;
};

export const refundEligibility = (segment, refund) => {
  const { env } = getConfig();
  const isEnvEligible = env !== 'pre-prod' || env !== 'production';

  const isInSegment = !isEnvEligible
    ? segment.some(item => item.name === 'helpandsupport-issue-sports-refund')
    : true;

  const result = isInSegment
    ? refund.some(item => item.status === 'eligible')
    : false;

  return result;
};

export const fallbackCancelUrl = 'https://xfinity.com/prepare';
export const getCancelDestination = location => {
  if (!location.search) {
    return fallbackCancelUrl;
  }

  const params = new URLSearchParams(location.search);
  const contParam = params.get('continue');
  return isComcastURL(contParam) ? contParam : fallbackCancelUrl;
};

export const addressToString = addressObj => {
  let addressString = '';
  // eslint-disable-next-line no-unused-vars
  for (const [key, value] of Object.entries(addressObj)) {
    if (value !== '') {
      addressString += ` ${value}`;
    }
  }
  return addressString;
};
