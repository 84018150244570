import React, { useState } from 'react';
import { Field, useFormikContext } from 'formik';
import { FormBoolean } from 'adc-ui-components';
import BooleanField from '../form-elements/BooleanField';
import TermsAndConditions from '../modals/TermsAndConditions';

const TermsAndConditionsCheckbox = () => {
  const {
    values: { acceptTerms },
    errors,
    setFieldValue,
  } = useFormikContext();
  const [showModal, setShowModal] = useState(false);
  const termsAndConditionsLabel = () => {
    return (
      <>
        I certify that I have read and agree to the
        <button
          type="button"
          onClick={() => {
            setFieldValue('termsViewed', true);
            setShowModal(true);
          }}
          className="button button--text"
        >
          &nbsp;terms &amp; conditions
        </button>
        .
      </>
    );
  };

  return (
    <>
      <div className="card card__content mb24">
        <FormBoolean
          inputId="termsConditionsCheckbox"
          label={termsAndConditionsLabel()}
          className="mb6 text-align-left"
        >
          <Field
            id="termsConditionsCheckbox"
            type="checkbox"
            name="acceptTerms"
            checked={acceptTerms}
            component={BooleanField}
          />
        </FormBoolean>
        {errors.terms && (
          <span
            className="form-control__error text-align-left body4"
            role="alert"
          >
            {errors.terms}
          </span>
        )}
      </div>
      {showModal && (
        <TermsAndConditions
          closeModal={() => setShowModal(false)}
          dismissable
        />
      )}
    </>
  );
};

export default TermsAndConditionsCheckbox;
