import {
  FETCH_ADDRESSES,
  FETCH_ADDRESSES_SUCCESS,
  FETCH_ADDRESSES_ERROR,
  RESET_ADDRESS_RESULTS,
  FETCH_TOKENIZED_ADDRESSES_FAILURE,
  FETCH_TOKENIZED_ADDRESSES_SUCCESS,
  FETCH_TOKENIZED_ADDRESSES,
  RESET_TOKENIZED_ADDRESS_RESULTS,
} from '../actions/typeAheadActions';

const INITIAL_STATE = { addressResults: [] };

export default function(state = INITIAL_STATE, action = {}) {
  switch (action.type) {
    case FETCH_ADDRESSES:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case FETCH_ADDRESSES_SUCCESS:
      return {
        ...state,
        addressResults: action.payload,
        loading: false,
        error: false,
      };
    case FETCH_ADDRESSES_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case RESET_ADDRESS_RESULTS:
      return {
        ...state,
        addressResults: null,
      };
    case FETCH_TOKENIZED_ADDRESSES:
      return {
        ...state,
        addressLoading: true,
        error: false,
      };
    case FETCH_TOKENIZED_ADDRESSES_SUCCESS:
      return {
        ...state,
        addressResults: action.payload.addresses,
        addressLoading: false,
        error: false,
      };
    case FETCH_TOKENIZED_ADDRESSES_FAILURE:
      return {
        ...state,
        addressLoading: false,
        error: action.payload,
      };
    case RESET_TOKENIZED_ADDRESS_RESULTS:
      return {
        ...state,
        addressResults: null,
      };
    default:
      return state;
  }
}
