/* eslint-disable react/jsx-curly-brace-presence */
import React from 'react';
import { PageSection } from 'adc-ui-components';
import { useSelector } from 'react-redux';
import ConfirmationIcon from '../../../components/svgs/IssueReportingConfirmationIcon';
import LinkOut from '../../../components/svgs/LinkOut';
import Button from '../../../components/Button';
import KeyValueGroup from '../../../components/KeyValueGroup';
import KeyValue from '../../../components/KeyValue';
import { formatPhone } from '../../../helpers/validation/phone';
import { replaceCMSText, replaceCMSTextWithLinks } from '../../../helpers/cms';
import PageTitle from '../../../components/layout/PageTitle';

const Confirmation = () => {
  const {
    problemDetails: { problemLabel },
    userDetails: { email, mobile },
    confirmationData: { ticketId = '' } = {},
  } = useSelector(state => state.reportIssue);
  const { firstName } = useSelector(state => state.user.data);
  const {
    reportIssue: {
      confirmation: { title = '', description = '' },
    },
  } = useSelector(state => state.cms);

  const confirmationData = [
    { label: 'Issue reported', value: problemLabel },
    { label: 'Email address', value: email },
  ];
  if (mobile) {
    confirmationData.push({
      label: 'Mobile phone number',
      value: formatPhone(mobile),
    });
  }
  return (
    <PageSection className="page-section--banner">
      <div className="mb24">
        <ConfirmationIcon />
      </div>
      <PageTitle
        title={replaceCMSText(title, '$firstName', firstName)}
        subtitle={replaceCMSTextWithLinks(description)}
      />
      <div className="card mb24 card--flex-start card--action-right">
        <div className="card__content">
          <p className="heading3">{`Your Ticket Request #${ticketId}`}</p>
          <KeyValueGroup itemClass="mb0">
            {confirmationData.map(item => (
              <KeyValue
                className="key-value--inline-at-768 confirmation"
                key={item.label}
                labelClass="add-colon-after-label"
                {...item}
              />
            ))}
          </KeyValueGroup>
        </div>
        <div className="card__action">
          <Button text onActivate={() => window.print()}>
            <span className="fw500">
              Print / Save
              <LinkOut className="svg-icon--22 ml6" />
            </span>
          </Button>
        </div>
      </div>
      <div className="text-center">
        <div className="action__item">
          <a
            className="button button--primary"
            href="https://xfinity.com"
            rel="noopener noreferrer"
          >
            I&apos;m Done
          </a>
        </div>
      </div>
    </PageSection>
  );
};

export default Confirmation;
