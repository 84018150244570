export default {
  env: 'production',
  apis: {
    ssm: 'https://api.sc.xfinity.com',
    cmsData: 'https://sdx.xfinity.com/cms/data/helpandsupport',
    xapi: 'https://xapi.xfinity.com',
    channelTracking: 'https://secure-niagaralog-prod.codebig2.net/',
  },
  kibanaApi: 'https://api-support.xfinity.com/logger/',
};
