import React from 'react';
import { PageSection } from 'adc-ui-components';
import { useHistory, Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import DocumentsReview from '../../../components/DocumentsReview';
import NavigationButtons from '../../../components/NavigationButtons';
import {
  BEREAVEMENT_REPRESENTATIVE_INFO,
  BEREAVEMENT_UPLOAD_DOCUMENTS,
  BEREAVEMENT_STATEMENT,
  BEREAVEMENT_CONFIRMATION,
} from '../../../helpers/routes';
import KeyValue from '../../../components/KeyValue';
import { createEROneTicket } from '../bereavementActions';
import { UNHANDLED_ERROR } from '../../../components/modals/ModalHandler';
import { dispatchSetModal } from '../../../shared/actions/userMessagesActions';
import PageTitle from '../../../components/layout/PageTitle';

const FinalReview = ({ setEditInfo }) => {
  const bereavementDetails = useSelector(state => state.bereavement);
  const documents = useSelector(state => state.uploadDocuments.documents);
  const {
    transaction = {},
    requester = {},
    loading = false,
  } = bereavementDetails;
  const { type = '' } = transaction;
  const { firstName = '', lastName = '', email = '' } = requester;
  const history = useHistory();
  const dispatch = useDispatch();
  const handleSubmit = async () => {
    try {
      // Dispatching create ER one ticket call on click of submit button
      await dispatch(createEROneTicket(bereavementDetails));
      return history.push(`${BEREAVEMENT_CONFIRMATION}`);
    } catch (error) {
      // Error modal scenario handled and showing error popup.
      return dispatch(
        dispatchSetModal({ showModal: true, type: UNHANDLED_ERROR }),
      );
    }
  };

  return (
    <PageSection className="page-section--banner">
      <PageTitle title="Please confirm the information below" />
      <div className="card-group card-group--final-review mb24">
        <div className="card-group__item">
          <div className="card">
            <div className="card__content">
              <KeyValue
                className="key-value--inline-at-768"
                label="Request"
                labelClass="fw500"
                value={
                  type === 'disconnect'
                    ? 'Close the account permanently'
                    : 'Transfer account ownership to me'
                }
              />
            </div>
          </div>
        </div>
        <div className="card-group__item">
          <div className="card">
            <div className="card__content">
              <KeyValue
                className="key-value--inline-at-768"
                label="Submitted by"
                labelClass="fw500"
                value={`${firstName} ${lastName}`}
              />
            </div>
          </div>
        </div>
        <div className="card-group__item">
          <div className="card card--action-right">
            <div className="card__content">
              <KeyValue
                className="key-value--inline-at-768"
                label="Email address"
                labelClass="fw500"
                value={email}
              />
            </div>
            <div className="card__action body2 fw500">
              <Link
                to={`${BEREAVEMENT_REPRESENTATIVE_INFO}`}
                onClick={() => setEditInfo(true)}
              >
                Edit
              </Link>
            </div>
          </div>
        </div>
        <div className="card-group__item">
          <div className="card card--action-right">
            <div className="card__content">
              <KeyValue
                className="key-value--inline-at-768"
                label="Uploads"
                labelClass="fw500"
                value={<DocumentsReview docsToUpload={documents} />}
              />
            </div>
            <div className="card__action body2 fw500">
              <Link
                to={`${BEREAVEMENT_UPLOAD_DOCUMENTS}`}
                onClick={() => setEditInfo(true)}
              >
                Edit
              </Link>
            </div>
          </div>
        </div>
      </div>
      <NavigationButtons
        labelForNext="Submit"
        labelForPrevious="Back"
        navLinkForBack={
          type === 'disconnect'
            ? BEREAVEMENT_STATEMENT
            : BEREAVEMENT_REPRESENTATIVE_INFO
        }
        handleSubmit={handleSubmit}
        submitting={loading}
      />
    </PageSection>
  );
};

export default FinalReview;
