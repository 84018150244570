export const getUrlParts = (url = window.location.href) => {
  const decodedUrl = decodeURIComponent(url);
  if (!decodedUrl.match(/[?|#]/)) {
    return {};
  }

  let parts = {};

  decodedUrl
    .split(/[?|#]/)
    .slice(1)
    .forEach(part => {
      parts = {
        ...parts,
        ...part.split('&').reduce((obj, item) => {
          const data = item.split('=');
          return { ...obj, [data[0]]: data[1] };
        }, {}),
      };
    });

  return parts;
};

/**
 * Matches url to be valid xfinity or comcast .com/.net domain (From MAW codebase)
 * Matches all patterns as described in test case. - https://regex101.com/r/M0tvlA/14
 * @param {String} url
 */
export const isComcastURL = url => {
  // Starts with 0 or 1 group equalling http(s)://
  // Followed by 0 or 1 group of any number of letters, dashes, and/or periods, followed by a letter or number and a period
  // Followed by the word xfinity or comcast
  // Followed by a period
  // Followed by com or net
  // Followed by a forward slash, question mark, or the string end.
  // After which you can put in anything you want in the whole wide world.
  return /^(https?:\/\/)?([A-Z0-9-.]+[A-Z0-9]\.)?(xfinity|comcast)\.(com|net)(\/|\?|$)/gi.test(
    url,
  );
};
