import React from 'react';
import { replaceCMSTextWithLinks } from '../helpers/cms';

const CommonSolutions = ({ footers }) => (
  <div className="card-group">
    {footers.map(({ description }) => (
      <div className="card-group__item" key={description}>
        <div className="card text-align-left">
          <div className="card__content">
            <p>{replaceCMSTextWithLinks(description)}</p>
          </div>
        </div>
      </div>
    ))}
  </div>
);

export default CommonSolutions;
