import mtmClient from '../../fetch/apiClient';
import { getCMSItemAPI } from '../../api/apis';
import { cmsEndpoints } from '../../api/cmsEndpoints';

export const FETCH_CMS = 'FETCH_CMS';
export const FETCH_CMS_SUCCESS = 'FETCH_CMS_SUCCESS';
export const FETCH_CMS_FAILURE = 'FETCH_CMS_FAILURE';

const dispatchGetCMSSuccess = payload => ({
  type: FETCH_CMS_SUCCESS,
  payload,
});
const dispatchGetCMSFailure = payload => ({
  type: FETCH_CMS_FAILURE,
  payload,
});
const dispatchGetCMS = payload => ({
  type: FETCH_CMS,
  payload,
});

export const getCmsContent = () => (dispatch, getState) => {
  const fetchCMS = async ({ type, endpoint }) => {
    // Creating a variable for the flow so we can properly nest the cms object in state
    const flow = type === 'credits' ? 'credits' : 'reportIssue';
    const {
      cms: { reportIssue },
    } = getState();
    // if this cms data for this itemId has already been fetched, just return the same one
    if (reportIssue[type] && !reportIssue[type].loading) {
      return reportIssue[type];
    }
    dispatch(dispatchGetCMS({ type, flow }));

    try {
      const cmsData = await mtmClient.fetch(getCMSItemAPI(endpoint), {
        sessionRequired: false,
      });

      // Credits CMS comes back as an array so we need to map it to an object so it
      // will behave similar to the other cms
      let cmsDataObject = {};
      if (Array.isArray(cmsData)) {
        cmsData.forEach(data => {
          cmsDataObject = { ...cmsDataObject, ...data };
        });
      }
      dispatch(
        dispatchGetCMSSuccess({
          flow,
          type,
          // If cmsDataObject is not empty then it is the credits cms, otherwise we can just
          // push it to the reducer
          cmsData: Object.keys(cmsDataObject).length ? cmsDataObject : cmsData,
        }),
      );
      return cmsData;
    } catch (err) {
      dispatch(
        dispatchGetCMSFailure({
          type,
          message: err.message,
        }),
      );
      throw err;
    }
  };

  for (const cmsKey in cmsEndpoints) {
    if (cmsKey) {
      fetchCMS(cmsEndpoints[cmsKey]);
    }
  }
};
