import debounce from 'lodash/debounce';

import { formatAppLog, formatServiceCallLog } from './formatKibanaLog';
import getConfig from '../../config';

const { kibanaApi } = getConfig();

let batch = [];

const stringifyBatch = () => {
  const batchString = JSON.stringify(batch);
  batch = [];

  return batchString;
};

const flush = async () => {
  if (batch.length > 0) {
    window.fetch(kibanaApi, {
      method: 'POST',
      body: stringifyBatch(),
    });
  }
};

const postLogsSoon = debounce(flush, 500, { maxWait: 3000 });

const log = (event, details = {}) => {
  batch.push(formatAppLog(event, details));
  postLogsSoon();
};

const logServiceCall = (details = {}) => {
  batch.push(formatServiceCallLog(details));
  postLogsSoon();
};

// Try to use the navigator.sendBeacon browser API on window unload if available.
// Otherwise, try to flush the logs immediately before the browser unloads.
window.addEventListener('unload', async () => {
  if (batch.length === 0) {
    return;
  }

  if (window.navigator.sendBeacon) {
    window.navigator.sendBeacon(kibanaApi, stringifyBatch());
  } else {
    // Last ditch attempt to flush the current batch of logs.
    await flush();
  }
});

export default { log, logServiceCall };
