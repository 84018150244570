import React from 'react';
import { joinClass } from '../helpers/component';

const KeyValue = ({
  label,
  value,
  className,
  labelClass,
  labelWrapperClass,
  valueClass,
}) => (
  <div className={joinClass('key-value', className)}>
    {label && (
      <div className={joinClass('key-value__key', labelWrapperClass)}>
        <h4 className={joinClass('body2', labelClass)}>{label}</h4>
      </div>
    )}
    <div className={joinClass('key-value__value', 'body2', valueClass)}>
      {value}
    </div>
  </div>
);
export default KeyValue;
