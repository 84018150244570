import performance from '../../helpers/logger/performance';

let markCounter = 0;
const createMarkId = url => {
  markCounter += 1;
  return `api-${markCounter}-${url}`;
};

// logger factory function responsible for logging the request and response
export default ({ fetch = window.fetch, logger = console }) => {
  const { markStart, markEnd } = performance(logger);

  const middleware = async (url, options = {}) => {
    const { method = 'get' } = options;
    const logData = {
      url,
      request: { method },
      response: {},
    };

    const markId = createMarkId(url);

    try {
      markStart(markId);
      const response = await fetch(url, options);
      const responseClone = response.clone();
      logData.response.status = responseClone.status;
      if (!responseClone.ok) {
        const errorData = await responseClone.text();
        const jsonErrorData = JSON.parse(errorData);
        logData.response.data = jsonErrorData;
      }

      markEnd(markId, logData);

      return response;
    } catch (error) {
      if (error instanceof Error) {
        logger.log('fetch error', { error, httpMethod: method, fetchUrl: url });
      } else {
        logData.response = error;
        markEnd(markId, logData);
      }

      throw error;
    }
  };
  return {
    fetch: middleware,
    logger,
  };
};
