import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Modal from './Modal';
import { dispatchResetUserMessages } from '../../shared/actions/userMessagesActions';
import { BEREAVEMENT } from '../../helpers/routes';

const TimeoutHandleError = ({ dismissable }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const continueHandler = () => {
    dispatch(dispatchResetUserMessages());
    history.push(`${BEREAVEMENT}`);
    window.location.reload();
  };
  return (
    <Modal isOpen dismissable={dismissable}>
      <div className="text-center">
        <div className="hgroup mb24">
          <h2>You&apos;ve been idle for 15 minutes</h2>
          <p className="body2">
            For your security, you&apos;ll be routed back to the beginning, and
            any information you provided will be deleted.
          </p>
        </div>
        <button
          className="button button--primary"
          type="button"
          onClick={continueHandler}
        >
          Continue
        </button>
      </div>
    </Modal>
  );
};

export default TimeoutHandleError;
