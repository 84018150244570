import React from 'react';

const CirclePlus = ({ className = '', ...props }) => (
  <span className={`svg-icon ${className}`} {...props}>
    <svg
      viewBox="0 0 40 40"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
      aria-hidden="true"
    >
      <g fill="none" stroke="currentColor" strokeMiterlimit="10">
        <path d="M20 13.5v13M26.5 20h-13M32.5 20A12.5 12.5 0 1 1 20 7.5 12.46 12.46 0 0 1 32.5 20z" />
      </g>
    </svg>
  </span>
);

export default CirclePlus;
