import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { joinClass } from '../helpers/component';

const NavigationButtons = ({
  navLinkForBack,
  navExternalLink,
  labelForNext,
  labelForPrevious,
  handleSubmit,
  hideBackButton,
  submitting,
  navToPreviousPage,
  navToLinkBeforeDeepLink,
}) => {
  const history = useHistory();
  return (
    <div className="text-center navigation-buttons">
      <div className="action action--right-centered">
        <div className="action__item">
          <button
            type="submit"
            onClick={handleSubmit}
            className={joinClass(
              'button button--primary',
              submitting && 'is-loading',
            )}
            disabled={submitting}
          >
            {labelForNext}
          </button>
        </div>
        {!hideBackButton && (
          <div className="action__item">
            {!navToPreviousPage &&
              !navExternalLink &&
              !navToLinkBeforeDeepLink && (
                <Link className="button" to={navLinkForBack}>
                  {labelForPrevious}
                </Link>
              )}
            {!navToPreviousPage && navExternalLink && (
              <a className="button" href={navLinkForBack}>
                {labelForPrevious}
              </a>
            )}
            {!navExternalLink && navToPreviousPage && (
              <button
                type="button"
                onClick={() => history.goBack()}
                className={joinClass('button')}
              >
                {labelForPrevious}
              </button>
            )}
            {!navExternalLink && !navToPreviousPage && navToLinkBeforeDeepLink && (
              <button
                type="button"
                onClick={() => history.go(-2)}
                className={joinClass('button')}
              >
                {labelForPrevious}
              </button>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default NavigationButtons;
