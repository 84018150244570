import React from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { PageSection, Loading } from 'adc-ui-components';

import PageTitle from '../../../components/layout/PageTitle';
import NavigationButtons from '../../../components/NavigationButtons';
import { SPORTS_REFUND_REVIEW } from '../../../helpers/routes';
import RefundDetailsCard from '../../../components/refund/refundDetailsCard';
import InfoNoticeCard from '../../../components/InfoNoticeCard';
import Pushdown from '../../../components/PushDown';

import {
  fallbackCancelUrl,
  getRefundDetails,
} from '../../../helpers/SportsRefundHelpers';

const Landing = () => {
  const history = useHistory();
  const { loading, cancelUrl, states: sportsRefundData = {} } = useSelector(
    state => state.sportsRefund,
  );
  const { accountNumber = '' } = sportsRefundData || {};
  const userRefundInfo = getRefundDetails(sportsRefundData);
  const { isLite, serviceAddress } = userRefundInfo;
  const handleSubmit = event => {
    event.preventDefault();
    history.push(SPORTS_REFUND_REVIEW);
  };
  const cancelDest = cancelUrl || fallbackCancelUrl;

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      {isLite && (
        <Pushdown
          accountNumber={accountNumber}
          serviceAddress={serviceAddress}
        />
      )}
      <PageSection className="page-section--banner">
        <PageTitle
          title="Regional Sports Network courtesy adjustment"
          noFocus
        />
        <div className="card card--action-right mb12">
          <div className="card__content">
            <p className="mb12 body2">
              Due to the impact of COVID-19 on this year&apos;s sporting events
              and broadcasts, you are eligible to receive a Regional Sports
              Network Courtesy Adjustment. The Adjustment will be issued within
              4-6 weeks from the request date.
            </p>
            <ul>
              <li className="body3">
                If you made a payment within the past 45 days with a
                debit/credit card on file, the adjustment will be sent to that
                card.
              </li>
              <li className="body3">
                If you did not make a debit/credit card payment within the past
                45 days, a check will be mailed to the billing address on file.
              </li>
            </ul>
          </div>
        </div>
        {userRefundInfo && (
          <RefundDetailsCard cardDetails={userRefundInfo} canEditIdentity />
        )}
        <InfoNoticeCard authorization={!isLite} />
        <NavigationButtons
          labelForNext="Continue"
          labelForPrevious="Cancel"
          handleSubmit={handleSubmit}
          navLinkForBack={cancelDest}
          navExternalLink
        />
      </PageSection>
    </>
  );
};

export default Landing;
