import { combineReducers } from 'redux';
import video from './Video/VideoCreditReducer';
import outage from './Outage/OutageCreditReducer';
import lateFee from './LateFee/LateFeeReducer';
import eligibility from './Eligibility/EligibilityReducer';

const credits = combineReducers({
  video,
  outage,
  lateFee,
  eligibility,
});

export default credits;
