import React from 'react';
import { Link } from 'react-router-dom';

import { joinClass } from '../helpers/component';

export default ({
  children,
  className,
  onActivate,
  to,
  href,
  tabIndex,
  text,
  primary,
  utility,
  size,
  ...props
}) => {
  const classes = joinClass(
    'button',
    text && 'button--text',
    primary && 'button--primary',
    utility && 'button--utility',
    size && `button--${size}`,
    className,
  );
  if (to) {
    return (
      <Link className={classes} tabIndex={tabIndex || '0'} to={to} {...props}>
        {children}
      </Link>
    );
  }
  if (href) {
    return (
      <a className={classes} tabIndex={tabIndex || '0'} href={href} {...props}>
        {children}
      </a>
    );
  }
  if (onActivate) {
    const keyUp = event => {
      const { key } = event;
      // appears that browsers are treating keyPress for spacebar as onclick,
      // so don't activate on spacebar being pressed or onActivate is fired twice.
      if (key === 'Enter') {
        return onActivate(event);
      }
      return true;
    };
    return (
      <button
        type="button"
        className={classes}
        tabIndex={tabIndex || '0'}
        onKeyUp={keyUp}
        onClick={onActivate}
        {...props}
      >
        {children}
      </button>
    );
  }
  return null;
};
