import React, { useState } from 'react';
import { formatDate } from '../helpers/date';
import { getLabelsOnAssetType } from '../helpers/videoCredits';
import { getVideoThumbnailSrc } from '../api/apis';
import FallbackVideoThumbnail from '../assets/images/fallbackVideoThumbnail.jpg';

const VideoAssetContent = ({
  title,
  titleClass,
  type,
  typeClass,
  orderDateTime,
  dateFormat,
  price,
  priceClass,
  page,
  ownershipRemovalNote,
  ownershipRemovalClass,
  entityId,
}) => {
  const [videoThumbnail, setVideoThumbnail] = useState(
    getVideoThumbnailSrc(entityId),
  );
  const { ownershipLabel } = getLabelsOnAssetType(type);
  const AssetPrice = price || '0.00';
  return (
    <>
      <div className="card__media">
        <img
          src={videoThumbnail}
          alt={title}
          onError={() => setVideoThumbnail(FallbackVideoThumbnail)}
        />
      </div>
      <div className="card__content">
        {title && <p className={titleClass}>{title}</p>}
        {type && orderDateTime && dateFormat && (
          <p className={typeClass}>
            {`${ownershipLabel} ${formatDate(
              new Date(orderDateTime),
              dateFormat,
            )}`}
          </p>
        )}
        {ownershipRemovalNote && (
          <p className={ownershipRemovalClass}>{ownershipRemovalNote}</p>
        )}
        {page === 'details' ? (
          <p className={priceClass}>{`$${AssetPrice}`}</p>
        ) : null}
      </div>
    </>
  );
};

export default VideoAssetContent;
