import React from 'react';

export default ({ className = '', ...props }) => (
  <span className={`svg-icon ${className}`} {...props}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 15 21"
      role="img"
      aria-hidden="true"
    >
      <g fill="none">
        <path
          d="M12.5 7.17v7.33H.5v-12h7.33m6.67 3v-5h-5M14 1L6 9"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  </span>
);
