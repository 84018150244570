import React from 'react';
import { Switch } from 'react-router-dom';

import DefaultLayout from './apps/Layouts/DefaultLayout';

import Bereavement from './apps/Bereavement';
import ReportIssue from './apps/ReportIssue';
import Credits from './apps/Credits';
import Error from './apps/Errors/Error';
import NoMatch from './apps/Errors/NoMatch';

import {
  ERROR,
  BEREAVEMENT,
  REPORT_ISSUE,
  CREDITS,
  SPORTS_REFUND,
} from './helpers/routes';
import SportsRefund from './apps/SportsRefund';

const Routes = props => (
  <Switch>
    <DefaultLayout path={BEREAVEMENT} component={Bereavement} {...props} />
    <DefaultLayout path={REPORT_ISSUE} component={ReportIssue} />
    <DefaultLayout path={CREDITS} component={Credits} />
    <DefaultLayout path={SPORTS_REFUND} component={SportsRefund} />
    <DefaultLayout exact path={ERROR} component={Error} {...props} />
    <DefaultLayout component={NoMatch} {...props} />
  </Switch>
);

export default Routes;
