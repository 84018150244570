import React from 'react';
import KeyValueGroup from './KeyValueGroup';
import KeyValue from './KeyValue';

const VidoeReviewCardGroupItem = ({
  label,
  value,
  labelClass,
  valueClass,
  keyValueClassName,
}) => {
  return (
    <div className="card-group__item">
      <div className="card">
        <div className="card__content">
          <KeyValueGroup className="mb0">
            <KeyValue
              className={keyValueClassName}
              label={label}
              labelClass={labelClass}
              valueClass={valueClass}
              value={value}
            />
          </KeyValueGroup>
        </div>
      </div>
    </div>
  );
};
export default VidoeReviewCardGroupItem;
