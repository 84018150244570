import React from 'react';
import Modal from './Modal';

const UnhandledError = ({ closeModal, dismissable }) => (
  <Modal isOpen onRequestClose={closeModal} dismissable={dismissable}>
    <div className="hgroup">
      <div className="mb12">
        <h2>Terms &amp; Conditions</h2>
      </div>
      <div className="hgroup__terms-conditions">
        <p className="body4">
          If applicable, I certify that I am authorized to view or hear any
          messages left in the Decedent&apos;s Xfinity Digital Voice voicemail
          box, receive call detail records or view emails left in the
          Decedent&apos;s Xfinity Internet email account.
        </p>
        <br />
        <p className="body4">
          I am accepting assignment and assumption of all Comcast equipment
          and/or any Xfinity minimum term agreements on the Xfinity account
          which may be up to 24 months in length and include early termination
          fees up to $460, including agreements for Xfinity Home and Xfinity
          Mobile devices and services.
        </p>
        <br />
        <p className="body4">
          I agree to be bound by the Comcast Agreement for Residential Services
          (https://www.xfinity.com/Corporate/Customers/Policies/SubscriberAgreement)
          and by the Xfinity Mobile Customer Agreement
          (https://www.xfinity.com/mobile/policies/customer-agreement) and
          Xfinity Home Agreement, if Xfinity Home services are active
          (https://www.xfinity.com/secureagreement).
        </p>
        <br />
        <p className="body4">
          I am aware of account elections made by the account holder including,
          but not limited to, paperless billing, notifications and marketing
          elections. It is my responsibility upon transfer of the account to
          change passwords as necessary to prevent unauthorized access to the
          account or equipment used for the services, such as wireless routers.
        </p>
        <br />
        <p className="body4">
          I have reviewed the Comcast Customer Privacy Notice
          (https://www.xfinity.com/Corporate/Customers/Policies/CustomerPrivacy)
        </p>
        <br />
        <p className="body4">
          If I have Xfinity Voice. I understand and acknowledge that in order
          for 911 calls to be properly directed to emergency services, Comcast
          must have my correct service address. If I move my Xfinity Voice
          equipment to a different address without Comcast’s approval, 911 calls
          may be directed to the incorrect emergency authority with the
          incorrect address, and my phone service, including 911, may fail. If
          there is an electrical power outage in my home, calling, including
          calls to 911, may be unavailable. Calls to 911 may not be completed if
          there is a technical or other problem with Comcast’s network or other
          network facilities or databases used to complete 911 calls.
        </p>
        <br />
        <p className="body4">
          I agree to RELEASE, INDEMNIFY, AND HOLD HARMLESS Comcast, its
          subsidiaries, affiliates, their respective parents, officers,
          employees, agents, successors and assigns from and against any claims,
          demands, actions, liens, rights, subrogated or contribution interests,
          debts, liabilities, judgments, costs, and attorney’s fees, arising out
          of the this change of account status.
        </p>
        <br />
        <p className="body4">
          The foregoing is the truth to the best of my knowledge, information
          and belief.
        </p>
      </div>
    </div>
  </Modal>
);

export default UnhandledError;
