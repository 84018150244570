import React from 'react';
import { PageSection, FormBoolean } from 'adc-ui-components';
import { useHistory } from 'react-router-dom';
import { Field, Form, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import BooleanField from '../../../components/form-elements/BooleanField';
import { submitFinalStatementAddress } from '../bereavementActions';
import NavigationButtons from '../../../components/NavigationButtons';
import {
  BEREAVEMENT_REVIEW,
  BEREAVEMENT_DISCONNECT,
} from '../../../helpers/routes';
import PageTitle from '../../../components/layout/PageTitle';

const FinalStatement = () => {
  const bereavementdetails = useSelector(state => state.bereavement);
  const { billingAddress, requester, customer } = bereavementdetails || {};
  const history = useHistory();
  const dispatch = useDispatch();
  let addressOption = 'customerAddress';
  if (
    billingAddress &&
    billingAddress.fullStreetAddress === requester.fullStreetAddress
  ) {
    addressOption = 'requesterAddress';
  }

  const handleSubmit = values => {
    // Dispatching the form values to save it in bereavement store.
    let payloadAddress;
    if (values.addressOption === 'requesterAddress') {
      payloadAddress = requester?.fullStreetAddress;
    } else if (values.addressOption === 'customerAddress') {
      payloadAddress = customer?.fullStreetAddress;
    }
    dispatch(submitFinalStatementAddress(payloadAddress));
    return history.push(`${BEREAVEMENT_REVIEW}`);
  };
  const requesterHelperText = 'Address provided for yourself';
  const customerhelperText = 'Address provided for the current account holder';
  // Passing this as to make the helper text clickable
  const labelText = (address, helperText) => (
    <>
      <p className="body2">{address}</p>
      <p className="body2">{helperText}</p>
    </>
  );
  return (
    <PageSection className="page-section--banner">
      <PageTitle
        title="Where should we send the account statement?"
        subtitle="We will issue any applicable refunds to the account holder's name
        once all Xfinity-equipment is returned."
      />
      <Formik onSubmit={handleSubmit} initialValues={{ addressOption }}>
        <Form>
          <div className="card-group text-align-left mb24">
            <div className="card-group__item">
              <div className="card">
                <div className="card__content card__content--info">
                  <FormBoolean
                    label={labelText(
                      customer?.fullStreetAddress,
                      customerhelperText,
                    )}
                    inputId="deceasedAddress"
                    labelClass="body2"
                  >
                    <Field
                      id="deceasedAddress"
                      name="addressOption"
                      component={BooleanField}
                      type="radio"
                      value="customerAddress"
                    />
                  </FormBoolean>
                </div>
              </div>
            </div>
            <div className="card-group__item">
              <div className="card">
                <div className="card__content card__content--info">
                  <FormBoolean
                    label={labelText(
                      requester?.fullStreetAddress,
                      requesterHelperText,
                    )}
                    inputId="representativeAddress"
                    labelClass="body2"
                    className="radio"
                  >
                    <Field
                      id="representativeAddress"
                      name="addressOption"
                      component={BooleanField}
                      type="radio"
                      value="requesterAddress"
                    />
                  </FormBoolean>
                </div>
              </div>
            </div>
          </div>
          <NavigationButtons
            navLinkForBack={BEREAVEMENT_DISCONNECT}
            labelForNext="Continue"
            labelForPrevious="Back"
          />
        </Form>
      </Formik>
    </PageSection>
  );
};
export default FinalStatement;
