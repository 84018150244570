import React from 'react';
import { PageSection } from 'adc-ui-components';
import RepresentativeUserForm from '../../../components/forms/RepresentativeUserForm';
import PageTitle from '../../../components/layout/PageTitle';

const RepresentativeInfo = ({ editInfo, setEditInfo }) => {
  return (
    <PageSection className="page-section--banner">
      <PageTitle title="Please provide us with your information" />
      <RepresentativeUserForm editInfo={editInfo} setEditInfo={setEditInfo} />
    </PageSection>
  );
};

export default RepresentativeInfo;
