import React, { useEffect, useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import debounce from 'lodash/debounce';
import {
  dispatchResetTokenizedAddressResults,
  fetchAddresses,
  fetchTokenizedAddresses,
  resetAddressResults,
} from '../../shared/actions/typeAheadActions';
import {
  setFieldValues,
  showAddressList,
  updateAddress,
} from '../../helpers/typeahead';

// Accessibility documentation: http://a11ypatterns.g4.app.cloud.comcast.net/#/autocomplete/

const AddressTypeAhead = ({
  field = {},
  form: {
    setFieldValue,
    validateForm,
    submitCount,
    errors = {},
    values = {},
    touched = {},
  },
  field: { name, onBlur, onChange },
  origin,
  className,
  id,
  placeholder,
  tokenized = false,
}) => {
  const addressResults = useSelector(state => state.typeAhead.addressResults);

  const dispatch = useDispatch();
  const isFormSubmitted = !!submitCount;
  const hasAttemptedInput = !!values[field.name] && touched[field.name];
  const shouldShowError =
    (isFormSubmitted || hasAttemptedInput) && errors[field.name];
  const errorMessageId = `error_${id}`;

  const [address, setAddress] = useState(values[field.name]); // set initial address value.
  const [manuallyClosed, setManuallyClosed] = useState(false); // when user press esc button in order to cancel the address suggestions.
  const [addressSelected, setAddressSelected] = useState(false); // to stop showing address when any address is selected.
  const [currentPosition, setCurrentPosition] = useState(-1); // to scroll and choose the address using down arrow.
  const [renderResults, setRenderResults] = useState(false); // to stop suggesting the results on click of back button

  useEffect(() => {
    // Validate the form whenever addressResults changes since results are asynchronous.
    validateForm();
  }, [addressResults, validateForm, manuallyClosed, setManuallyClosed]);

  // wait for the user the complete typing before you dispatch
  // useCallback will return a memoized version of debounce which keeps track of the previous value
  function fetchCallback(value, originParam) {
    if (tokenized) {
      return fetchTokenizedAddresses(value);
    }
    return fetchAddresses(value, originParam);
  }
  const debounceGetAddresses = useCallback(
    debounce(value => dispatch(fetchCallback(value, origin)), 500),
    [],
  );

  const navigateByKey = (e, setValuesCallback, clearValuesCallback) => {
    if (addressResults && addressResults.length) {
      const key = e.key || e.keyCode;

      if (key === 'Down' || key === 'ArrowDown' || key === 40) {
        // A11y: Steps through auto-suggestions and input field.
        if (
          currentPosition > -1 &&
          currentPosition + 1 !== addressResults.length
        ) {
          setCurrentPosition(currentPosition + 1);
        } else if (currentPosition === -1) {
          setCurrentPosition(0);
        }
      } else if (key === 'Up' || key === 'ArrowUp' || key === 38) {
        if (currentPosition !== 0) {
          setCurrentPosition(currentPosition - 1);
        }
      } else if (key === 'Enter' || key === 13) {
        // A11y: Select the currently focused auto-suggestion and close the menu.
        setAddressSelected(true);
        setValuesCallback();
        clearValuesCallback();
        e.preventDefault();
      } else if (key === 'Tab' || key === 9) {
        // A11y: Select the currently focused auto-suggestion, close the menu, and move focus to the next focusable element.
        updateAddress(
          addressResults[currentPosition],
          debounceGetAddresses,
          setAddress,
          manuallyClosed,
          setManuallyClosed,
          setRenderResults,
        );
        setValuesCallback();
        setAddressSelected(true);
      } else if (key === 'Escape' || key === 27) {
        // A11y: Close the menu without making a selection.
        setManuallyClosed(true);
      }
    }
  };

  /* eslint-disable jsx-a11y/role-has-required-aria-props */
  return (
    <>
      <input
        type="text"
        value={address}
        className={className}
        autoComplete="off"
        placeholder={placeholder}
        onChange={e => {
          updateAddress(
            e.target.value,
            debounceGetAddresses,
            setAddress,
            manuallyClosed,
            setManuallyClosed,
            setRenderResults,
          );
          addressSelected && setAddressSelected(false);
          setCurrentPosition(-1);
          if (onChange instanceof Function) {
            onChange(e);
          }
        }}
        onKeyDown={e =>
          navigateByKey(
            e,
            () => {
              if (tokenized) {
                setFieldValues(
                  name,
                  setAddress,
                  setFieldValue,
                  addressResults[currentPosition],
                );
              } else {
                setAddress(addressResults[currentPosition]);
                setFieldValue(name, addressResults[currentPosition]);
              }
            },
            () => {
              if (tokenized) {
                dispatch(dispatchResetTokenizedAddressResults());
              } else {
                dispatch(resetAddressResults());
              }
            },
          )
        }
        name={name}
        id="address"
        onBlur={onBlur}
        role="combobox"
        aria-controls="typeaheadResults"
        aria-expanded={showAddressList(
          address,
          manuallyClosed,
          addressSelected,
          renderResults,
        )}
        aria-activedescendant={`addressItem${currentPosition}`}
        aria-autocomplete="list"
        aria-describedby="typeaheadAnnouncer error_address"
      />
      <div
        role="listbox"
        tabIndex={
          showAddressList(
            address,
            manuallyClosed,
            addressSelected,
            renderResults,
          )
            ? 0
            : -1
        }
        id="typeaheadResults"
      >
        {showAddressList(
          address,
          manuallyClosed,
          addressSelected,
          renderResults,
        ) &&
          addressResults &&
          addressResults.map((result, idx) => {
            const identifier = `addressItem${idx}`;
            return (
              <div
                key={identifier}
                id={identifier}
                onClick={() => {
                  if (tokenized) {
                    dispatch(dispatchResetTokenizedAddressResults());
                    setFieldValues(name, setAddress, setFieldValue, result);
                    setAddressSelected(true);
                  } else {
                    dispatch(resetAddressResults());
                    setAddress(result);
                    setFieldValue(name, result);
                    setAddressSelected(true);
                  }
                }}
                className={`typeahead__address-row ${
                  currentPosition === idx ? 'typeahead__selection' : ''
                }`}
                role="option"
                tabIndex={currentPosition === idx ? 0 : -1}
                data-value={identifier}
                onKeyPress={e =>
                  e.key === 'Enter'
                    ? setAddress(result) &&
                      (tokenized
                        ? dispatch(dispatchResetTokenizedAddressResults())
                        : dispatch(resetAddressResults()))
                    : null
                }
              >
                {tokenized ? result.fullStreetAddress : result}
              </div>
            );
          })}
      </div>
      {shouldShowError && (
        <span id={errorMessageId} className="form-control__error" role="alert">
          {errors[field.name]}
        </span>
      )}
    </>
  );
  /* eslint-enable jsx-a11y/role-has-required-aria-props */
};

export default AddressTypeAhead;
