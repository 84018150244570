import en from 'nanoid-good/locale/en';
import nanoid from 'nanoid-good';
import mtmClient from '../../fetch/apiClient';
import getConfig from '../../config';
import { getOauth } from '../auth';
import logger from './kibana';

const uuid = nanoid(en)();
export const trackFiveStandards = async ({
  id,
  accountNumber,
  custGuid,
  error,
  interactionType = 'BILLING_PAYMENTS',
}) => {
  const { accessToken } = await getOauth();
  const { channelTracking } = getConfig().apis;
  logger.log(id, {
    fiveStandardsTracking: {
      transactionId: uuid,
      id,
      intent: id.includes('OUTAGE') ? 'HSD' : 'VIDEO',
      interactionType,
      accountNumber,
    },
  });
  return mtmClient.fetch(channelTracking, {
    method: 'POST',
    sessionRequired: false,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    body: JSON.stringify({
      LogType: 'DSS_CustomerInteraction',
      TransactionId: uuid,
      eventTimestamp: String(Date.now()),
      session: {
        channel: 'H&S',
        customer: {
          accountNumber,
          custGuid,
        },
      },
      action: {
        id,
        intent: id.includes('OUTAGE') ? 'HSD' : 'VIDEO',
        status: error || id.endsWith('DENIED') ? 'FAILED' : 'SUCCESS',
        failureReason: error || null,
        metadata: {
          targetChannel: 'H&S',
          trackingId: uuid,
        },
      },
      interactionId: null,
      interactionType,
    }),
  });
};
