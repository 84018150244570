import React from 'react';

const BooleanField = ({
  field = {},
  className = '',
  forceError,
  id,
  value,
  type,
  form: { submitCount, errors = {}, values = {}, touched = {} } = {},
  ...props
}) => {
  const isFormSubmitted = !!submitCount;
  const hasAttemptedInput = !!values[field.name] && touched[field.name];
  const shouldShowError =
    (isFormSubmitted || hasAttemptedInput) && errors[field.name];
  const shouldShowErrorHighlight = shouldShowError || forceError;
  const errorMessageId = `error_${id}`;
  const defaultChecked =
    (type === 'checkbox' && values[field.name]) ||
    (type === 'radio' && values[field.name] === value);
  return (
    <>
      <input
        id={id}
        aria-invalid={!!shouldShowError}
        aria-describedby={errorMessageId}
        className={`${className} ${shouldShowErrorHighlight ? 'error' : ''}`}
        checked={defaultChecked}
        type={type}
        {...field}
        {...props}
      />
      {shouldShowError && (
        <span id={errorMessageId} className="form-control__error" role="alert">
          {errors[field.name]}
        </span>
      )}
    </>
  );
};

export default BooleanField;
