import { AddressTypeahead, TypeAheadAPI } from '../../api/apis';
import mtmClient from '../../fetch/apiClient';
import { getIdmAuth } from '../../helpers/auth';

export const FETCH_ADDRESSES = 'FETCH_ADDRESSES';
export const FETCH_ADDRESSES_SUCCESS = 'FETCH_ADDRESSES_SUCCESS';
export const FETCH_ADDRESSES_ERROR = 'FETCH_ADDRESSES_ERROR';
export const RESET_ADDRESS_RESULTS = 'RESET_ADDRESS_RESULTS';

export const FETCH_TOKENIZED_ADDRESSES = 'FETCH_TOKENIZED_ADDRESSES';
export const FETCH_TOKENIZED_ADDRESSES_SUCCESS =
  'FETCH_TOKENIZED_ADDRESSES_SUCCESS';
export const FETCH_TOKENIZED_ADDRESSES_FAILURE =
  'FETCH_TOKENIZED_ADDRESSES_FAILURE';
export const RESET_TOKENIZED_ADDRESS_RESULTS =
  'RESET_TOKENIZED_ADDRESS_RESULTS';

let abortController;

const dispatchFetchAddress = () => ({
  type: FETCH_ADDRESSES,
});

const dispatchFetchAddressSuccess = addresses => ({
  type: FETCH_ADDRESSES_SUCCESS,
  payload: addresses || [],
});
const dispatchFetchAddressFailure = error => ({
  type: FETCH_ADDRESSES_ERROR,
  payload: error,
});

export const resetAddressResults = () => ({
  type: RESET_ADDRESS_RESULTS,
});
export const fetchAddresses = (
  typeAddress,
  locationOrigin,
) => async dispatch => {
  try {
    dispatch(dispatchFetchAddress());
    abortController && abortController.abort();
    abortController = new AbortController();
    const { signal } = abortController;
    const response = await mtmClient.fetch(TypeAheadAPI, {
      method: 'POST',
      body: JSON.stringify({
        address: typeAddress,
        max_results: 5,
        origin: locationOrigin,
      }),
      signal,
    });
    const { address_results: addresses } = response;
    dispatch(dispatchFetchAddressSuccess(addresses));
  } catch (err) {
    dispatch(dispatchFetchAddressFailure(err));
  }
};

const dispatchFetchTokenizedAddressSuccess = payload => ({
  type: FETCH_TOKENIZED_ADDRESSES_SUCCESS,
  payload,
});

const dispatchFetchTokenizedAddressFailure = payload => ({
  type: FETCH_TOKENIZED_ADDRESSES_FAILURE,
  payload,
});

export const dispatchResetTokenizedAddressResults = () => ({
  type: RESET_TOKENIZED_ADDRESS_RESULTS,
});

const dispatchGetTokenizedAddresses = (address, accessToken) => {
  const encodedAddress = encodeURIComponent(address);
  const url = AddressTypeahead.concat(
    `?tokenized=true&address=${encodedAddress}`,
  );
  return {
    type: FETCH_TOKENIZED_ADDRESSES,
    payload: mtmClient.fetch(url, {
      sessionRequired: false,
      method: 'GET',
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
    }),
  };
};
export const fetchTokenizedAddresses = address => async dispatch => {
  try {
    const { accessToken } = await getIdmAuth();
    const response = await dispatch(
      dispatchGetTokenizedAddresses(address, accessToken),
    ).payload;
    return await dispatch(dispatchFetchTokenizedAddressSuccess(response));
  } catch (err) {
    await dispatch(dispatchFetchTokenizedAddressFailure(err));
    throw err;
  }
};
