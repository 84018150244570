export const getLabelsOnAssetType = type => {
  const labelsOnAssetType = {
    ownershipLabel: '',
    demandRequestTypeLabel: '',
  };
  if (type === 'VOD' || type === 'PPV') {
    labelsOnAssetType.ownershipLabel = 'Rented';
    labelsOnAssetType.demandRequestTypeLabel = 'Rental';
  }
  if (type === 'EST') {
    labelsOnAssetType.ownershipLabel = 'Purchased';
    labelsOnAssetType.demandRequestTypeLabel = 'Purchase';
  }
  return labelsOnAssetType;
};
