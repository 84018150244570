import React from 'react';

const IconCircleExclamation = ({ className = '', ...props }) => {
  return (
    <span className={`svg-icon ${className}`} {...props}>
      <svg
        width="42"
        height="42"
        xmlns="http://www.w3.org/2000/svg"
        role="img"
        aria-hidden="true"
      >
        <g fill="none" fillRule="evenodd">
          <path
            d="M21 0C9.402 0 0 9.402 0 21s9.402 21 21 21 21-9.402 21-21A21 21 0 0 0 21 0"
            fill="#FFA700"
            fillRule="nonzero"
          />
          <path
            d="M22.643 31.357a1.643 1.643 0 1 1-3.286 0 1.643 1.643 0 0 1 3.286 0zM22.166 9a.82.82 0 0 1 .613.274.873.873 0 0 1 .219.649l-1.171 16.284A.845.845 0 0 1 21 27a.844.844 0 0 1-.826-.793L19.003 9.923a.869.869 0 0 1 .219-.649.818.818 0 0 1 .613-.274h2.33z"
            fill="#FFF"
          />
        </g>
      </svg>
    </span>
  );
};

export default IconCircleExclamation;
