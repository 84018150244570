import React from 'react';
import { PageSection } from 'adc-ui-components';
import PageTitle from '../../../components/layout/PageTitle';
import PreferredBillingForm from '../../../components/forms/PreferredBillingForm';

const MailingAddress = () => {
  return (
    <PageSection className="page-section--banner">
      <PageTitle title="Please enter your preferred billing address" noFocus />
      <PreferredBillingForm />
    </PageSection>
  );
};
export default MailingAddress;
