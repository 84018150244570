import mtmClient from '../../fetch/apiClient';
import { UserAPI } from '../../api/apis';
import { getOauth } from '../../helpers/auth';

export const GET_USER = 'GET_USER';
export const GET_USER_SUCCESS = 'GET_USER_SUCCESS';
export const GET_USER_FAILURE = 'GET_USER_FAILURE';

const dispatchGetUser = accessToken => {
  return {
    type: GET_USER,
    payload: mtmClient.fetch(UserAPI, {
      sessionRequired: false,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }),
  };
};

const dispatchGetUserSuccess = payload => ({
  type: GET_USER_SUCCESS,
  payload,
});
const dispatchGetUserFailure = payload => ({
  type: GET_USER_FAILURE,
  payload,
});

export const getUser = () => async dispatch => {
  try {
    const { accessToken } = await getOauth();

    const response = await dispatch(dispatchGetUser(accessToken)).payload;
    return dispatch(dispatchGetUserSuccess(response));
  } catch (error) {
    dispatch(dispatchGetUserFailure(error));
    throw error;
  }
};
