import React from 'react';
import KeyValue from './KeyValue';
import KeyValueGroup from './KeyValueGroup';

const ReviewCard = props => {
  const { cardData } = props;
  return (
    <div className="card-group--final-review mb24">
      {cardData.map((item, index) => (
        <div className="card-group__item" key={item.label ? item.label : ''}>
          <div className="card">
            <div className="card__content">
              {item.label && (
                <KeyValueGroup>
                  <KeyValue
                    className={`key-value--${
                      index === 0 ? 'column' : 'inline'
                    }`}
                    valueClass="fw500"
                    label={item.label}
                    value={item.value}
                  />
                </KeyValueGroup>
              )}
              {!item.label && (
                <KeyValueGroup itemClass="mb0">
                  {item.map(reviewInfo => (
                    <KeyValue
                      className="key-value--inline"
                      key={reviewInfo.label}
                      label={reviewInfo.label}
                      value={reviewInfo.value}
                    />
                  ))}
                </KeyValueGroup>
              )}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};
export default ReviewCard;
