// an object of the CMS ids (endpoints)
// the type should be equal to the part before .json e.g. page/type.json
export const cmsEndpoints = {
  selectIntent: {
    endpoint: 'digitalconnection/issuetype/selectIntent.json',
    type: 'selectIntent',
  },
  contactInfo: {
    endpoint: 'digitalconnection/contact-info/contactInfo.json',
    type: 'contactInfo',
  },
  reviewInfo: {
    endpoint: 'digitalconnection/review-info/reviewInfo.json',
    type: 'reviewInfo',
  },
  confirmation: {
    endpoint: 'digitalconnection/confirmation/confirmation.json',
    type: 'confirmation',
  },
  credits: {
    endpoint: 'creditrequest/credits/creditRequest.json',
    type: 'credits',
  },
};
