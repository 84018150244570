import mtmClient from '../../../fetch/apiClient';
import {
  OutageHistoryAPI,
  OutageCreditsEligibilityAPI,
  OutageCreditAPI,
} from '../../../api/apis';
import { getOauth } from '../../../helpers/auth';
import { getFeatureFlagValue } from '../../../helpers/featureFlags';

export const GET_OUTAGE_HISTORY = 'GET_OUTAGE_HISTORY';
export const GET_OUTAGE_HISTORY_SUCCESS = 'GET_OUTAGE_HISTORY_SUCCESS';
export const GET_OUTAGE_HISTORY_FAILURE = 'GET_OUTAGE_HISTORY_FAILURE';
export const SUBMIT_OUTAGE_DETAILS = 'SUBMIT_OUTAGE_DETAILS';
export const GET_OUTAGE_CREDIT_ELIGIBILITY = 'GET_OUTAGE_CREDIT_ELIGIBILITY';
export const GET_OUTAGE_CREDIT_ELIGIBILITY_SUCCESS =
  'GET_OUTAGE_CREDIT_ELIGIBILITY_SUCCESS';
export const GET_OUTAGE_CREDIT_ELIGIBILITY_FAILURE =
  'GET_OUTAGE_CREDIT_ELIGIBILITY_FAILURE';
export const POST_OUTAGE_CREDIT = 'POST_OUTAGE_CREDIT';
export const POST_OUTAGE_CREDIT_SUCCESS = 'POST_OUTAGE_CREDIT_SUCCESS';
export const POST_OUTAGE_CREDIT_FAILURE = 'POST_OUTAGE_CREDIT_FAILURE';

const dispatchGetOutageHistorySuccess = response => ({
  type: GET_OUTAGE_HISTORY_SUCCESS,
  payload: response,
});

const dispatchGetOutageHistoryFailure = error => ({
  type: GET_OUTAGE_HISTORY_FAILURE,
  payload: error,
});

const dispatchGetOutageHistory = accessToken => {
  return {
    type: GET_OUTAGE_HISTORY,
    payload: mtmClient.fetch(OutageHistoryAPI, {
      method: 'GET',
      sessionRequired: false,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }),
  };
};

export const getOutageHistory = () => async dispatch => {
  try {
    const { accessToken } = await getOauth();
    const response = await dispatch(dispatchGetOutageHistory(accessToken))
      .payload;
    await dispatch(dispatchGetOutageHistorySuccess(response));
    return response;
  } catch (error) {
    dispatch(dispatchGetOutageHistoryFailure(error));
    throw error;
  }
};

export const submitOutageDetails = payload => ({
  type: SUBMIT_OUTAGE_DETAILS,
  payload,
});

const dispatchGetOutageCreditEligibilitySuccess = response => ({
  type: GET_OUTAGE_CREDIT_ELIGIBILITY_SUCCESS,
  payload: response,
});

const dispatchGetOutageCreditEligibilityFailure = error => ({
  type: GET_OUTAGE_CREDIT_ELIGIBILITY_FAILURE,
  payload: error,
});

const dispatchGetOutageCreditEligibility = (outageInfo, accessToken) => {
  const formattedMessageFeatureFlagFor4101IneligibleCode = getFeatureFlagValue(
    'showFormattedMessageAndMultipleCTAsFor4101IneligibleCode',
    false,
  );
  return {
    type: GET_OUTAGE_CREDIT_ELIGIBILITY,
    payload: mtmClient.fetch(
      OutageCreditsEligibilityAPI({
        ...outageInfo,
        messageChoice4101: formattedMessageFeatureFlagFor4101IneligibleCode,
      }),
      {
        method: 'GET',
        sessionRequired: false,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      },
    ),
  };
};

export const getOutageCreditEligibility = data => async dispatch => {
  try {
    const { accessToken } = await getOauth();
    const response = await dispatch(
      dispatchGetOutageCreditEligibility(data, accessToken),
    ).payload;
    await dispatch(dispatchGetOutageCreditEligibilitySuccess(response));
    return response.eligibleForCredit;
  } catch (error) {
    dispatch(dispatchGetOutageCreditEligibilityFailure(error));
    throw error;
  }
};

export const dispatchPostOutageCredit = (outageInfo, accessToken) => {
  return {
    type: POST_OUTAGE_CREDIT,
    payload: mtmClient.fetch(OutageCreditAPI, {
      method: 'POST',
      body: JSON.stringify(outageInfo),
      sessionRequired: false,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }),
  };
};

const dispatchPostOutageCreditSuccess = payload => ({
  type: POST_OUTAGE_CREDIT_SUCCESS,
  payload,
});
const dispatchPostOutageCreditFailure = () => ({
  type: POST_OUTAGE_CREDIT_FAILURE,
});

export const postOutageCredit = data => async dispatch => {
  try {
    const { accessToken } = await getOauth();
    const response = await dispatch(dispatchPostOutageCredit(data, accessToken))
      .payload;
    return dispatch(dispatchPostOutageCreditSuccess(response));
  } catch (error) {
    dispatch(dispatchPostOutageCreditFailure());
    throw error;
  }
};
