import React from 'react';

const DatePickerOverlay = ({ classNames, selectedDay, children, ...props }) => {
  return (
    <div className={classNames.overlayWrapper} {...props}>
      <div className={classNames.overlay}>
        {children}
        <div className="DayPicker__Key">
          <div className="DayPicker__selected">Selected date</div>
        </div>
      </div>
    </div>
  );
};
export default DatePickerOverlay;
