/* eslint-disable no-await-in-loop */
import { DocumentAPI } from '../../api/apis';
import { generateDocumentPayload } from '../../helpers/documentUpload';
import mtmClient from '../../fetch/apiClient';

export const UPLOAD_DOCUMENT = 'UPLOAD_DOCUMENT';
export const UPLOAD_DOCUMENT_SUCCESS = 'UPLOAD_DOCUMENT_SUCCESS';
export const UPLOAD_DOCUMENT_FAILURE = 'UPLOAD_DOCUMENT_FAILURE';

export const DELETE_DOCUMENT = 'DELETE_DOCUMENT';
export const DELETE_DOCUMENT_SUCCESS = 'DELETE_DOCUMENT_SUCCESS';
export const DELETE_DOCUMENT_FAILURE = 'DELETE_DOCUMENT_FAILURE';

const dispatchUploadDocument = requestPayload => ({
  // Note that the 'uploadDocuments.documents' property in the store will be an object
  // whose keys are the hashes of the documents.
  // This allows the user to re-upload the same file without creating a duplicate.
  type: UPLOAD_DOCUMENT,
  payload: {
    [requestPayload.hash]: {
      ...requestPayload,
      loading: true,
      status: 'uploading',
    },
  },
});

const dispatchUploadDocumentSuccess = responsePayload => ({
  type: UPLOAD_DOCUMENT_SUCCESS,
  payload: {
    [responsePayload.hash]: {
      fileName: responsePayload.fileName,
      hash: responsePayload.hash,
      documentId: responsePayload.documentId,
      loading: false,
      status: 'done',
    },
  },
});

const dispatchUploadDocumentFailure = (requestPayload, e) => ({
  type: UPLOAD_DOCUMENT_FAILURE,
  payload: {
    hash: requestPayload.hash,
    error: e,
  },
});

const dispatchDeleteDocument = file => ({
  type: DELETE_DOCUMENT,
  payload: file.hash,
});

const dispatchDeleteDocumentSuccess = response => ({
  type: DELETE_DOCUMENT_SUCCESS,
  payload: response,
});

const dispatchDeleteDocumentFailure = (document, error) => ({
  type: DELETE_DOCUMENT_FAILURE,
  payload: {
    hash: document.hash,
    error,
  },
});

export const deleteDocument = document => async (dispatch, getState) => {
  try {
    // Dispatch delete document action to the store
    dispatch(dispatchDeleteDocument(document));

    /*
    If document upload failed (i.e. it was never successfully uploaded in the first place),
    dispatch the successs scenario
    */
    if (document.status === 'failed') {
      const {
        uploadDocuments: { documents },
      } = getState();
      delete documents[document.hash];
      return dispatch(dispatchDeleteDocumentSuccess(documents));
    }

    const { documentId } = document;

    // Call SSM to delete document
    await mtmClient.fetch(`${DocumentAPI}/${documentId}`, {
      method: 'DELETE',
    });
    const {
      uploadDocuments: { documents },
    } = getState();
    delete documents[document.hash];
    return dispatch(dispatchDeleteDocumentSuccess(documents));
  } catch (error) {
    return dispatch(dispatchDeleteDocumentFailure(document, error));
  }
};

export const uploadDocument = document => async dispatch => {
  // Generate chunked payloads for document
  const chunkedPayload = await generateDocumentPayload(document);

  // Get documentGUID (hash) and name from first payload
  const { documentGUID, documentName } = chunkedPayload[0];
  const requestData = {
    hash: documentGUID,
    fileName: documentName,
  };

  try {
    if (requestData) {
      // Dispatch document upload to store
      dispatch(dispatchUploadDocument(requestData));

      // Send payloads serially:
      // Each payload is sent and the for loop awaits for it to complete before sending the next.
      // This is necessary for SSM to recognize the last chunk and give the correct response.
      // The response is then appended to the responses object.
      const responses = [];
      for (const payload of chunkedPayload) {
        const response = await mtmClient.fetch(DocumentAPI, {
          method: 'POST',
          body: JSON.stringify(payload),
        });
        responses.push(response);
      }

      // Await for all responses to be resolved
      const promises = await Promise.all(responses);

      // Find the success promise with 200 status
      const successPromise = promises.find(
        response => Object.keys(response).length !== 0,
      );

      // Dispatch success if the response is successfully resolved and the hash matches the request hash
      if (successPromise) {
        const responsePayload = successPromise;
        if (responsePayload.hash && responsePayload.hash === requestData.hash) {
          return dispatch(dispatchUploadDocumentSuccess(responsePayload));
        }
      }
      // Else dispatch the error
      // The reqeust data is included in the payload as it allows the application to identify the document
      // in the event that the hash isn't present in the response.
      return dispatch(dispatchUploadDocumentFailure(requestData, promises));
    }
  } catch (error) {
    return dispatch(dispatchUploadDocumentFailure(requestData, error));
  }
  return dispatch(dispatchUploadDocumentFailure(requestData));
};

export default uploadDocument;
