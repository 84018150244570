import React from 'react';
import { Form, Formik } from 'formik';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { PageSection } from 'adc-ui-components';

import PageTitle from '../../../components/layout/PageTitle';
import EmailField from '../../../components/form-controls/fields/Email';
import ConfirmEmailField from '../../../components/form-controls/fields/ConfirmEmail';
import NavigationButtons from '../../../components/NavigationButtons';

import { SPORTS_REFUND } from '../../../helpers/routes';
import FocusErrorField from '../../../helpers/FocusErrorField';
import { validateEmail } from '../../../helpers/SportsRefundHelpers';

import { updateEmailInfo } from '../Actions/UpdateEmailAddress';

const Email = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const initialValues = {
    email: '',
    confirmEmailAddress: '',
  };

  const onSubmit = formValues => {
    const { email, confirmEmailAddress } = formValues;
    const values = {
      email: email?.toLowerCase().trim(),
      confirmEmail: confirmEmailAddress?.toLowerCase().trim(),
    };

    dispatch(updateEmailInfo(values.email));
    history.push(SPORTS_REFUND);
  };

  return (
    <PageSection className="page-section--banner">
      <PageTitle title="Please enter your preferred email address" noFocus />
      <Formik
        initialValues={initialValues}
        validate={validateEmail}
        onSubmit={onSubmit}
        validateOnBlur={false}
      >
        {() => (
          <Form noValidate>
            <div className="card-group text-align-left mb30">
              <div className="card-group__item">
                <div className="card">
                  <div className="card__content">
                    <div className="form-control-group">
                      <div className="form-control-group__item">
                        <EmailField label="Email Address" />
                        <ConfirmEmailField label="Re-enter email address" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <FocusErrorField fieldOrders={Object.keys(initialValues)} />

            <NavigationButtons
              labelForNext="Save"
              labelForPrevious="Cancel"
              navLinkForBack={SPORTS_REFUND}
            />
          </Form>
        )}
      </Formik>
    </PageSection>
  );
};
export default Email;
