import React from 'react';

export default ({ className = '', ...props }) => (
  <span className={`svg-icon ${className}`} {...props}>
    <svg
      width="29"
      height="36"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
      aria-hidden="true"
    >
      <g fill="none" fillRule="evenodd">
        <path
          d="M18.5 0l10 10-.001.5h.001V33c0 1.65685-1.34315 3-3 3H3c-1.65685 0-3-1.34315-3-3V3c0-1.65685 1.34315-3 3-3h15.5zm-2.001 1.5H3c-.76925 0-1.40326.57906-1.4899 1.32507L1.5 3v30c0 .76925.57906 1.40326 1.32507 1.4899L3 34.5h22.5c.76925 0 1.40326-.57906 1.4899-1.32507L27 33V12h-7.506l-.1685-.005a3.0125 3.0125 0 01-.24825-.02455 2.99212 2.99212 0 01-.19037-.03315 2.97581 2.97581 0 01-.23042-.05752c-.19553-.05778-.37524-.13095-.54535-.21996a3.00331 3.00331 0 01-.54341-.36491 3.02015 3.02015 0 01-.25016-.23653 3.0168 3.0168 0 01-.24147-.29038l-.0166-.02295a3.00707 3.00707 0 01-.11771-.17726 2.99075 2.99075 0 01-.23475-.47051 2.98202 2.98202 0 01-.04972-.13609 2.97923 2.97923 0 01-.12179-.49822 2.9985 2.9985 0 01-.02727-.23908l-.0015-.02135A3.04463 3.04463 0 0116.5 9l-.001-7.5zM14.25 13.9501l5.77483 5.65699-1.04966 1.07154L14.999 16.783 15 26.33333h-1.5l-.001-9.55033-3.97417 3.89563-1.04966-1.07154L14.25 13.9501zM18 1.622V9l.005.131.00169.01155a1.49033 1.49033 0 00.16023.5458 1.50158 1.50158 0 00.22122.31853 1.51082 1.51082 0 00.1265.1241l-.03153-.02828a1.50799 1.50799 0 00.10511.08847l-.07359-.06019a1.50729 1.50729 0 00.08655.07001l-.01296-.00982a1.50398 1.50398 0 00.1056.074l-.09264-.06418a1.50206 1.50206 0 00.1583.10378l-.06566-.0396a1.49831 1.49831 0 00.1187.06836l-.05303-.02876c.03651.02077.07399.04005.11234.05776l-.05931-.029a1.49243 1.49243 0 00.12421.05712l-.0649-.02812a1.48993 1.48993 0 00.14451.05779l-.0796-.02967a1.48867 1.48867 0 00.12386.04396l-.04426-.0143c.03885.01323.0784.0249.11861.03496l-.07435-.02066a1.48934 1.48934 0 00.14521.03655l-.07086-.0159a1.49258 1.49258 0 00.14472.02867l-.07386-.01277a1.50694 1.50694 0 00.29016.02883L26.8785 10.5 18 1.622z"
          fill="#0272B6"
          fillRule="nonzero"
        />
      </g>
    </svg>
  </span>
);
