import React from 'react';
import { Route, Link } from 'react-router-dom';
import {
  BEREAVEMENT,
  BEREAVEMENT_UPLOAD_DOCUMENTS,
  BEREAVEMENT_CONFIRMATION,
  BEREAVEMENT_REPRESENTATIVE_INFO,
  BEREAVEMENT_ACCOUNT_HOLDER_INFO,
  BEREAVEMENT_DISCONNECT,
  BEREAVEMENT_REVIEW,
  BEREAVEMENT_STATEMENT,
  REPORT_ISSUE,
  CREDITS,
  INELIGIBLE_CREDITS,
  SPORTS_REFUND,
  SPORTS_REFUND_REVIEW,
  SPORTS_REFUND_CONFIRMATION,
  SPORTS_REFUND_HISTORY,
} from '../../helpers/routes';

const DefaultLayout = ({
  component: Component,
  isHarness,
  device,
  ...props
}) => (
  <Route
    {...props}
    render={matchProps => (
      <>
        <header>
          <xc-header flownav hide-back simpleheader />
        </header>
        <main className="page-content">
          <Component {...matchProps} {...props} />
        </main>
        <footer>
          <xc-footer slim-footer theme="light" hide-panels />
        </footer>
        {process.env.NODE_ENV !== 'production' && (
          <ul>
            <li>
              <Link to={BEREAVEMENT}>Bereavement Flow</Link>
            </li>
            <li>
              <Link to={BEREAVEMENT_UPLOAD_DOCUMENTS}>
                Document Upload Page
              </Link>
            </li>
            <li>
              <Link to={BEREAVEMENT_REPRESENTATIVE_INFO}>
                Bereavement representative info
              </Link>
            </li>
            <li>
              <Link to={BEREAVEMENT_ACCOUNT_HOLDER_INFO}>
                Account holder info
              </Link>
            </li>
            <li>
              <Link to={BEREAVEMENT_CONFIRMATION}>
                Disconnect request confirmation page
              </Link>
            </li>
            <li>
              <Link to={BEREAVEMENT_DISCONNECT}>
                Bereavement Disconnect Selection
              </Link>
            </li>
            <li>
              <Link to={BEREAVEMENT_STATEMENT}>
                Bereavement Final Statement
              </Link>
            </li>
            <li>
              <Link to={BEREAVEMENT_REVIEW}>Bereavement Final Review</Link>
            </li>
            <li>
              <Link to={REPORT_ISSUE}>Issue Reporting Flow</Link>
            </li>
            <li>
              <Link to={CREDITS}>Credit Selection Flow</Link>
            </li>
            <li>
              <Link to={INELIGIBLE_CREDITS}>Credits Ineligibility Page</Link>
            </li>
            <li>
              <Link to={SPORTS_REFUND}>RSN Landing Page</Link>
            </li>
            <li>
              <Link to={SPORTS_REFUND_REVIEW}>RSN Review Page</Link>
            </li>
            <li>
              <Link to={SPORTS_REFUND_CONFIRMATION}>RSN Confirmation Page</Link>
            </li>
            <li>
              <Link to={SPORTS_REFUND_HISTORY}>RSN History Page</Link>
            </li>
          </ul>
        )}
      </>
    )}
  />
);

export default DefaultLayout;
