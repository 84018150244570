import en from 'nanoid-good/locale/en';
import nanoid from 'nanoid-good';
import Cookies from 'js-cookie';
import getConfig from '../../config';
import { decodeToken } from '../jwt';

const { env, clientID, sessionCookieName } = getConfig();

const uuid = nanoid(en)();

const getUUID = () => {
  const session = Cookies.get(sessionCookieName);

  if (!session) {
    return 'NO-SESSION-YET';
  }
  try {
    const tokenInfo = decodeToken(session);
    return tokenInfo.sessionId;
  } catch (e) {
    return 'Unable to decode token';
  }
};

const objFromError = err => {
  if (!(err instanceof Error)) {
    return err;
  }

  const obj = {
    name: err.stack,
    message: err.message,
  };

  if (err.stack) {
    obj.stack = err.stack;
  }

  return obj;
};

const defaultLogData = () => ({
  env,
  uri: window.location.href,
  page: window.location.pathname,
  appname: clientID,
  session_uuid: getUUID(),
  _time: Date.now(),
  uuid,
});

export const formatAppLog = (event, details = {}) => {
  const { error, ...info } = details;
  const logError = objFromError(error);

  return {
    event,
    message: { ...info, error: logError },
    ...defaultLogData(),
  };
};

export const formatServiceCallLog = (details = {}) => {
  return {
    ...details,
    ...defaultLogData(),
  };
};
