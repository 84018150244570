import React from 'react';
import { FormControl } from 'adc-ui-components';
import { Field } from 'formik';
import InputField from '../../form-elements/InputField';
import { RequiredFactory } from '../../../helpers/validation/required';

const vRequired = RequiredFactory();

const City = ({ label, className, ...props }) => (
  <FormControl inputId="city" label={label} className={className}>
    <Field
      id="city"
      type="text"
      name="city"
      aria-autocomplete="none"
      component={InputField}
      validate={value => vRequired('Please enter a city')(value)}
      {...props}
    />
  </FormControl>
);

export default City;
