import React from 'react';

const Document = () => (
  <svg
    width="15"
    height="18"
    xmlns="http://www.w3.org/2000/svg"
    role="img"
    aria-hidden="true"
  >
    <g fill="#44484C" fillRule="evenodd">
      <path
        d="M9.25 0l5 5-.001.25h.001V16.5c0 .828427-.671573 1.5-1.5 1.5H1.5C.671573 18 0 17.328427 0 16.5v-15C0 .671573.671573 0 1.5 0h7.75zm.5 6c-.069468 0-.137832-.004722-.20479-.013863-.069937-.009566-.137968-.023847-.20414-.042555a1.482189 1.482189 0 01-.077808-.024318 1.496023 1.496023 0 01-.051042-.018548 1.484921 1.484921 0 01-.065022-.026757 1.495636 1.495636 0 01-.098029-.047408 1.499364 1.499364 0 01-.10842-.063355 1.50368 1.50368 0 01-.127178-.091331 1.50776 1.50776 0 01-.102052-.089477 1.507334 1.507334 0 01-.050504-.050825 1.513054 1.513054 0 01-.040208-.04415 1.503523 1.503523 0 01-.048153-.057875 1.510343 1.510343 0 01-.034387-.045223 1.501585 1.501585 0 01-.07264-.10904 1.496546 1.496546 0 01-.065637-.120677 1.484341 1.484341 0 01-.028188-.061538 1.493349 1.493349 0 01-.029384-.073505 1.491892 1.491892 0 01-.013144-.037101 1.48586 1.48586 0 01-.027936-.091942 1.489361 1.489361 0 01-.027409-.122199 1.495153 1.495153 0 01-.010067-.063532C8.254722 4.637832 8.25 4.569468 8.25 4.5L8.249.75H1.5c-.384627 0-.70163.28953-.744954.662534L.75 1.5v15c0 .384627.28953.70163.662534.744954L1.5 17.25h11.25c.384627 0 .70163-.28953.744954-.662534L13.5 16.5V6H9.75zM9 .811V4.5l.003.053.004024.050067c.009261.067387.027475.13192.053402.192358l.041971.083114a.75054.75054 0 00.02772.04378.754348.754348 0 00.091982.11043c.036127.035746.075381.067526.117592.095165a.747843.747843 0 00.037355.022928l-.037355-.022928a.749364.749364 0 00.065839.038475l-.028484-.015547a.746981.746981 0 00.087391.04288l-.058907-.027333a.745794.745794 0 00.086095.037918l-.027188-.010586a.744229.744229 0 00.095529.031994l-.06834-.021408a.744171.744171 0 00.087392.026116l-.019052-.004708a.746866.746866 0 00.102568.01924l.003378.000384L9.75 5.25h3.68925L9 .811z"
        fillRule="nonzero"
      />
      <path d="M2.625 8.625h8.625v1H2.625zM2.625 10.875h8.625v1H2.625zM2.625 13.125h8.625v1H2.625z" />
    </g>
  </svg>
);
export default Document;
