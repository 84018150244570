import logger from './kibana';
import performance from './performance';

const { collectTimings } = performance(logger);
collectTimings();

const stacktraceFromException = errorObj =>
  errorObj.stack || errorObj.backtrace || errorObj.stacktrace;

function generateStacktrace() {
  let generated;
  let stacktrace;

  const MAX_FAKE_STACK_SIZE = 10;
  const ANONYMOUS_FUNCTION_PLACEHOLDER = '[anonymous]';
  const FUNCTION_REGEX = /function\s*([\w\-$]+)?\s*\(/i;

  // Try to generate a real stacktrace (most browsers, except IE9 and below).
  try {
    throw new Error('');
  } catch (exception) {
    generated = '<generated>';
    stacktrace = stacktraceFromException(exception);
  }

  // Otherwise, build a fake stacktrace from the list of method names by
  // looping through the list of functions that called this one (and skip
  // whoever called us).
  if (!stacktrace) {
    generated = '<generated-ie>';
    let functionStack = [];
    try {
      // eslint-disable-next-line no-caller, no-restricted-properties
      let curr = arguments.callee.caller.caller;
      while (curr && functionStack.length < MAX_FAKE_STACK_SIZE) {
        const fn = FUNCTION_REGEX.test(curr.toString())
          ? RegExp.$1 || ANONYMOUS_FUNCTION_PLACEHOLDER
          : ANONYMOUS_FUNCTION_PLACEHOLDER;
        functionStack.push(fn);
        curr = curr.caller;
      }
    } catch (e) {
      functionStack = e;
    }
    stacktrace = functionStack.join('|');
  }
  return generated + stacktrace;
}

window.onerror = (errorMsg, url, lineNumber, column, errorObj) => {
  const data = {
    name: (errorObj && errorObj.name) || 'window.onerror',
    stacktrace:
      (errorObj && stacktraceFromException(errorObj)) || generateStacktrace(),
  };

  if (errorMsg) {
    data.message = errorMsg;
  }

  if (url) {
    data.script = url;
  }

  if (lineNumber) {
    data.line = lineNumber;
  }

  if (column) {
    data.column = column;
  }

  logger.log('js_error', { error: data });
};

export default logger;
