import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { PageSection } from 'adc-ui-components';
import IconCircleExclamation from '../../components/svgs/IconCircleExclamation';

class NoMatch extends Component {
  componentDidMount() {
    // todo: add logging here
  }

  render() {
    return (
      <PageSection className="ui-grey page-section--banner">
        <IconCircleExclamation className="mb6 svg-icon--42" />

        <div className="hgroup">
          <h1 tabIndex="-1">Not Found</h1>
          <p>The page you&apos;re looking for is not here.</p>
        </div>

        <Link className="button button--primary" to="/">
          Go Back
        </Link>
      </PageSection>
    );
  }
}

export default NoMatch;
