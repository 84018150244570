import React from 'react';
import { PageSection } from 'adc-ui-components';
import DisconnectForm from '../../../components/forms/DisconnectForm';
import PageTitle from '../../../components/layout/PageTitle';

const Disconnection = () => {
  return (
    <PageSection className="page-section--banner">
      <PageTitle
        title="When would you like to disconnect services?"
        subtitle="To avoid charges for unused services, you can pre-date your service
        disconnection up to 120 days prior. You can also choose to disconnect up
        to 60 days from now."
      />
      <DisconnectForm />
    </PageSection>
  );
};

export default Disconnection;
