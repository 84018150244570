import {
  FETCH_CMS,
  FETCH_CMS_SUCCESS,
  FETCH_CMS_FAILURE,
} from '../actions/cmsActions';

const INITIAL_STATE = {
  // Storing all of the cms in here in case we will have to add different flow
  reportIssue: {},
  credits: {},
};

export default (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
    case FETCH_CMS:
      return {
        ...state,
        [action.payload.flow]: {
          ...state[action.payload.flow],
          [action.payload.type]: {
            loading: true,
          },
        },
      };
    case FETCH_CMS_SUCCESS:
      return {
        ...state,
        [action.payload.flow]: {
          ...state[action.payload.flow],
          [action.payload.type]: {
            loading: false,
            ...(action.payload.flow === 'credits'
              ? action.payload.cmsData
              : action.payload.cmsData[action.payload.type]),
          },
        },
      };
    case FETCH_CMS_FAILURE:
      return {
        ...state,
        reportIssue: {
          ...state.reportIssue,
          [action.payload.type]: {
            loading: false,
            error: action.payload.message,
          },
        },
      };
    default:
      return state;
  }
};
