import {
  SUBMIT_ISSUE_TYPE,
  SUBMIT_USER_INFO,
  POST_ISSUE_TICKET,
  POST_ISSUE_TICKET_SUCCESS,
  POST_ISSUE_TICKET_FAILURE,
} from '../actions/ReportIssueActions';

const INITIAL_STATE = {
  problemDetails: { problemType: '', problemLabel: '', deeplinked: false },
  userDetails: { email: '', mobile: '', comment: '' },
  loading: false,
};

export default (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
    // Store transaction type
    case SUBMIT_ISSUE_TYPE:
      return {
        ...state,
        problemDetails: action.payload,
      };
    // Saving the account holder details into the store.
    case SUBMIT_USER_INFO:
      return {
        ...state,
        userDetails: action.payload,
      };
    case POST_ISSUE_TICKET:
      return {
        ...state,
        loading: true,
      };
    case POST_ISSUE_TICKET_SUCCESS:
      return {
        ...state,
        loading: false,
        confirmationData: action.payload,
      };
    case POST_ISSUE_TICKET_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};
