import React from 'react';
import { UploadItem } from './UploadItem';

export const UploadDetails = ({ documents }) => {
  if (!documents.length) {
    return null;
  }

  return (
    <div className="upload__details">
      <h3>Upload details</h3>
      <div className="card-group">
        {documents.map(document => (
          <UploadItem item={document} key={document.hash} />
        ))}
      </div>
    </div>
  );
};

export default UploadDetails;
