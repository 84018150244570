import React from 'react';
import dateFnsFormat from 'date-fns/format';

export const parseDate = dateStr => {
  let parts;
  let day;
  let month;
  let year;
  let delimiter;
  // Fallback to today if dateString is falsy
  let dateString = (dateStr || new Date()).toString();
  if (dateString.match(/^\d+-\d+-\d+[T\s]\d+:\d+:\d+$/)) {
    // MAW-814: Removing timepart in case when date is like "2016-08-04 14:28:03"
    [dateString] = dateString.split(' ');
  }
  if (dateString.match(/^\d{4}\D\d+\D\d+$/)) {
    // Case when date has format yyyy-MM-dd
    [delimiter] = dateString.match(/\D/);
    parts = dateString.split(delimiter);
    [year, month, day] = parts;
  } else if (dateString.match(/^\d+\D\d+\D\d+$/)) {
    // Case when date is a timestamp or formatted like MM/dd/yy or MM/dd/yyyy
    [delimiter] = dateString.match(/\D/);
    parts = dateString.split(delimiter);
    [month, day, year] = parts;
  } else if (dateString.match(/^[-+]?\d+$/)) {
    // Should match any positive or negative number string to dates before 1970
    return new Date(+dateString).setHours(0, 0, 0, 0);
  } else {
    return new Date(dateString).setHours(0, 0, 0, 0);
  }
  // Move to local noon and return timestamp.
  return new Date(year, month - 1, day, 0, 0, 0, 0).getTime();
};

export const today = () => {
  const thisDate = new Date();
  thisDate.setHours(0, 0, 0, 0);
  return thisDate;
};

export const monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const formatMonthDateYear = date => {
  const d = new Date(date);
  return `${monthNames[d.getMonth()]} ${d.getDate()}, ${d.getFullYear()}`;
};

export const createDateObject = date => {
  let parsedDate;
  let thisDate;

  if (date) {
    if (date instanceof Date) {
      thisDate = date;
      thisDate.setHours(0, 0, 0, 0);
    } else {
      parsedDate = parseDate(date);
      thisDate = new Date(parsedDate);
      thisDate.setHours(0, 0, 0, 0);
    }
    return thisDate;
  }

  return null;
};

export const addDays = (date, days = 0) => {
  const thisDate = createDateObject(date);
  const returnDate = new Date();
  const timeOffset = days * 864e5;
  returnDate.setTime(thisDate.getTime() + timeOffset);
  return returnDate;
};

export const subtractDays = (date, days = 0) => {
  const thisDate = createDateObject(date);
  const returnDate = new Date();
  const timeOffset = days * 864e5;
  returnDate.setTime(thisDate.getTime() - timeOffset);
  return returnDate;
};

const isDate = date => date instanceof Date;

export const compareDays = (date1, date2, comparator) => {
  // IE11 doesn't support passing a Date object into the Date constructor
  // so we do a type-check before formatting into a yyyy-mm-dd string
  const a = ((isDate(date1) ? date1 : new Date(date1)).toJSON() || '').substr(
    0,
    10,
  );
  const b = ((isDate(date2) ? date2 : new Date(date2)).toJSON() || '').substr(
    0,
    10,
  );
  return comparator(a, b);
};

export const createRange = (start, end, leadingZero) => {
  const array = [];

  let i;
  let string;

  for (i = start; i <= end; i += 1) {
    string = '';

    if (leadingZero && i < 10) {
      string = '0';
    }

    string += String(i);

    array.push(string);
  }

  return array;
};

export const months = createRange(1, 12, true);
export const years = createRange(
  new Date().getFullYear(),
  new Date().getFullYear() + 10,
);

export const renderOptions = values =>
  values.map(item => (
    <option key={item} value={item}>
      {item}
    </option>
  ));

export const isWithinNDays = (date, daysToAdd, daysToSubtract) => {
  const dateObject = createDateObject(date);
  return (
    compareDays(
      dateObject,
      subtractDays(today(), daysToSubtract),
      (a, b) => a >= b,
    ) && compareDays(dateObject, addDays(today(), daysToAdd), (a, b) => a <= b)
  );
};

export const formatDate = (date, format) =>
  dateFnsFormat(parseDate(date), format);

export const dayDifferenceFromToday = date => {
  if (!date) {
    // eslint-disable-next-line no-param-reassign
    date = new Date();
  }
  const todaysDate = new Date();
  const timeDiff =
    createDateObject(todaysDate).getTime() - createDateObject(date).getTime();
  const dayDiff = timeDiff / (1000 * 3600 * 24);
  return Math.round(dayDiff);
};
