import React from 'react';
import { PageSection } from 'adc-ui-components';
import AccountHolderForm from '../../../components/forms/AccountHolderForm';
import PageTitle from '../../../components/layout/PageTitle';

const AccountHolderInfo = () => (
  <div>
    <PageSection className="page-section--banner">
      <PageTitle
        title="Provide as much information as you can about the account holder"
        subtitle="We’ll use this to find and verify the account"
      />
      <AccountHolderForm />
    </PageSection>
  </div>
);

export default AccountHolderInfo;
