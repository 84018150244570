import getConfig from '../config';

const {
  apis: { ssm, cmsData, creditsVideoThumbnail, xapi },
} = getConfig();
/**
 * CMS ENDPOINTS
 */
// This will be where we pass in the last part of the cms url
// e.g. issuetype/selectIntent.json
export const getCMSItemAPI = itemId => `${cmsData}/${itemId}`;

/**
 * BEREAVEMENT FLOW
 */

// SSM Session
export const SessionAPI = `${ssm}/mtm/session`;

export const XapiSegment = (
  segmentName = 'helpandsupport',
  caller = 'helpsupport',
) => `${xapi}/personalization/evaluation?TAGS=${segmentName}&caller=${caller}`;

// SDS (Secure Document Service Endpoints) - Routed through SSM
export const DocumentAPI = `${ssm}/mtm/document`;

// SSM PSP EROne Ticket
export const CreateEROneTicketAPI = `${ssm}/mtm/psp`;

export const TypeAheadAPI = `${ssm}/mtm/typeahead`;

/**
 * DTC FLOW
 */

export const UserAPI = `${ssm}/user`;
export const CreateIssueEROneTicketAPI = `${ssm}/escalate`;

/**
 * CREDITS FLOW
 */

// SSM Late Fee Credits APIs
export const LateFeeCreditEligibilityAPI = `${ssm}/credits/latefee/eligibility`;
export const IssueLateFeeCreditAPI = `${ssm}/credits/latefee`;

// SSM Outage History API
export const OutageHistoryAPI = `${ssm}/outage/history`;

// SSM Outage Credit APIs
export const OutageCreditAPI = `${ssm}/credits/outage/adjustment`;
export const OutageCreditsEligibilityAPI = ({
  startDate,
  endDate,
  messageChoice4101,
}) =>
  `${ssm}/credits/outage/adjustment/eligibility?startDate=${startDate}&endDate=${endDate}${
    messageChoice4101 ? '&4101messageChoice=b' : ''
  }`;

// SSM Video Credit API
export const VideoAssetAPI = `${ssm}/credits/video/assets`;
export const getVideoCreditEligibilityAPI = (
  purchaseTokenId,
  orderDateTime,
  type,
) =>
  `${ssm}/credits/video/assets/${purchaseTokenId}/eligibility?orderDateTime=${orderDateTime}&assetType=${type}`;
export const getVideoThumbnailSrc = entityId =>
  `${creditsVideoThumbnail}/image?entityId=${entityId}&width=300&height=400&default=true`;

// SSM Video issue credits APIs
export const getIssuingVideoCreditAPI = purchaseTokenId =>
  `${ssm}/credits/video/assets/${purchaseTokenId}`;

// SSM Credit Eligibility
export const CreditEligibilityAPI = `${ssm}/credits/adjustment/eligibility?additionalInfo=market,segments,role`;

// SSM sports refund
export const SportsRefund = `${ssm}/refunds/sports`;

// SSM Address API (for RSN)
export const AddressTypeahead = `${ssm}/address/typeahead`;
