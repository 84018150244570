import React from 'react';

const InputField = ({
  field = {},
  className = '',
  forceError,
  id,
  form: { submitCount, errors = {}, values = {}, touched = {} },
  ...props
}) => {
  const isFormSubmitted = !!submitCount;
  const hasAttemptedInput = !!values[field.name] && touched[field.name];
  const shouldShowError =
    (isFormSubmitted || hasAttemptedInput) && errors[field.name];
  const shouldShowErrorHighlight = shouldShowError || forceError;
  const errorMessageId = `error_${id}`;

  return (
    <>
      <input
        id={id}
        className={`${className} ${shouldShowErrorHighlight ? 'error' : ''}`}
        aria-invalid={!!shouldShowError}
        aria-describedby={errorMessageId}
        {...field}
        {...props}
      />

      {shouldShowError && (
        <span id={errorMessageId} className="form-control__error" role="alert">
          {errors[field.name]}
        </span>
      )}
    </>
  );
};

export default InputField;
