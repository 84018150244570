import mtmClient from '../../../fetch/apiClient';
import { CreditEligibilityAPI } from '../../../api/apis';
import { getOauth } from '../../../helpers/auth';

export const GET_CREDIT_ELIGIBILITY = 'GET_CREDIT_ELIGIBILITY';
export const GET_CREDIT_ELIGIBILITY_SUCCESS = 'GET_CREDIT_ELIGIBILITY_SUCCESS';
export const GET_CREDIT_ELIGIBILITY_FAILURE = 'GET_CREDIT_ELIGIBILITY_FAILURE';

export const dispatchGetCreditEligibility = accessToken => {
  return {
    type: GET_CREDIT_ELIGIBILITY,
    payload: mtmClient.fetch(CreditEligibilityAPI, {
      sessionRequired: false,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }),
  };
};

const dispatchGetCreditEligibilitySuccess = payload => ({
  type: GET_CREDIT_ELIGIBILITY_SUCCESS,
  payload,
});

const dispatchGetCreditEligibilityFailure = () => ({
  type: GET_CREDIT_ELIGIBILITY_FAILURE,
});

export const getCreditEligibility = location => async (dispatch, getState) => {
  // if we already fetched the eligibility criteria, we don't need to fetch again
  // unless the data is no longer in state
  if (getState().credits.eligibility.isCreditEligible) {
    return null;
  }
  try {
    const { accessToken } = await getOauth();
    const response = await dispatch(dispatchGetCreditEligibility(accessToken))
      .payload;
    const isCreditEligible =
      location.pathname === '/credits/video/details'
        ? true
        : response.creditEligible;
    const { ineligibleReason, ineligibleCode } = response;
    // using optional chaining in case segments comes back as null or undefined, but it
    // should come back as [] if it is not there
    const showCreditOption = response.segments?.data.some(
      segment => segment.name === 'helpandsupport-issue-credit',
    );
    const showOutageOption = response.segments?.data.some(
      segment => segment.name === 'helpandsupport-issue-outage-credit',
    );
    const showLateFeeOption = response.segments?.data.some(
      segment => segment.name === 'helpandsupport-issue-latefee-credit',
    );
    const showVideoOption = response.segments?.data.some(
      segment => segment.name === 'helpandsupport-issue-video-credit',
    );
    dispatch(
      dispatchGetCreditEligibilitySuccess({
        isCreditEligible,
        ineligibleReason,
        ineligibleCode,
        showCreditOption,
        showOutageOption,
        showLateFeeOption,
        showVideoOption,
      }),
    );
    return isCreditEligible;
  } catch (error) {
    dispatch(dispatchGetCreditEligibilityFailure());
    throw error;
  }
};
