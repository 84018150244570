import React from 'react';
import { FormControl } from 'adc-ui-components';
import { Field } from 'formik';

const StreetAddress2 = ({ label, className, ...props }) => (
  <FormControl inputId="streetAddress2" label={label} className={className}>
    <Field
      id="streetAddress2"
      type="text"
      name="streetAddress2"
      aria-autocomplete="none"
      placeholder="Apartment, suite, unit, building, floor, etc."
      {...props}
    />
  </FormControl>
);

export default StreetAddress2;
