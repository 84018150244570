import {
  GET_OUTAGE_HISTORY,
  GET_OUTAGE_HISTORY_SUCCESS,
  GET_OUTAGE_HISTORY_FAILURE,
  SUBMIT_OUTAGE_DETAILS,
  GET_OUTAGE_CREDIT_ELIGIBILITY,
  GET_OUTAGE_CREDIT_ELIGIBILITY_SUCCESS,
  GET_OUTAGE_CREDIT_ELIGIBILITY_FAILURE,
  POST_OUTAGE_CREDIT,
  POST_OUTAGE_CREDIT_SUCCESS,
  POST_OUTAGE_CREDIT_FAILURE,
} from './OutageCreditActions';

const INITIAL_STATE = {
  startDate: '',
  endDate: '',
  servicesImpacted: '',
  loading: false,
};

export default (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
    case GET_OUTAGE_HISTORY:
      return {
        ...state,
        loading: true,
      };
    case GET_OUTAGE_HISTORY_SUCCESS:
      return {
        ...state,
        loading: false,
        ...action.payload,
      };
    case GET_OUTAGE_HISTORY_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case SUBMIT_OUTAGE_DETAILS:
      return {
        ...state,
        ...action.payload,
      };
    case GET_OUTAGE_CREDIT_ELIGIBILITY:
      return {
        ...state,
        loading: true,
      };
    case GET_OUTAGE_CREDIT_ELIGIBILITY_SUCCESS:
      return {
        ...state,
        loading: false,
        ...action.payload,
      };
    case GET_OUTAGE_CREDIT_ELIGIBILITY_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case POST_OUTAGE_CREDIT:
      return {
        ...state,
        loading: true,
      };
    case POST_OUTAGE_CREDIT_SUCCESS:
      return {
        ...state,
        ...action.payload,
        loading: false,
      };
    /** @todo see if we want to force failure when a user is not eligible */
    case POST_OUTAGE_CREDIT_FAILURE:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};
