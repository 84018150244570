import { getStoredSession } from '../../helpers/session';

// authHeader factory provides necessary headers for the fetch call
// sessionRequired is optional and set to true by default
// if sessionRequired is set to true fetch would need an session token
export default ({ fetch = window.fetch, logger = console }) => {
  const middleware = (url, options = {}) => {
    const authHeaders = { 'Content-Type': 'application/json' };
    const { sessionRequired = true, headers = {}, ...requestOpts } = options;

    if (sessionRequired) {
      const token = getStoredSession();

      if (!token) {
        throw new Error(`Missing SSM Session Token for ${url}!`);
      }

      // Add the auth header to the headers
      Object.assign(authHeaders, {
        Authorization: `Bearer ${token}`,
      });
    }

    return fetch(url, {
      ...requestOpts,
      headers: new Headers({
        ...authHeaders,
        ...headers,
      }),
    });
  };

  return {
    fetch: middleware,
    logger,
  };
};
