import { CreateEROneTicketAPI } from '../../api/apis';
import mtmClient from '../../fetch/apiClient';
import { generateEROneRequest } from '../../helpers/EROne';

export const SUBMIT_TRANSACTION = 'SUBMIT_TRANSACTION';
export const SUBMIT_ACCOUNT_HOLDER_INFO = 'SUBMIT_ACCOUNT_HOLDER_INFO';
export const SUBMIT_REPRESENTATIVE_INFO = 'SUBMIT_REPRESENTATIVE_INFO';
export const SUBMIT_DISCONNECT_DETAILS = 'SUBMIT_DISCONNECT_DETAILS';
export const SUBMIT_EFFECTIVE_DATE = 'SUBMIT_EFFECTIVE_DATE';
export const SUBMIT_SELECTED_ADDRESS = 'SUBMIT_SELECTED_ADDRESS ';
export const CREATE_ER_ONE_TICKET = 'CREATE_ER_ONE_TICKET';
export const CREATE_ER_ONE_TICKET_SUCCESS = 'CREATE_ER_ONE_TICKET_SUCCESS';
export const CREATE_ER_ONE_TICKET_FAILURE = 'CREATE_ER_ONE_FAILURE';
export const SUBMIT_FINAL_STATEMENT_ADDRESS = 'SUBMIT_FINAL_STATEMENT_ADDRESS ';

// Returning payloads for the respective actions.
export const dispatchSubmitTransaction = payload => ({
  type: SUBMIT_TRANSACTION,
  payload: { ...payload },
});

export const submitAccountHolderInfo = accountInfo => {
  return {
    type: SUBMIT_ACCOUNT_HOLDER_INFO,
    payload: accountInfo,
  };
};

export const submitRepresentativeInfo = repInfo => {
  return {
    type: SUBMIT_REPRESENTATIVE_INFO,
    payload: repInfo,
  };
};

export const submitDisconnectDetails = data => {
  return {
    type: SUBMIT_DISCONNECT_DETAILS,
    payload: data,
  };
};

export const submitFinalStatementAddress = value => {
  return {
    type: SUBMIT_FINAL_STATEMENT_ADDRESS,
    payload: value,
  };
};

const dispatchCreateEROneTicket = () => ({
  type: CREATE_ER_ONE_TICKET,
});

const dispatchCreateEROneTicketSuccess = response => ({
  type: CREATE_ER_ONE_TICKET_SUCCESS,
  payload: response,
});

const dispatchCreateEROneTicketFailure = error => ({
  type: CREATE_ER_ONE_TICKET_FAILURE,
  payload: error,
});

// SSM call to create an EROne ticket
export const createEROneTicket = bereavementDetails => async dispatch => {
  try {
    const requestPayload = generateEROneRequest(bereavementDetails);
    dispatch(dispatchCreateEROneTicket());
    const response = await mtmClient.fetch(CreateEROneTicketAPI, {
      method: 'POST',
      body: JSON.stringify(requestPayload),
    });
    return dispatch(dispatchCreateEROneTicketSuccess(response));
  } catch (error) {
    dispatch(dispatchCreateEROneTicketFailure(error.message));
    // Error thrown to catch it in catch block of final review page submit handler.
    throw error;
  }
};
