import React from 'react';
import { FormControl } from 'adc-ui-components';
import { Field } from 'formik';
import InputField from '../../form-elements/InputField';
import { EmailFactory } from '../../../helpers/validation/email';

const vEmail = EmailFactory();
const vMessage = 'Please enter a valid email address';

export const defaultEmailValidation = vEmail(vMessage);
export const defaultValidate = value => defaultEmailValidation(value);

const EmailField = ({
  validate = defaultValidate,
  className,
  label,
  optional,
  ...props
}) => (
  <FormControl className={className}>
    <div className="form-control__label">
      <label htmlFor="email">
        {label}
        {optional && (
          <span className="form-control__label--helper">(optional)</span>
        )}
      </label>
    </div>
    <Field
      id="email"
      type="email"
      name="email"
      component={InputField}
      validate={validate}
      {...props}
    />
  </FormControl>
);

export default EmailField;
