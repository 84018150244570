import memoized from '../memoized';

export const AccountValidation = memoized(message => input => {
  if (!input) {
    return undefined;
  }
  return input.trim().length === 16 && !isNaN(input)
    ? undefined
    : message || true;
});

export default AccountValidation;
