// This file holds all possible default configuration
export default {
  env: undefined,
  disableApp: false,
  clientID: 'mtm-account-change',
  publicURL: process.env.PUBLIC_URL,
  sessionCookieName: 'mtm_s',
  meta: {
    title: 'Moments that Matter',
  },
  apis: {
    ssm: 'https://api.sc.xfinity.com',
    cmsData: 'https://sdx.qa.xfinity.com/cms/data/helpandsupport',
    creditsVideoThumbnail: 'http://edge.myriad-xcr.xcr.comcast.net/select',
    xapi: 'https://xapi.xfinity.com',
    channelTracking: 'https://secure-niagaralog-uat.codebig2.net/',
  },
  features: {
    showCPP: true,
    showCreditIssueOption: false,
    showFormattedMessageAndMultipleCTAsFor4101IneligibleCode: true,
  },
  oauthOptions: {
    clientId: 'mtm-account-change',
    idmClientId: 'dss-refund',
    authorizeUrl: 'https://oauth.xfinity.com/oauth/authorize',
    iframeRedirectUri: '/support/account-management/token.html',
    defaultRedirectUri: '/support/account-management/issue-reporting',
  },
};
