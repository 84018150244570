import React, { useEffect } from 'react';
import { Route, Switch, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
  VIDEO_CONFIRMATION,
  VIDEO_REVIEW,
  INELIGIBLE_CREDITS,
  VIDEO_DETAILS,
} from '../../../helpers/routes';
import VideoConfirmation from './Views/Confirmation';
import VideoDetails from './Views/Details';
import VideoReview from './Views/Review';

const VideoCreditsFlow = () => {
  const history = useHistory();
  const {
    eligibility: { showVideoOption },
  } = useSelector(state => state.credits);
  useEffect(() => {
    if (!showVideoOption) {
      history.push(INELIGIBLE_CREDITS);
    }
  }, [history, showVideoOption]);

  return (
    <Switch>
      <Route exact path={VIDEO_DETAILS} component={VideoDetails} />
      <Route exact path={VIDEO_REVIEW} component={VideoReview} />
      <Route exact path={VIDEO_CONFIRMATION} component={VideoConfirmation} />
    </Switch>
  );
};
export default VideoCreditsFlow;
