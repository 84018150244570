import React from 'react';
import IconCircleExclamation from '../svgs/IconCircleExclamation';
import Modal from './Modal';

const UnhandledError = ({ closeModal, dismissable }) => (
  <Modal isOpen onRequestClose={closeModal} dismissable={dismissable}>
    <div className="text-center">
      <IconCircleExclamation className="mb12 svg-icon--42" />
      <div className="hgroup mb24">
        <h2>Looks like something went wrong</h2>
        <p className="body2">Your information was not submitted.</p>
      </div>
      <button
        className="button button--primary"
        type="button"
        onClick={closeModal}
      >
        Try Again
      </button>
    </div>
  </Modal>
);

export default UnhandledError;
