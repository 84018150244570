import React from 'react';
import { FormControl } from 'adc-ui-components';
import { Field } from 'formik';

const MobilePhone = ({ className, label, optional, formatPhone, ...props }) => (
  <FormControl className={className}>
    <div className="form-control__label">
      <label htmlFor="phone">
        {label}
        {optional && (
          <span className="form-control__label--helper">(optional)</span>
        )}
      </label>
    </div>
    <Field {...props}>
      {({
        field: fieldProps,
        form: { setFieldValue, touched, values, submitCount, errors },
      }) => {
        const isFormSubmitted = !!submitCount;
        const hasAttemptedInput = !!values.phone && touched.phone;
        const shouldShowError =
          (isFormSubmitted || hasAttemptedInput) && errors.phone;
        const handleChange = e => {
          const formattedPhone = formatPhone(e.target.value);
          setFieldValue('phone', formattedPhone);
        };
        return (
          <>
            <input
              {...fieldProps}
              className={`${shouldShowError ? 'error' : ''}`}
              onChange={handleChange}
              id="phone"
              name="phone"
              type="tel"
              value={fieldProps.value.phone || ''}
            />
            {shouldShowError && (
              <span
                id="error_phone"
                className="form-control__error"
                role="alert"
              >
                {errors.phone}
              </span>
            )}
          </>
        );
      }}
    </Field>
  </FormControl>
);

export default MobilePhone;
