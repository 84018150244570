import React, { useEffect } from 'react';
import { Switch, Route, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Loading } from 'adc-ui-components';

import LateFeeLanding from './Views/LateFeeLanding';
import LateFeeConfirmation from './Views/LateFeeConfirmation';
import { fetchLateFeeCreditEligibility } from './LateFeeActions';
import {
  LATE_FEE_CREDITS,
  LATE_FEE_CONFIRMATION,
  INELIGIBLE_CREDITS,
} from '../../../helpers/routes';

const LateFeeFlow = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  // @TODO - Update with selectors
  const { loading, eligibility: { lateFeeCreditEligible } = {} } = useSelector(
    state => state.credits.lateFee,
  );

  useEffect(() => {
    dispatch(fetchLateFeeCreditEligibility());
  }, [dispatch]);

  if (loading) {
    return <Loading />;
  }

  // Redirect if not eligible
  if (!loading && !lateFeeCreditEligible) {
    history.push(INELIGIBLE_CREDITS);
  }

  return (
    <Switch>
      <Route
        exact
        path={LATE_FEE_CONFIRMATION}
        component={LateFeeConfirmation}
      />
      <Route path={LATE_FEE_CREDITS} component={LateFeeLanding} />
    </Switch>
  );
};

export default LateFeeFlow;
