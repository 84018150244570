import mtmClient from '../../../fetch/apiClient';
import {
  LateFeeCreditEligibilityAPI,
  IssueLateFeeCreditAPI,
} from '../../../api/apis';
import { getOauth } from '../../../helpers/auth';

export const FETCH_LATE_FEE_CREDIT_ELIGIBILITY =
  'FETCH_LATE_FEE_CREDIT_ELIGIBILITY';
export const FETCH_LATE_FEE_CREDIT_ELIGIBILITY_SUCCESS =
  'FETCH_LATE_FEE_CREDIT_ELIGIBILITY_SUCCESS';
export const FETCH_LATE_FEE_CREDIT_ELIGIBILITY_FAILURE =
  'FETCH_LATE_FEE_CREDIT_ELIGIBILITY_FAILURE';

export const ISSUE_LATE_FEE_CREDIT = 'ISSUE_LATE_FEE_CREDIT';
export const ISSUE_LATE_FEE_CREDIT_SUCCESS = 'ISSUE_LATE_FEE_CREDIT_SUCCESS';
export const ISSUE_LATE_FEE_CREDIT_FAILURE = 'ISSUE_LATE_FEE_CREDIT_FAILURE';

/**
 * LATE FEE CREDIT ELIGIBILITY
 */
const dispatchFetchLateFeeCreditEligibility = accessToken => ({
  type: FETCH_LATE_FEE_CREDIT_ELIGIBILITY,
  payload: mtmClient.fetch(LateFeeCreditEligibilityAPI, {
    method: 'GET',
    sessionRequired: false,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  }),
});

const dispatchFetchLateFeeCreditEligibilitySuccess = payload => ({
  type: FETCH_LATE_FEE_CREDIT_ELIGIBILITY_SUCCESS,
  payload,
});

const dispatchFetchLateFeeCreditEligibilityFailure = payload => ({
  type: FETCH_LATE_FEE_CREDIT_ELIGIBILITY_FAILURE,
  payload,
});

export const fetchLateFeeCreditEligibility = () => async dispatch => {
  try {
    const { accessToken } = await getOauth();
    const response = await dispatch(
      dispatchFetchLateFeeCreditEligibility(accessToken),
    ).payload;
    return dispatch(dispatchFetchLateFeeCreditEligibilitySuccess(response));
  } catch (error) {
    dispatch(dispatchFetchLateFeeCreditEligibilityFailure(error));
    throw error;
  }
};

/**
 * ISSUE LATE FEE CREDIT
 */

const dispatchIssueLateFeeCredit = (creditAmount, accessToken) => ({
  type: ISSUE_LATE_FEE_CREDIT,
  payload: mtmClient.fetch(IssueLateFeeCreditAPI, {
    method: 'POST',
    body: JSON.stringify({
      amount: creditAmount,
    }),
    sessionRequired: false,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  }),
});

const dispatchIssueLateFeeCreditSuccess = payload => ({
  type: ISSUE_LATE_FEE_CREDIT_SUCCESS,
  payload,
});

const dispatchIssueLateFeeCreditFailure = payload => ({
  type: ISSUE_LATE_FEE_CREDIT_FAILURE,
  payload,
});

export const issueLateFeeCredit = creditAmount => async dispatch => {
  try {
    const { accessToken } = await getOauth();
    const response = await dispatch(
      dispatchIssueLateFeeCredit(creditAmount, accessToken),
    ).payload;
    return dispatch(dispatchIssueLateFeeCreditSuccess(response));
  } catch (error) {
    dispatch(dispatchIssueLateFeeCreditFailure(error));
    throw error;
  }
};
