import React from 'react';
import { useDispatch } from 'react-redux';
import XClose from '../svgs/XClose';
import { deleteDocument } from '../../apps/Bereavement/uploadDocumentsActions';
import Document from '../svgs/Document';

export const UploadItem = ({ item }) => {
  const dispatch = useDispatch();
  const { fileName, status } = item;
  return (
    <div className={`card-group__item ${status}`}>
      <div className="card card--action-right">
        <div className="card__content">
          <span title={fileName} className="body4 upload__details--fileName">
            <Document />
            {fileName}
          </span>
          <div className="upload__details--status">
            {status === 'uploading' && (
              <p className="body4 loading-circle">Uploading...</p>
            )}
            {status === 'done' && <p className="body4">Done</p>}
            {status === 'failed' && <p className="body4">Upload Failed</p>}
            {status === 'deleting' && <p className="body4">Deleting</p>}
            {(status === 'done' || status === 'failed') && (
              <XClose
                onClick={() => dispatch(deleteDocument(item))}
                className="svg-icon--12"
                tabIndex="0"
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default UploadItem;
