import { isWithinNDays, formatDate } from '../date';
import { getResolution } from '../resolution';

export const vDateSelectionRange = (day, after = 60, prior = 120) => {
  const date = new Date(day);
  date.setHours(0, 0, 0, 0);
  return isWithinNDays(date, after, prior)
    ? null
    : `Please select a date within the past 90 days (including today)`;
};

export const vEndDateGreaterThanStart = (day1, day2) =>
  new Date(day1) > new Date(day2)
    ? 'Please select an end date after the selected start date'
    : null;

export const vDateAfterDays = (day1, day2, after = 7, text = 'date') => {
  const diff = Math.floor((Date.parse(day2) - Date.parse(day1)) / 86400000);
  return diff <= after
    ? null
    : `Please select a date within ${after} days of the ${text}.`;
};

export const vValidDate = day => {
  if (
    day.length < 10 ||
    (getResolution() === 'desktop' && !isValidDateFormat(day)) ||
    isNaN(Date.parse(day))
  ) {
    return 'Select a valid date.';
  }
  return null;
};

// Validates that the input string is a valid date formatted as "mm/dd/yyyy"
export const isValidDateFormat = inputDate => {
  let date;
  if (typeof inputDate !== 'string') {
    date = formatDate(new Date(inputDate), 'MM/dd/yyyy');
  } else {
    date = inputDate;
  }
  // First check for the pattern
  if (!/^\d{1,2}\/\d{1,2}\/\d{4}$/.test(date)) {
    return false;
  }

  // Parse the date parts to integers
  const parts = date.split('/');
  const day = parseInt(parts[1], 10);
  const month = parseInt(parts[0], 10);
  const year = parseInt(parts[2], 10);

  // Check the ranges of month and year
  if (year < 1000 || year > 3000 || month === 0 || month > 12) {
    return false;
  }

  const monthLength = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

  // Adjust for leap years
  if (year % 400 === 0 || (year % 100 !== 0 && year % 4 === 0)) {
    monthLength[1] = 29;
  }

  // Check the range of the day
  return day > 0 && day <= monthLength[month - 1];
};
