import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { version } from '../../package.json';

// replacing any dashes with spaces.
const formatScreenName = pageName => pageName.replace(/-/g, ' ');

export const getScreenNameFromPath = path => {
  const pathArray = path.substring(1).split('/');
  // Checking pathArray for depth level of routes greater than or equal to 2
  // Index 0 is subCategory1 & for index more than 1 including it as part of screen names.
  if (pathArray.length >= 2) {
    let screenName = '';
    pathArray.slice(1, pathArray.length).forEach(pathName => {
      screenName += `${formatScreenName(pathName)} `;
    });
    return screenName.trim();
  }
  // bereavement/issue reporting landing page, return introduction
  // courtesy-adjustment return landing
  return pathArray[0] === 'courtesy-adjustment' ? 'landing' : 'introduction';
};

export const getBaseNameFromPath = path => {
  // Splits path by '/'
  // Returns the first element, the top level flow
  const pathArray = path.substring(1).split('/');
  return formatScreenName(pathArray[0]);
};

export const PageTracking = () => {
  const location = useLocation();
  const { pathname } = location;

  useEffect(() => {
    // c-tracking-log-page-start - used for tracking page performance
    // c-tracking-log-page - will search the markup of the entire page for page view...
    // ...related script blocks and use what it finds to initialize the data layer.
    document.dispatchEvent(
      new CustomEvent('c-tracking-log-page-start', {
        bubbles: true,
      }),
    );
    document.body.dispatchEvent(
      new CustomEvent('c-tracking-log-page', {
        bubbles: true,
      }),
    );
  }, [pathname]);

  return (
    // this is used to pass additional info to the data layer
    // screenName and subCategory1 change depending on the flow
    <script type="tracking-data-page">
      {JSON.stringify({
        pageInfo: { screenName: getScreenNameFromPath(pathname) },
        category: { subCategory1: getBaseNameFromPath(pathname) },
      })}
    </script>
  );
};

// this is base data layer object that adobe needs to track page and click tracking
// click tracking is in-built and no additional work needs to be done
// devs can debug datalayer by using window.trackingDebug = true in browser console
// or by digitalData in browser console
export const BaseDataLayer = () => {
  const baseDigitalData = {
    schemaVersion: 0.11,
    page: {
      category: {
        primaryCategory: 'help & support',
        businessType: 'resi',
        siteType: 'selfservice',
        designType: 'responsive',
      },
      codebase: {
        name: 'Moments that Matter',
        releaseVersion: version,
      },
      affiliate: {
        channel: 'web',
        name: 'comcast',
      },
      pageInfo: {
        language: 'en',
        attributes: {
          experience: 'web',
        },
      },
      attributes: {},
    },
  };

  return (
    <script type="tracking-data-digitalData">
      {JSON.stringify(baseDigitalData)}
    </script>
  );
};
