import 'core-js/fn/array/find';
import 'core-js/fn/string/starts-with';
import 'core-js/fn/string/repeat';
import 'core-js/fn/string/pad-start';
import 'react-app-polyfill/ie11';

import React from 'react';
import { render } from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';

import Root from './apps/Core/Root';
import configureStore from './store/configureStore';
import { BASENAME } from './helpers/routes';

require('./sass/style.scss');

const store = configureStore();
const root = document.getElementById('root');

render(
  <Router basename={BASENAME}>
    <Root store={store} />
  </Router>,
  root,
);
