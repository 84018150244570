import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Switch, Route, useHistory, useLocation } from 'react-router-dom';
import { Loading } from 'adc-ui-components';

import { getUser } from '../../shared/actions/userActions';
import PermsError from '../Errors/PermsError';
import IssueConfirmation from './Views/confirmation';
import Details from './Views/details';
import Review from './Views/review';
import IssueType from './Views/IssueType';
import { submitIssuePassedByChannels } from './actions/ReportIssueActions';
import { getCmsContent } from '../../shared/actions/cmsActions';
import { dispatchSetModal } from '../../shared/actions/userMessagesActions';
import { UNHANDLED_ERROR } from '../../components/modals/ModalHandler';

import {
  ISSUE_CONFIRMATION,
  ISSUE_DETAILS,
  ISSUE_REVIEW,
  REPORT_ISSUE,
} from '../../helpers/routes';

const ReportIssueApp = () => {
  const [deepLinkChecked, setDeepLinkChecked] = useState(false);
  const { loading: userLoading, data: { role = '' } = {} } = useSelector(
    state => state.user,
  );
  const { problemType } = useSelector(
    state => state.reportIssue.problemDetails,
  );
  const history = useHistory();
  const location = useLocation();

  const loading = userLoading && !deepLinkChecked;

  const dispatch = useDispatch();
  useEffect(() => {
    const checkForIssueDeepLink = async () => {
      try {
        await dispatch(getCmsContent());
        await dispatch(getUser());
        if (
          location.search ||
          (!problemType && location.pathname !== REPORT_ISSUE)
        ) {
          await dispatch(submitIssuePassedByChannels());
          if (location.pathname !== ISSUE_DETAILS) {
            setDeepLinkChecked(true);
            history.push(ISSUE_DETAILS);
          }
        }
      } catch (e) {
        dispatch(dispatchSetModal({ showModal: true, type: UNHANDLED_ERROR }));
      }
    };
    checkForIssueDeepLink();
  }, [problemType, dispatch, location, history]);

  if (loading) {
    return <Loading />;
  }

  if (role.toUpperCase().indexOf('PRIMARY') === -1) {
    return <PermsError />;
  }

  return (
    <Switch>
      <Route exact path={ISSUE_CONFIRMATION} component={IssueConfirmation} />
      <Route exact path={ISSUE_DETAILS} component={Details} />
      <Route exact path={ISSUE_REVIEW} component={Review} />
      <Route component={IssueType} />
    </Switch>
  );
};

export default ReportIssueApp;
