/* eslint-disable react/jsx-curly-brace-presence */
import React from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Formik, Form, Field } from 'formik';
import { PageSection, FormBoolean, Loading } from 'adc-ui-components';
import BooleanField from '../../../components/form-elements/BooleanField';
import NavigationButtons from '../../../components/NavigationButtons';
import {
  ISSUE_DETAILS,
  INELIGIBLE_CREDITS,
  CREDITS,
} from '../../../helpers/routes';
import { replaceCMSText } from '../../../helpers/cms';
import { submitIssueType } from '../actions/ReportIssueActions';
import CommonSolutions from '../../../components/CommonSolutions';
import CardGroup from '../../../components/CardGroup';
import FocusErrorFirstBoolean from '../../../helpers/FocusErrorFirstBoolean';
import PageTitle from '../../../components/layout/PageTitle';

const IssueType = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const reportDetails = useSelector(state => state.reportIssue);
  const { firstName } = useSelector(state => state.user.data);
  const { selectIntent } = useSelector(state => state.cms.reportIssue);
  const {
    eligibility: {
      loading: creditEligibilityLoading,
      isCreditEligible,
      showCreditOption,
    } = {},
  } = useSelector(state => state.credits);

  if (!selectIntent || selectIntent.loading || creditEligibilityLoading) {
    return <Loading />;
  }

  const {
    header: { title },
    footers,
    intents: issueOptions,
  } = selectIntent;

  const {
    problemDetails: { problemType: initialProblemValue },
  } = reportDetails;

  const optionsVariables = issueOptions.map(issue => issue.variable);

  const onFormSubmit = values => {
    if (values.issueType === 'CREDIT') {
      if (isCreditEligible) {
        return history.push(CREDITS);
      }
      return history.push(INELIGIBLE_CREDITS);
    }
    const reportInfo = issueOptions.find(
      ({ variable }) => variable === values.issueType,
    );
    dispatch(
      submitIssueType({
        problemType: reportInfo.variable,
        problemLabel: reportInfo.text,
      }),
    );
    return history.push(`${ISSUE_DETAILS}`);
  };

  const validateForm = values => {
    const { issueType } = values;
    const errors = {};

    if (!issueType) {
      errors.chooseAnOption = 'Please choose an option to continue';
    }
    return errors;
  };

  return (
    <PageSection className="page-section--banner">
      <PageTitle
        title={replaceCMSText(title, '$firstName', firstName)}
        noFocus
      />
      <Formik
        validate={validateForm}
        validateOnChange
        validateOnBlur={false}
        onSubmit={onFormSubmit}
        initialValues={{ issueType: initialProblemValue }}
      >
        {({ errors }) => (
          <Form noValidate>
            {errors.chooseAnOption && (
              <div
                className="form-control__error error-top"
                id="err-msg"
                tabIndex="-1"
              >
                {errors.chooseAnOption}
              </div>
            )}

            <div className="mb24">
              <CardGroup>
                {issueOptions
                  .filter(option =>
                    // if we are not showing the credit option we filter it out of the issueOptions array
                    // if we are showing it then keep everything
                    option.variable === 'CREDIT' ? showCreditOption : true,
                  )
                  .map(({ text, variable }) => (
                    <div className="card text-align-left" key={variable}>
                      <div className="card__content">
                        <FormBoolean
                          label={text}
                          inputId={variable.replace(' ', '-')}
                          labelClass="body2"
                        >
                          <Field
                            id={variable.replace(' ', '-')}
                            name="issueType"
                            component={BooleanField}
                            type="radio"
                            value={variable}
                            forceError={!!errors.chooseAnOption}
                            aria-invalid={!!errors.chooseAnOption}
                            aria-describedby="err-msg"
                          />
                        </FormBoolean>
                      </div>
                    </div>
                  ))}
              </CardGroup>
            </div>
            <FocusErrorFirstBoolean booleanValues={optionsVariables} />
            <NavigationButtons
              navLinkForBack={'https://www.xfinity.com'}
              labelForNext="Continue"
              labelForPrevious="Cancel"
              navExternalLink
            />
          </Form>
        )}
      </Formik>
      <CommonSolutions footers={footers} />
    </PageSection>
  );
};
export default IssueType;
