import memoized from '../memoized';

export const RequiredFactory = memoized(message => value => {
  if (typeof value !== 'string') {
    return value ? undefined : message || true;
  }
  return value && value.trim() ? undefined : message || true;
});

export default value => (value ? undefined : true);
