import mtmClient from '../../../fetch/apiClient';
import { getIdmAuth } from '../../../helpers/auth';
import { XapiSegment } from '../../../api/apis';

export const GET_XAPI_SEGMENT = 'GET_XAPI_SEGMENT';
export const XAPI_REFUND_SEGMENT_SUCCESS = 'XAPI_REFUND_SEGMENT_SUCCESS';
export const XAPI_REFUND_SEGMENT_FAILURE = 'XAPI_REFUND_SEGMENT_FAILURE';

export const getRefundSegmentInfo = () => async dispatch => {
  try {
    const { accessToken } = await getIdmAuth();
    const response = await dispatch(dispatchGetRefundSegments(accessToken))
      .payload;
    return await dispatch(dispatchGetRefundSegmentsSuccess(response));
  } catch (error) {
    await dispatch(dispatchGetRefundSegmentsFailure(error));
    throw error;
  }
};

const dispatchGetRefundSegments = accessToken => {
  return {
    type: GET_XAPI_SEGMENT,
    payload: mtmClient.fetch(XapiSegment('helpandsupport'), {
      sessionRequired: false,
      method: 'GET',
      headers: {
        'X-User-CimaAuthorization': accessToken,
      },
    }),
  };
};

const dispatchGetRefundSegmentsSuccess = payload => ({
  type: XAPI_REFUND_SEGMENT_SUCCESS,
  payload,
});

const dispatchGetRefundSegmentsFailure = payload => ({
  type: XAPI_REFUND_SEGMENT_FAILURE,
  payload,
});
