import React from 'react';
import FirstName from './fields/FirstName';
import LastName from './fields/LastName';

const FirstLastNameFields = ({
  labelFirstName,
  labelLastName,
  classFirstName,
  classLastName,
  ...props
}) => (
  <div className="form-control-group form-control-group--flex-at-768">
    <div className="form-control-group__item">
      <FirstName className={classFirstName} label={labelFirstName} {...props} />
    </div>
    <div className="form-control-group__item">
      <LastName className={classLastName} label={labelLastName} {...props} />
    </div>
  </div>
);

export default FirstLastNameFields;
