import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Switch, Route, useHistory, useLocation } from 'react-router-dom';
import { Loading } from 'adc-ui-components';

import { getUser } from '../../shared/actions/userActions';
import PermsError from '../Errors/PermsError';
import CreditSelection from './Views/CreditsSelection';
import LateFeeFlow from './LateFee';
import VideoCreditsFlow from './Video';
import OutageCreditsFlow from './Outage';
import Ineligible from './Views/Ineligible';
import {
  VIDEO_CREDITS,
  OUTAGE_CREDITS,
  INELIGIBLE_CREDITS,
  LATE_FEE_CREDITS,
} from '../../helpers/routes';
import { getCreditEligibility } from './Eligibility/EligibilityActions';

import { getCmsContent } from '../../shared/actions/cmsActions';

const CreditsApp = () => {
  const { loading: userLoader, data: { role = '' } = {} } = useSelector(
    state => state.user,
  );

  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const {
    eligibility: {
      isCreditEligibilityChecked = false,
      loading: eligibilityLoader,
    },
  } = useSelector(state => state.credits);

  useEffect(() => {
    const creditEligibilityCheck = async () => {
      const isCreditEligible = await dispatch(getCreditEligibility(location));
      if (!isCreditEligible) {
        history.push(INELIGIBLE_CREDITS);
      }
    };

    if (!isCreditEligibilityChecked) {
      creditEligibilityCheck();
    }
  }, [dispatch, history, isCreditEligibilityChecked, location]);

  useEffect(() => {
    dispatch(getUser()); // call getUsers when flow mounts
    dispatch(getCmsContent());
  }, [dispatch]);

  if (userLoader || eligibilityLoader) {
    return <Loading />;
  }

  const lowerRole = role.toLowerCase();
  if (
    !lowerRole.includes('primary') &&
    !lowerRole.includes('manager') &&
    !lowerRole.includes('billpaymanager') &&
    !lowerRole.includes('billpay')
  ) {
    return <PermsError />;
  }

  return (
    <Switch>
      <Route path={VIDEO_CREDITS} component={VideoCreditsFlow} />
      <Route path={LATE_FEE_CREDITS} component={LateFeeFlow} />
      <Route path={OUTAGE_CREDITS} component={OutageCreditsFlow} />
      <Route path={INELIGIBLE_CREDITS} component={Ineligible} />
      <Route component={CreditSelection} />
    </Switch>
  );
};

export default CreditsApp;
