import {
  GET_VIDEO_ASSET,
  GET_VIDEO_ASSET_FAILURE,
  GET_VIDEO_ASSET_SUCCESS,
  USER_SELECTED_VIDEO_ASSET,
  GET_VIDEO_CREDITS_ELIGIBILITY,
  GET_VIDEO_CREDITS_ELIGIBILITY_SUCCESS,
  GET_VIDEO_CREDITS_ELIGIBILITY_FAILURE,
  ISSUE_VIDEO_CREDITS,
  ISSUE_VIDEO_CREDITS_SUCCESS,
  ISSUE_VIDEO_CREDITS_FAILURE,
} from './VideoCreditActions';

const INITIAL_STATE = {
  ineligibleCode: '',
  ineligibleReason: null,
};

export default (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
    case GET_VIDEO_ASSET:
      return {
        ...state,
        loading: true,
      };
    case GET_VIDEO_ASSET_SUCCESS:
      return {
        ...state,
        loading: false,
        videoAssets: action.payload,
      };
    case GET_VIDEO_ASSET_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case USER_SELECTED_VIDEO_ASSET:
      return {
        ...state,
        selectedAssetForCredit: action.payload,
      };
    case GET_VIDEO_CREDITS_ELIGIBILITY:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case GET_VIDEO_CREDITS_ELIGIBILITY_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        ineligibleCode: action.payload.ineligibleCode,
        ineligibleReason: action.payload.ineligibleReason,
        selectedAssetForCredit: {
          ...state.selectedAssetForCredit,
          eligible: action.payload.refundableFlag,
        },
      };
    case GET_VIDEO_CREDITS_ELIGIBILITY_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case ISSUE_VIDEO_CREDITS:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case ISSUE_VIDEO_CREDITS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        selectedAssetForCredit: {
          ...state.selectedAssetForCredit,
          ...action.payload,
        },
      };
    case ISSUE_VIDEO_CREDITS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
