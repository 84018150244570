/* eslint-disable no-unneeded-ternary */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Formik, Form } from 'formik';
import { useHistory } from 'react-router-dom';
import Email from '../../../components/form-controls/fields/Email';
import MobilePhone from '../../../components/form-controls/fields/MobilePhone';
import SelectIssueDropdown from '../../../components/form-controls/SelectIssueDropdown';
import NavigationButtons from '../../../components/NavigationButtons';
import issueDetailsData from '../data/issueDetails';

import { REPORT_ISSUE, ISSUE_REVIEW } from '../../../helpers/routes';
import { submitUserInfo } from '../actions/ReportIssueActions';
import { formatPhone } from '../../../helpers/validation/phone';
import FocusErrorField from '../../../helpers/FocusErrorField';

const DetailsForm = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const {
    problemDetails: { id: problemId, deeplinked },
    userDetails: { email: emailAddress, mobile, comment },
  } = useSelector(state => state.reportIssue);
  const { contactEmail, mobileNumber, preferredEmail } = useSelector(
    state => state.user.data,
  );
  // If the customer has previously selected a comment from the dropdown
  // then make sure that the comment is one of the available options for the current problemId
  // in case the customer may have gone back to the beginning and changed the initial problem
  const issueDetailsInitialValue =
    comment &&
    (issueDetailsData[problemId] || [])
      .map(({ option }) => option)
      .indexOf(comment) > -1
      ? comment
      : '';

  const initialFormValues = {
    email: emailAddress || contactEmail || preferredEmail || '',
    phone: formatPhone(mobile) || formatPhone(mobileNumber),
    issueDetails: issueDetailsInitialValue,
  };

  const onSubmit = values => {
    const { email, phone, issueDetails } = values;
    const detailsData = {
      email,
      // remove all dashes in phone for proper backend formate
      mobile: phone.replace(/-/g, ''),
      comment: issueDetails,
    };
    dispatch(submitUserInfo(detailsData));
    return history.push(`${ISSUE_REVIEW}`);
  };

  const validate = formValues => {
    const { email, issueDetails, phone } = formValues;
    const errors = {};
    if (!email) {
      errors.email = 'Please enter a valid email address';
    }
    if (!issueDetails) {
      errors.issueDetails = 'Please select an option to continue';
    }

    if (phone && phone.length < 12) {
      errors.phone = 'Please enter a valid phone number';
    }
    return errors;
  };

  return (
    <Formik
      initialValues={initialFormValues}
      validate={validate}
      onSubmit={onSubmit}
      validateOnBlur={false}
    >
      {({ values: { description } }) => (
        <Form noValidate>
          <div className="card-group text-align-left mb30">
            <div className="card-group__item">
              <div className="card">
                <div className="card__content">
                  <Email label="Email address" />
                  <MobilePhone
                    label="Mobile phone number"
                    optional
                    formatPhone={formatPhone}
                  />
                </div>
              </div>
            </div>
            <div className="card-group__item">
              <div className="card">
                <div className="card__content">
                  <SelectIssueDropdown label="Select the option closest to your issue" />
                </div>
              </div>
            </div>
          </div>
          <FocusErrorField fieldOrders={Object.keys(initialFormValues)} />
          <NavigationButtons
            labelForNext="Continue"
            labelForPrevious="Back"
            navLinkForBack={REPORT_ISSUE}
            navToLinkBeforeDeepLink={deeplinked}
          />
        </Form>
      )}
    </Formik>
  );
};

export default DetailsForm;
