import mtmClient from '../../../fetch/apiClient';
import {
  VideoAssetAPI,
  getVideoCreditEligibilityAPI,
  getIssuingVideoCreditAPI,
} from '../../../api/apis';
import { getOauth } from '../../../helpers/auth';

export const GET_VIDEO_ASSET_SUCCESS = 'GET_VIDEO_ASSET_SUCCESS';
export const GET_VIDEO_ASSET_FAILURE = 'GET_VIDEO_ASSET_FAILURE';
export const GET_VIDEO_ASSET = 'GET_VIDEO_ASSET';
export const USER_SELECTED_VIDEO_ASSET = 'USER_SELECTED_VIDEO_ASSET';
export const GET_VIDEO_CREDITS_ELIGIBILITY = 'GET_VIDEO_CREDITS_ELIGIBILITY';
export const GET_VIDEO_CREDITS_ELIGIBILITY_SUCCESS =
  'GET_VIDEO_CREDITS_ELIGIBILITY_SUCCESS';
export const GET_VIDEO_CREDITS_ELIGIBILITY_FAILURE =
  'GET_VIDEO_CREDITS_ELIGIBILITY_FAILURE';

// Issue Video credits constants
export const ISSUE_VIDEO_CREDITS = 'ISSUE_VIDEO_CREDITS';
export const ISSUE_VIDEO_CREDITS_SUCCESS = 'ISSUE_VIDEO_CREDITS_SUCCESS';
export const ISSUE_VIDEO_CREDITS_FAILURE = 'ISSUE_VIDEO_CREDITS_FAILURE';

export const dispatchSelectedVideoAsset = data => ({
  type: USER_SELECTED_VIDEO_ASSET,
  payload: data,
});

const dispatchGetVideoAssetSuccess = response => ({
  type: GET_VIDEO_ASSET_SUCCESS,
  payload: response,
});

const dispatchGetVideoAssetFailure = error => ({
  type: GET_VIDEO_ASSET_FAILURE,
  payload: error,
});

const dispatchIssueVideoCreditsSuccess = payload => ({
  type: ISSUE_VIDEO_CREDITS_SUCCESS,
  payload,
});
const dispatchIssueVideoCreditsFailure = payload => ({
  type: ISSUE_VIDEO_CREDITS_FAILURE,
  payload,
});

const dispatchGetVideoAsset = accessToken => {
  return {
    type: GET_VIDEO_ASSET,
    payload: mtmClient.fetch(VideoAssetAPI, {
      method: 'GET',
      sessionRequired: false,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }),
  };
};

const dispatchIssueVideoCredits = (
  purchaseTokenId,
  videoAssetDetails,
  accessToken,
) => {
  return {
    type: ISSUE_VIDEO_CREDITS,
    payload: mtmClient.fetch(getIssuingVideoCreditAPI(purchaseTokenId), {
      sessionRequired: false,
      method: 'POST',
      body: JSON.stringify(videoAssetDetails),
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }),
  };
};

export const getVideoAsset = () => async dispatch => {
  try {
    const { accessToken } = await getOauth();
    const response = await dispatch(dispatchGetVideoAsset(accessToken)).payload;
    return dispatch(dispatchGetVideoAssetSuccess(response));
  } catch (error) {
    dispatch(dispatchGetVideoAssetFailure(error));
    throw error;
  }
};

const dispatchGetVideoCreditEligibilitySuccess = response => ({
  type: GET_VIDEO_CREDITS_ELIGIBILITY_SUCCESS,
  payload: response,
});

const dispatchGetVideoCreditEligibilityFailure = payload => ({
  type: GET_VIDEO_CREDITS_ELIGIBILITY_FAILURE,
  payload,
});

const dispatchGetVideoCreditEligibility = (
  purchaseTokenId,
  orderDateTime,
  type,
  accessToken,
) => {
  return {
    type: GET_VIDEO_CREDITS_ELIGIBILITY,
    payload: mtmClient.fetch(
      getVideoCreditEligibilityAPI(purchaseTokenId, orderDateTime, type),
      {
        sessionRequired: false,
        method: 'GET',
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      },
    ),
  };
};

export const getVideoCreditEligibility = (
  purchaseTokenId,
  { orderDateTime, type },
) => async dispatch => {
  try {
    const { accessToken } = await getOauth();
    const response = await dispatch(
      dispatchGetVideoCreditEligibility(
        purchaseTokenId,
        orderDateTime,
        type,
        accessToken,
      ),
    ).payload;
    await dispatch(dispatchGetVideoCreditEligibilitySuccess(response));
    return response;
  } catch (error) {
    dispatch(dispatchGetVideoCreditEligibilityFailure(error));
    throw error;
  }
};

export const issueVideoCredits = (
  purchaseTokenId,
  videoAssetDetails,
) => async dispatch => {
  try {
    const { accessToken } = await getOauth();
    const response = await dispatch(
      dispatchIssueVideoCredits(
        purchaseTokenId,
        videoAssetDetails,
        accessToken,
      ),
    ).payload;
    return await dispatch(dispatchIssueVideoCreditsSuccess(response));
  } catch (error) {
    await dispatch(dispatchIssueVideoCreditsFailure(error));
    throw error;
  }
};
