import {
  GET_CREDIT_ELIGIBILITY,
  GET_CREDIT_ELIGIBILITY_SUCCESS,
  GET_CREDIT_ELIGIBILITY_FAILURE,
} from './EligibilityActions';

const INITIAL_STATE = {
  isCreditEligible: false,
  ineligibleReason: null,
  ineligibleCode: '',
  showCreditOption: false,
  showOutageOption: false,
  showVideoOption: false,
  showLateFeeOption: false,
  loading: false,
  error: null,
};

export default (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
    case GET_CREDIT_ELIGIBILITY:
      return {
        ...state,
        loading: true,
      };
    case GET_CREDIT_ELIGIBILITY_SUCCESS:
      return {
        ...state,
        loading: false,
        ...action.payload,
        isCreditEligibilityChecked: true,
      };
    case GET_CREDIT_ELIGIBILITY_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
      };
    default:
      return state;
  }
};
