import React from 'react';
import { PageSection, Loading } from 'adc-ui-components';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import NavigationButtons from '../../../../components/NavigationButtons';
import { VIDEO_CONFIRMATION, VIDEO_DETAILS } from '../../../../helpers/routes';
import { dispatchSetModal } from '../../../../shared/actions/userMessagesActions';
import { UNHANDLED_ERROR } from '../../../../components/modals/ModalHandler';
import VideoAssetContent from '../../../../components/VideoAssetContent';
import { getLabelsOnAssetType } from '../../../../helpers/videoCredits';
import VidoeReviewCardGroupItem from '../../../../components/VideoReviewCardGroupItem';
import { issueVideoCredits } from '../VideoCreditActions';
import PageTitle from '../../../../components/layout/PageTitle';

const VideoReview = () => {
  const { video = {} } = useSelector(state => state.credits) || {};
  const { selectedAssetForCredit = {}, loading } = video;
  const {
    title = '',
    type = '',
    orderDateTime = '',
    price = '',
    purchaseTokenID = '',
    entityId = '',
  } = selectedAssetForCredit;
  const history = useHistory();
  const dispatch = useDispatch();
  const dateFormat = 'EEEE, MMMM d';

  const handleSubmit = async () => {
    try {
      const videoAssetDetails = {
        orderDateTime,
        assetType: type,
      };
      await dispatch(issueVideoCredits(purchaseTokenID, videoAssetDetails));
      return history.push(VIDEO_CONFIRMATION);
    } catch (error) {
      // Error modal scenario handled and showing error popup.
      return dispatch(
        dispatchSetModal({ showModal: true, type: UNHANDLED_ERROR }),
      );
    }
  };
  /*
    @todo This will be replaced by cms response object and its corresponding loader variable
    as page is not to be rendered until those are available.
  */
  if (!selectedAssetForCredit && loading) {
    return <Loading />;
  }
  const { demandRequestTypeLabel } = getLabelsOnAssetType(type);
  return (
    <PageSection className="page-section--banner">
      {/* {@todo: This header will be replaced once its available from CMS. } */}
      <PageTitle title="Review On Demand Credit" />
      <div className="card-group text-align-left mb24">
        <VidoeReviewCardGroupItem
          label="Type of Request"
          value={`On Demand ${demandRequestTypeLabel} Credit`}
          labelClass="body3"
          valueClass="fw500 mb0"
          keyValueClassName="key-value--column"
        />
        <div className="card-group__item card-group__item--asset-review">
          <div className="card card--has-media">
            <VideoAssetContent
              title={title}
              titleClass="body2"
              orderDateTime={orderDateTime}
              dateFormat={dateFormat}
              type={type}
              entityId={entityId}
              typeClass="body2"
              // @todo: This text will be replaced once its available from CMS.
              ownershipRemovalNote="This title will be removed from your Xfinity Order Library and
              any affiliated devices."
              ownershipRemovalClass="body2"
            />
          </div>
        </div>
        <VidoeReviewCardGroupItem
          label="Credit Amount"
          value={`$${price}`}
          valueClass="fw500 mb0"
          keyValueClassName="key-value--inline"
        />
      </div>
      <NavigationButtons
        labelForNext="Credit Your Account"
        labelForPrevious="Back"
        navExternalLink={false}
        navLinkForBack={VIDEO_DETAILS}
        handleSubmit={handleSubmit}
        submitting={loading}
      />
    </PageSection>
  );
};
export default VideoReview;
