import React from 'react';
import { useSelector } from 'react-redux';
import { PageSection } from 'adc-ui-components';
import DetailsForm from '../forms/DetailsForm';
import PageTitle from '../../../components/layout/PageTitle';

const Details = () => {
  const {
    reportIssue: {
      contactInfo: { title = '' },
    },
  } = useSelector(state => state.cms);

  return (
    <PageSection className="page-section--banner">
      <PageTitle title={title} />
      <DetailsForm />
    </PageSection>
  );
};

export default Details;
