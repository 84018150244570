import {
  FETCH_LATE_FEE_CREDIT_ELIGIBILITY,
  FETCH_LATE_FEE_CREDIT_ELIGIBILITY_SUCCESS,
  FETCH_LATE_FEE_CREDIT_ELIGIBILITY_FAILURE,
  ISSUE_LATE_FEE_CREDIT,
  ISSUE_LATE_FEE_CREDIT_SUCCESS,
  ISSUE_LATE_FEE_CREDIT_FAILURE,
} from './LateFeeActions';

const INITIAL_STATE = {
  eligibility: {
    lateFee: '',
    lateFeeDate: '',
    lateFeeCreditEligible: false,
  },
  loading: true,
  error: false,
};

export default (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
    case FETCH_LATE_FEE_CREDIT_ELIGIBILITY:
      return {
        ...state,
        eligibility: action.payload,
        loading: true,
      };
    case FETCH_LATE_FEE_CREDIT_ELIGIBILITY_SUCCESS:
      return {
        ...state,
        eligibility: action.payload,
        loading: false,
      };
    case FETCH_LATE_FEE_CREDIT_ELIGIBILITY_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case ISSUE_LATE_FEE_CREDIT:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case ISSUE_LATE_FEE_CREDIT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
      };
    case ISSUE_LATE_FEE_CREDIT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
