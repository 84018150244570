import React from 'react';
// This helper replaces placeholder tokens we retrieve from the CMS (for example, $firstName) with required dynamic text.
export const replaceCMSText = (cmsText, replacedCMSText, dynamicText) =>
  cmsText.replace(replacedCMSText, dynamicText);

export const replaceCMSTextWithLinks = cmsText => {
  // eslint-disable-next-line no-empty-character-class
  const regexToGetArrayOfLinks = /([])|\[(.*?)\]\(.*?\)/gm;

  // This will split the cms text by the text and the "text" of the md link e.g. ["We'll be in touch ", "online3", " by email ... please go to My Account in the app or ", "online"]
  // The "single" words are the "text" of the md links
  const splitCMSText = cmsText.split(regexToGetArrayOfLinks).filter(el => !!el);

  // This will give us an array of markdown urls e.g. [[online](https://customer.xfinity.com), [online2](https://customer2.xfinity.com)]
  const arrayOfLinks = cmsText.match(regexToGetArrayOfLinks);

  // this splits the md link into an object where we have the full md link, "text" of the link, and just the url of the link
  const regex = /^\[([\w\s\d]+)\]\(((?:\/|https?:\/\/)[\w\d./?=#:-]+)\)$/;

  // This is to put the md links into an object e.g. {text: 'online', url: 'https://customer.xfinity.com'}
  const regexedArrayOfLinks = arrayOfLinks.map(link => {
    // eslint-disable-next-line no-unused-vars
    const [full, text, url] = link.match(regex);
    return { text, url };
  });

  const replacedCMSArray = [];
  const createXfinityAssistantButton = (url, text) => {
    return (
      <span
        className="link fw600"
        role="link"
        tabIndex="0"
        onClick={() =>
          window.open(
            url,
            'XfinityAssistant',
            `toolbar=yes,scrollbars=yes,resizable=yes,top=${window.screen
              .height - 650},left=${window.screen.width -
              375},width=${375},height=${650}`,
          )
        }
        onKeyPress={e =>
          e.key === 'Enter'
            ? window.open(
                url,
                'XfinityAssistant',
                `toolbar=yes,scrollbars=yes,resizable=yes,top=${window.screen
                  .height - 650},left=${window.screen.width -
                  375},width=${375},height=${650}`,
              )
            : null
        }
      >
        {text}
      </span>
    );
  };
  const createAnchor = (url, text) => (
    <a className="fw600" target="_blank" rel="noreferrer noopener" href={url}>
      {text}
    </a>
  );

  // This is going to go through all of splitCMSText and check to see if the element is equal to one of the
  // "text"s of the regexedArrayOfLinks array of objects. If it matches, we are going to replace the string in that
  // place with an anchor tag with the text that matches and an href with the url of the object
  splitCMSText.forEach(elem => {
    // keep track of the correct link obj
    let indexOfLink;
    // check if the current string is equal any text in the md links
    regexedArrayOfLinks.forEach((link, idx) => {
      if (link.text === elem) {
        indexOfLink = idx;
      }
    });
    // if a match was found...
    if (indexOfLink !== undefined) {
      // create anchor tag and push to new array
      const newLink =
        // The Xfinity Assistant button link will open a new window
        regexedArrayOfLinks[indexOfLink].text === 'Xfinity Assistant'
          ? createXfinityAssistantButton(
              regexedArrayOfLinks[indexOfLink].url,
              regexedArrayOfLinks[indexOfLink].text,
            )
          : createAnchor(
              regexedArrayOfLinks[indexOfLink].url,
              regexedArrayOfLinks[indexOfLink].text,
            );

      replacedCMSArray.push(newLink);
      return;
    }
    // if no match is found, push same element
    replacedCMSArray.push(elem);
  });
  // at the end we should have something like ["We'll be in touch ", <AnchorTag />, " by email... please go to My Account in the app or ", <AnchorTag />]
  return replacedCMSArray;
};
