import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Formik, Form, Field } from 'formik';
import { PageSection, Loading, FormControl } from 'adc-ui-components';
import NavigationButtons from '../../../components/NavigationButtons';
import { OUTAGE_REVIEW, INELIGIBLE_CREDITS } from '../../../helpers/routes';
import {
  getOutageHistory,
  submitOutageDetails,
  getOutageCreditEligibility,
} from './OutageCreditActions';
import IconCalendar from '../../../components/svgs/Calendar';
import DatePicker from '../../../components/form-elements/DatePicker';
import { RequiredFactory } from '../../../helpers/validation/required';
import {
  vDateSelectionRange,
  vDateAfterDays,
  vValidDate,
  vEndDateGreaterThanStart,
} from '../../../helpers/validation/dateValidation';
import { dayDifferenceFromToday, formatDate } from '../../../helpers/date';
import { detailsOutageMock } from '../../../__mocks__/mockData/OutageDetails';
import { dispatchSetModal } from '../../../shared/actions/userMessagesActions';
import { UNHANDLED_ERROR } from '../../../components/modals/ModalHandler';
import PageTitle from '../../../components/layout/PageTitle';

const vRequired = RequiredFactory();
const TEXT_DATE_FORMAT = 'MM/dd/yyyy';
const DATE_DATE_FORMAT = 'yyyy-MM-dd';

const OutageDetails = () => {
  const [selectedStartDay, setSelectedStartDay] = useState(null);
  const [selectedEndDay, setSelectedEndDay] = useState(null);
  const [outageHistoryStartDate, setOutageHistoryStartDate] = useState();
  const [outageHistoryEndDate, setOutageHistoryEndDate] = useState();

  const startRange = 90;
  const endRange = 0;
  const dayLimit = 7;

  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    try {
      const outageAutopopulateFlag = JSON.parse(
        localStorage.getItem('outageAutopopulateFlag'),
      );
      const outageHistory = async () => {
        const response = await dispatch(getOutageHistory());
        const events = response?.body?.events;
        if (events && events.length > 0) {
          const startDate = events[0].actualStart?.date;
          const endDate = events[0].actualEnd?.date;
          setSelectedStartDay(startDate);
          setSelectedEndDay(endDate);
          setOutageHistoryStartDate(formatDate(startDate, TEXT_DATE_FORMAT));
          setOutageHistoryEndDate(formatDate(endDate, TEXT_DATE_FORMAT));
        }
      };
      !!outageAutopopulateFlag && outageHistory();
    } catch (error) {
      throw error;
    }
  }, [dispatch]);

  // Check destination URL for query string that indicates user navigated from the billing flow
  const { search } = useLocation();
  const isBillingFlow = new URLSearchParams(search).get('src') === 'billing';

  const {
    credits: {
      loading,
      creditInfoPage: { datesQuestion },
    },
  } = useSelector(state => state.cms?.credits);

  const {
    reportIssue: {
      outageInfo: { description = '' },
    },
  } = detailsOutageMock.cms;

  if (!detailsOutageMock.cms || loading) {
    return <Loading />;
  }

  const onFormSubmit = async values => {
    const finalOutageData = {
      startDate: formatDate(values.startDate, DATE_DATE_FORMAT),
      endDate: formatDate(values.endDate, DATE_DATE_FORMAT),
    };

    const finalOutageDataForAPI = {
      startDate: formatDate(values.startDate, DATE_DATE_FORMAT),
      endDate: formatDate(values.endDate, DATE_DATE_FORMAT),
    };

    try {
      const eligible = await dispatch(
        getOutageCreditEligibility(finalOutageDataForAPI),
      );
      if (!eligible) {
        return history.push(INELIGIBLE_CREDITS + search);
      }

      dispatch(submitOutageDetails(finalOutageData));

      return history.push(OUTAGE_REVIEW + search);
    } catch (error) {
      return dispatch(
        dispatchSetModal({ showModal: true, type: UNHANDLED_ERROR }),
      );
    }
  };

  const validateForm = values => {
    const { startDate, endDate } = values;
    const errors = {};

    const validationStartDateText =
      vRequired('Please select a start date')(startDate) ||
      vValidDate(startDate) ||
      vDateSelectionRange(startDate, endRange, startRange);

    const validationEndDateText =
      vRequired('Please select an end date')(endDate) ||
      vValidDate(endDate) ||
      vDateSelectionRange(endDate, endRange, startRange) ||
      vDateAfterDays(startDate, endDate, dayLimit, 'start date') ||
      vEndDateGreaterThanStart(startDate, endDate);

    if (validationStartDateText) {
      errors.startDate = validationStartDateText;
    } else {
      setSelectedStartDay(formatDate(startDate, DATE_DATE_FORMAT));
    }

    if (validationEndDateText) {
      errors.endDate = validationEndDateText;
    } else {
      setSelectedEndDay(formatDate(endDate, DATE_DATE_FORMAT));
    }

    return errors;
  };

  const initialFormValues = {
    startDate: selectedStartDay
      ? formatDate(selectedStartDay, TEXT_DATE_FORMAT)
      : '',
    endDate: selectedEndDay ? formatDate(selectedEndDay, TEXT_DATE_FORMAT) : '',
    serviceType: '',
  };

  return (
    <PageSection className="page-section--banner">
      <PageTitle title={datesQuestion} subtitle={description} />
      <Formik
        enableReinitialize
        validate={validateForm}
        validateOnChange
        validateOnBlur={false}
        onSubmit={onFormSubmit}
        initialValues={initialFormValues}
      >
        {({ errors, setFieldValue, values }) => (
          <Form noValidate>
            <div className="card mb24 card--flex-start">
              <div className="card__content card--date-picker">
                <FormControl
                  className="date-picker"
                  inputId="startDate"
                  label="When did this issue start?"
                >
                  <Field
                    id="startDate"
                    name="startDate"
                    className={errors.startDate ? 'error' : ''}
                    component={DatePicker}
                    setFieldValue={setFieldValue}
                    selectedDay={selectedStartDay}
                    setSelectedDay={setSelectedStartDay}
                    after={0}
                    prior={startRange}
                    aria-invalid={!!errors.startDate}
                    aria-describedby="error_startDate"
                    outageHistoryDate={outageHistoryStartDate}
                  />
                  <IconCalendar
                    className="date-picker__icon svg-icon--32 svg-icon--non-reactive"
                    aria-hidden
                  />
                </FormControl>
                <FormControl
                  className="date-picker"
                  inputId="endDate"
                  label="When did this issue end?"
                >
                  <Field
                    id="endDate"
                    name="endDate"
                    className={errors.endDate ? 'error' : ''}
                    component={DatePicker}
                    setFieldValue={setFieldValue}
                    selectedDay={selectedEndDay}
                    setSelectedDay={setSelectedEndDay}
                    after={endRange}
                    prior={
                      !values.startDate
                        ? startRange
                        : dayDifferenceFromToday(selectedStartDay)
                    }
                    aria-invalid={!!errors.endDate}
                    aria-describedby="error_endDate"
                    outageHistoryDate={outageHistoryEndDate}
                  />
                  <IconCalendar
                    className="date-picker__icon svg-icon--32 svg-icon--non-reactive"
                    aria-hidden
                  />
                </FormControl>
              </div>
            </div>

            <NavigationButtons
              navLinkForBack={
                isBillingFlow
                  ? 'https://customer.xfinity.com/billing/services'
                  : 'https://www.xfinity.com/support/status'
              }
              navExternalLink
              labelForNext="Continue"
              labelForPrevious="Back"
            />
          </Form>
        )}
      </Formik>
    </PageSection>
  );
};

export default OutageDetails;
