import React from 'react';
import ReactModal from 'react-modal';
import { LoadingCard } from 'adc-ui-components';

const Modal = ({
  isOpen,
  onRequestClose,
  contentLabel,
  children,
  fullPage,
  loading,
  dismissable,
}) => {
  const modalClass = {
    base: 'modal__content',
    afterOpen: '',
    beforeClose: '',
  };
  const overlayClass = {
    base: `modal ${fullPage ? 'modal--full-page' : ''}`,
    afterOpen: 'modal--is-open',
    beforeClose: '',
  };

  const closeButton = (
    <button
      type="button"
      className="modal__close button button--text"
      onClick={onRequestClose}
    >
      <span className="visuallyhidden">Close Dialog</span>
    </button>
  );

  return (
    <ReactModal
      ariaHideApp={false}
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel={contentLabel}
      className={modalClass}
      overlayClassName={overlayClass}
      shouldCloseOnOverlayClick={dismissable}
      shouldCloseOnEsc={dismissable}
    >
      {loading && <LoadingCard />}

      {!loading && !fullPage && (
        <div className="card">
          {dismissable && closeButton}

          <div className="card__content">{children}</div>
        </div>
      )}

      {!loading && fullPage && (
        <>
          {dismissable && closeButton}
          {children}
        </>
      )}
    </ReactModal>
  );
};

export default Modal;
