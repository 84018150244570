import React from 'react';
import ReportIssueApp from './ReportIssueApp';
import { REPORT_ISSUE } from '../../helpers/routes';

import CimaAuth from '../CimaAuth';

const ReportIssue = () => {
  const redirectUrl = `${REPORT_ISSUE}?issueType=HAZARDOUS-WIRES`;
  return (
    <CimaAuth redirectTo={redirectUrl}>
      <ReportIssueApp />
    </CimaAuth>
  );
};

export default ReportIssue;
