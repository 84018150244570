import React from 'react';
import { PageSection } from 'adc-ui-components';
import { Link } from 'react-router-dom';
import { BEREAVEMENT } from '../../helpers/routes';
import { BaseDataLayer, PageTracking } from '../../helpers/dataLayer';
import IconCircleExclamation from '../../components/svgs/IconCircleExclamation';

const SessionError = () => {
  return (
    <>
      <BaseDataLayer />
      <PageTracking />
      <PageSection className="ui-grey page-section--banner">
        <IconCircleExclamation className="mb6 svg-icon--42" />

        <div className="hgroup">
          <h1 className="display3" tabIndex="-1">
            Error
          </h1>
          <p className="body2">
            A problem occurred while processing your information.
          </p>
        </div>

        <Link className="button button--primary" to={`${BEREAVEMENT}`}>
          Start Over
        </Link>
      </PageSection>
    </>
  );
};

export default SessionError;
