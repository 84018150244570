import React from 'react';
import KeyValuePair from '../KeyValuePair';
import { formatMonthDateYear } from '../../helpers/date';

const RefundHistoryCard = props => {
  const { refundHistory, authorization, isLite } = props;
  return (
    <>
      <h2 className="mb12 text-align-left">Request History</h2>
      {refundHistory.map(refund => (
        <div className="card-group mb12" key={refund.request.date}>
          <div className="card-group__item">
            <div className="card card--action-right">
              <div className="card__content">
                <KeyValuePair
                  className="key-value--inline-at-768"
                  label="Date"
                  value={formatMonthDateYear(refund.request?.date)}
                  valueClass="mb0 key-value__value--right-align"
                />
              </div>
            </div>
          </div>
          {authorization && (
            <div className="card-group__item">
              <div className="card card--action-right">
                <div className="card__content">
                  <KeyValuePair
                    className="key-value--inline-at-768"
                    key={isLite ? 'Service Address' : 'Billing Address'}
                    label={isLite ? 'Service Address' : 'Billing Address'}
                    value={refund.request?.mailingAddress}
                    valueClass="mb0 key-value__value--right-align"
                  />
                </div>
              </div>
            </div>
          )}
          {authorization && (
            <div className="card-group__item">
              <div className="card card--action-right">
                <div className="card__content">
                  <KeyValuePair
                    className="key-value--inline-at-768"
                    label="Confirmation email"
                    value={refund.request?.emailAddress}
                    valueClass="mb0 key-value__value--right-align"
                  />
                </div>
              </div>
            </div>
          )}
          <div className="card-group__item">
            <div className="card card--action-right">
              <div className="card__content">
                <KeyValuePair
                  className="key-value--inline-at-768"
                  label="Adjustment amount"
                  value={refund.amount}
                  valueClass="mb0 fw500 key-value__value--right-align"
                />
              </div>
            </div>
          </div>
        </div>
      ))}
    </>
  );
};

export default RefundHistoryCard;
