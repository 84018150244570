import React from 'react';

export default ({ className = '', ...props }) => (
  <span className={`${className}`} {...props}>
    <svg
      width="8"
      height="8"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
      aria-hidden="true"
    >
      <title>Delete this item</title>
      <path
        d="M6.67.293L7.377 1 4.542 3.835 7.377 6.67l-.707.707-2.835-2.835L1 7.377.293 6.67l2.835-2.835L.293 1 1 .293l2.835 2.835L6.67.293z"
        fill="#646A70"
        fillRule="nonzero"
      />
    </svg>
  </span>
);
