export default {
  env: 'stg',
  kibanaApi: 'https://api-support-preprod.xfinity.com/logger/',
  apis: {
    ssm: 'https://api-preprod.sc.xfinity.com',
    cmsData: 'https://sdx.xfinity.com/cms/data/helpandsupport',
    channelTracking: 'https://secure-niagaralog-prod.codebig2.net/',
  },
  oauthOptions: {
    idmClientId: 'dss-refund-preprod',
    clientId: 'mtm-account-change-preprod',
  },
};
