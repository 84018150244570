export const BASENAME = '/support/account-management';

// Errors
export const ERROR = '/error';

// Bereavement Routes
export const BEREAVEMENT = '/bereavement';
export const BEREAVEMENT_UPLOAD_DOCUMENTS = `${BEREAVEMENT}/document-upload`;
export const BEREAVEMENT_ACCOUNT_HOLDER_INFO = `${BEREAVEMENT}/decedent-information`;
export const BEREAVEMENT_REPRESENTATIVE_INFO = `${BEREAVEMENT}/user-information`;
export const BEREAVEMENT_DISCONNECT = `${BEREAVEMENT}/disconnect-option`;
export const BEREAVEMENT_STATEMENT = `${BEREAVEMENT}/final-bill`;
export const BEREAVEMENT_REVIEW = `${BEREAVEMENT}/transaction-summary`;
export const BEREAVEMENT_CONFIRMATION = `${BEREAVEMENT}/confirmation`;

// DTC (Report Issue) Flow
export const REPORT_ISSUE = '/issue-reporting';
export const ISSUE_DETAILS = `${REPORT_ISSUE}/details`;
export const ISSUE_REVIEW = `${REPORT_ISSUE}/review`;
export const ISSUE_CONFIRMATION = `${REPORT_ISSUE}/confirmation`;

// Credits Flow
export const CREDITS = `/credits`;
export const INELIGIBLE_CREDITS = `${CREDITS}/ineligible`;
export const OUTAGE_CREDITS = `${CREDITS}/outage`;
export const VIDEO_CREDITS = `${CREDITS}/video`;
export const LATE_FEE_CREDITS = `${CREDITS}/late-fee`;

// Outage credits routes
export const OUTAGE_DETAILS = `${OUTAGE_CREDITS}/details`;
export const OUTAGE_REVIEW = `${OUTAGE_CREDITS}/review`;
export const OUTAGE_CONFIRMATION = `${OUTAGE_CREDITS}/confirmation`;

// Video credits routes
export const VIDEO_DETAILS = `${VIDEO_CREDITS}/details`;
export const VIDEO_REVIEW = `${VIDEO_CREDITS}/review`;
export const VIDEO_CONFIRMATION = `${VIDEO_CREDITS}/confirmation`;

// Late Fee Credits Routes
export const LATE_FEE_CONFIRMATION = `${LATE_FEE_CREDITS}/confirmation`;

// Sports Refund Routes
export const SPORTS_REFUND = `/courtesy-adjustment`;
export const SPORTS_REFUND_REVIEW = `${SPORTS_REFUND}/review`;
export const SPORTS_REFUND_CONFIRMATION = `${SPORTS_REFUND}/confirmation`;
export const SPORTS_REFUND_HISTORY = `${SPORTS_REFUND}/request-history`;
export const SPORTS_REFUND_EMAIL_EDIT = `${SPORTS_REFUND}/email-change`;
export const SPORTS_REFUND_MAIL_EDIT = `${SPORTS_REFUND}/address-change`;
export const SPORTS_REFUND_INELIGIBLE = `${SPORTS_REFUND}/ineligible`;
export const SPORTS_REFUND_ERROR = `${SPORTS_REFUND}/error`;
export const SPORTS_REFUND_PERMS_ERROR = `${SPORTS_REFUND}/permission-error`;

export const withBasename = (path = '') => {
  // If the path starts with a slash, then join with an empty string.
  // Otherwise, join with a slash.
  const joiner = path.match(/^\//) ? '' : '/';
  if (path) {
    return [BASENAME, path].join(joiner);
  }
  return BASENAME;
};
