import React from 'react';
import { Link } from 'react-router-dom';
import KeyValuePair from '../KeyValuePair';
import CirclePlus from '../svgs/CirclePlus';
import {
  SPORTS_REFUND_EMAIL_EDIT,
  SPORTS_REFUND_MAIL_EDIT,
} from '../../helpers/routes';

const RefundDetailsCard = props => {
  const { cardDetails, canEditIdentity, className = 'mb12' } = props;
  const {
    isLite,
    serviceAddress,
    mailingAddress,
    emailAddress,
    amount,
  } = cardDetails;

  return (
    <div className={`card-group text-align-left ${className}`}>
      <div className="card-group__item">
        <div className="card card--action-right align-items-start">
          <div className="card__content">
            <KeyValuePair
              className="key-value--inline-at-768"
              valueClass={
                isLite || !canEditIdentity
                  ? 'key-value__value--right-align'
                  : ''
              }
              key={isLite ? 'Service Address' : 'Billing Address'}
              label={isLite ? 'Service Address' : 'Billing Address'}
              value={isLite ? serviceAddress : mailingAddress}
            />
          </div>
          {!isLite && canEditIdentity && (
            <div className="card__action">
              <Link to={SPORTS_REFUND_MAIL_EDIT}>Edit</Link>
            </div>
          )}
        </div>
      </div>
      {emailAddress && (
        <div className="card-group__item">
          <div className="card card--action-right align-items-start">
            <div className="card__content">
              <KeyValuePair
                className="key-value--inline-at-768"
                valueClass={
                  isLite || !canEditIdentity
                    ? 'key-value__value--right-align'
                    : ''
                }
                label="Confirmation email"
                value={emailAddress}
              />
            </div>
            {!isLite && canEditIdentity && (
              <div className="card__action">
                <Link to={SPORTS_REFUND_EMAIL_EDIT}>Edit</Link>
              </div>
            )}
          </div>
        </div>
      )}
      {!emailAddress && !isLite && canEditIdentity && (
        <div className="card-group__item">
          <Link className="card" to={SPORTS_REFUND_EMAIL_EDIT}>
            <div className="card__content">
              <span className="link d-flex align-items-center">
                <CirclePlus className="mr6 svg-icon--40" />
                Add a Confirmation Email Address
              </span>
            </div>
          </Link>
        </div>
      )}
      <div className="card-group__item">
        <div className="card card--action-right-at-768">
          <div className="card__content">
            <KeyValuePair
              className="key-value--inline-at-768"
              label="Adjustment amount"
              value={amount}
              valueClass="mb0 fw500 key-value__value--right-align"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default RefundDetailsCard;
