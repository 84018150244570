import memoized from '../memoized';

const VALID_PHONE = /^(\+?1-?)?(\([2-9]([02-9]\d|1[02-9])\)|[2-9]([02-9]\d|1[02-9]))-?[2-9]([02-9]\d|1[02-9])-?\d{4}$/;
const MATCH_NON_DIGITS = /\D/g;
const MATCH_SPACES = /\s+/g;

export const formatPhone = value => {
  // only allow users to enter numbers
  if (typeof value !== 'string') {
    return '';
  }

  const out = [];
  const stripped = value.replace(/[^\d]+/g, '');
  // split number string out into 3,3,4 parts
  const parts = [
    stripped.substr(0, 3),
    stripped.substr(3, 3),
    stripped.substr(6, 4),
  ].filter(a => !!a);

  if (parts[0]) {
    out.push(parts[0]);
  }

  if (parts[1]) {
    out.push(`-${parts[1]}`);
  }

  if (parts[2]) {
    out.push(`-${parts[2]}`);
  }

  return out.join('');
};

export const PhoneFactory = memoized(message => input => {
  if (!input) {
    return undefined;
  }

  const phone = input.replace(MATCH_SPACES, '');
  const valid =
    VALID_PHONE.test(phone) && phone.replace(MATCH_NON_DIGITS, '').length >= 10;

  return valid ? undefined : message || true;
});

export default PhoneFactory;
