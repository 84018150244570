export const SET_MODAL = 'SET_MODAL';
export const RESET_USER_MESSAGES = 'RESET_USER_MESSAGES';

export const dispatchSetModal = ({ showModal = false, type }) => {
  return {
    type: SET_MODAL,
    payload: {
      type,
      showModal,
    },
  };
};

export const dispatchResetUserMessages = () => {
  return {
    type: RESET_USER_MESSAGES,
  };
};
