import React from 'react';
import { PageSection } from 'adc-ui-components';
import { useSelector } from 'react-redux';
import PageTitle from '../../../components/layout/PageTitle';
import ConfirmationIcon from '../../../components/svgs/SportsRefundConfirmationIcon';
import RefundDetailsCard from '../../../components/refund/refundDetailsCard';
import {
  fallbackCancelUrl,
  getRefundDetails,
} from '../../../helpers/SportsRefundHelpers';

const Confirmation = () => {
  const { cancelUrl, states: sportsRefundData } = useSelector(
    state => state.sportsRefund,
  );
  const refund = getRefundDetails(sportsRefundData);
  const cancelDest = cancelUrl || fallbackCancelUrl;

  return (
    <PageSection className="page-section--banner">
      <ConfirmationIcon className="svg-icon--42 mb12" />
      <PageTitle title="Thank you!" noFocus />
      <div className="card card--action-right mb12">
        <div className="card__content">
          <p className="mb0">
            Your courtesy adjustment is processing and will either be sent to
            your most recently-used credit/debit card on file or mailed to the
            billing address on file.
          </p>
        </div>
      </div>
      {refund && <RefundDetailsCard className="mb24" cardDetails={refund} />}
      <div className="text-center">
        <div className="action__item">
          <a className="button button--primary" href={cancelDest}>
            Done
          </a>
        </div>
      </div>
    </PageSection>
  );
};
export default Confirmation;
