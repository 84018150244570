import React from 'react';
import { FormControl } from 'adc-ui-components';
import { Field } from 'formik';
import InputField from '../../form-elements/InputField';
import { RequiredFactory } from '../../../helpers/validation/required';
import { EmailFactory } from '../../../helpers/validation/email';

const vRequired = RequiredFactory();
const vEmail = EmailFactory();
const vMessage = 'Please enter a valid email address';

export const defaultEmailValidation = vEmail(vMessage);
export const defaultRequiredValidation = vRequired(vMessage);

export const defaultValidate = value =>
  defaultRequiredValidation(value) || defaultEmailValidation(value);

const ConfirmEmailField = ({
  validate = defaultValidate,
  label,
  className,
  ...props
}) => (
  <FormControl
    inputId="confirmEmailAddress"
    className={className}
    label={label}
  >
    <Field
      id="confirmEmailAddress"
      type="email"
      name="confirmEmailAddress"
      component={InputField}
      validate={validate}
      {...props}
    />
  </FormControl>
);

export default ConfirmEmailField;
