/* eslint-disable react/jsx-curly-brace-presence */
import React from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { PageSection } from 'adc-ui-components';
import PageTitle from '../../../components/layout/PageTitle';
import { dispatchSetModal } from '../../../shared/actions/userMessagesActions';
import { RETRY_ERROR } from '../../../components/modals/ModalHandler';
import RefundDetailsCard from '../../../components/refund/refundDetailsCard';
import NavigationButtons from '../../../components/NavigationButtons';
import { getRefundDetails } from '../../../helpers/SportsRefundHelpers';
import {
  SPORTS_REFUND,
  SPORTS_REFUND_CONFIRMATION,
} from '../../../helpers/routes';
import { issueSportsRefund } from '../Actions/IssueSportsRefund';

const Review = () => {
  const refundInfo = useSelector(state => state.sportsRefund);
  const { submitLoading, states: sportsRefundData } = refundInfo;
  const refund = getRefundDetails(sportsRefundData);
  const history = useHistory();
  const dispatch = useDispatch();
  const handleSubmit = async event => {
    event.preventDefault();
    try {
      const { id } = refund;
      const {
        updatedMailingAddress = {},
        updatedEmail = '',
      } = sportsRefundData;
      const requestData = {
        id,
      };

      if (!id) {
        throw Error;
      }

      if (Object.keys(updatedMailingAddress).length !== 0) {
        requestData.mailingAddress = updatedMailingAddress;
      }

      if (updatedEmail) {
        requestData.emailAddress = updatedEmail;
      }

      await dispatch(issueSportsRefund(requestData));
      return history.push(SPORTS_REFUND_CONFIRMATION);
    } catch (error) {
      // Error modal scenario handled and showing error popup.
      return dispatch(dispatchSetModal({ showModal: true, type: RETRY_ERROR }));
    }
  };
  return (
    <PageSection className="page-section--banner">
      <PageTitle
        title="Review your Regional Sports Network courtesy adjustment"
        noFocus
      />
      <div className="card card--action-right mb12">
        <div className="card__content">
          <p className="mb12 body2">
            Due to the impact of COVID-19 on this year&apos;s sporting events
            and broadcasts, you are eligible to receive a Regional Sports
            Network Courtesy Adjustment. The Adjustment will be issued within
            4-6 weeks from the request date.
          </p>
          <ul>
            <li className="body3">
              If you made a payment within the past 45 days with a debit/credit
              card on file, the adjustment will be sent to that card.
            </li>
            <li className="body3">
              If you did not make a debit/credit card payment within the past 45
              days, a check will be mailed to the billing address on file.
            </li>
          </ul>
        </div>
      </div>
      {refund && <RefundDetailsCard cardDetails={refund} />}
      <div className="card card--warning card--action-right mb24">
        <div className="card__content">
          <p>
            Please note: Your eligibility to request this courtesy adjustment
            will expire on September 20th, 2021. Whether the adjustment is sent
            to your credit card or mailed by check you will need to click on
            &ldquo;Request Adjustment&rdquo; below.
          </p>
        </div>
      </div>
      <NavigationButtons
        labelForNext="Request Adjustment"
        labelForPrevious="Back"
        navLinkForBack={SPORTS_REFUND}
        handleSubmit={handleSubmit}
        navExternalLink={false}
        submitting={submitLoading}
      />
    </PageSection>
  );
};

export default Review;
