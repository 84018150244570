import React from 'react';
import IconCircleExclamation from '../svgs/IconCircleExclamation';
import Modal from './Modal';

const RetryError = () => {
  const refresh = () => window.location.reload();
  return (
    <Modal isOpen>
      <div className="text-center">
        <IconCircleExclamation className="mb12 svg-icon--42" />
        <div className="hgroup mb24">
          <h2>Looks like something went wrong</h2>
        </div>
        <button
          className="button button--primary"
          type="button"
          onClick={refresh}
        >
          Try Again
        </button>
      </div>
    </Modal>
  );
};

export default RetryError;
