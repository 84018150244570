import React from 'react';
import { FormControl } from 'adc-ui-components';
import { Field } from 'formik';
import InputField from '../../form-elements/InputField';
import { RequiredFactory } from '../../../helpers/validation/required';
import { MinFactory, MaxFactory } from '../../../helpers/validation/minMax';
import { isDigit } from '../../../helpers/validation/isDigit';

const vRequired = RequiredFactory();
const vMinLength = MinFactory();
const vMaxLength = MaxFactory();
const vIsDigit = isDigit();

const ZipCode = ({ label, className, ...props }) => (
  <FormControl inputId="zipCode" label={label} className={className}>
    <Field
      id="zipCode"
      type="text"
      name="zipCode"
      aria-autocomplete="none"
      component={InputField}
      validate={value =>
        vRequired('Please enter a valid ZIP Code')(value) ||
        vIsDigit('Please enter a valid ZIP Code')(value) ||
        vMinLength(5, `Please enter a valid ZIP Code of 5 digits`)(value) ||
        vMaxLength(5, `Please enter a valid ZIP Code of 5 digits`)(value)
      }
      {...props}
    />
  </FormControl>
);

export default ZipCode;
