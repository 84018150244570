import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Loading } from 'adc-ui-components';

import { withBasename } from '../helpers/routes';
import { getOauth } from '../helpers/auth';
import { dispatchSetModal } from '../shared/actions/userMessagesActions';
import { UNHANDLED_ERROR } from '../components/modals/ModalHandler';
import { BaseDataLayer, PageTracking } from '../helpers/dataLayer';

export default ({ children, redirectTo = '' }) => {
  const [loading, setLoading] = useState(true);
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  useEffect(() => {
    const getAuth = async () => {
      try {
        const login = await getOauth(withBasename(redirectTo));

        // Remove the loading dots unless getOauth is about to redirect.
        setLoading(!!login.forceRedirect);
      } catch (err) {
        dispatch(dispatchSetModal({ showModal: true, type: UNHANDLED_ERROR }));
      }
    };

    getAuth();
  }, [pathname, redirectTo, dispatch]);

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      <BaseDataLayer />
      <PageTracking />
      {children}
    </>
  );
};
