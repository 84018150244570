import React, { useEffect } from 'react';
import { Route, Switch, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
  INELIGIBLE_CREDITS,
  OUTAGE_CONFIRMATION,
  OUTAGE_DETAILS,
  OUTAGE_REVIEW,
} from '../../../helpers/routes';
import OutageConfirmation from './OutageConfirmation';
import OutageDetails from './OutageDetails';
import OutageReview from './outageReview';

import { trackFiveStandards } from '../../../helpers/logger/fiveStandards';

const OutageCreditsFlow = () => {
  const history = useHistory();
  const { showOutageOption } = useSelector(
    state => state.credits?.eligibility || {},
  );

  const { accountNumber, customerGuid: custGuid } = useSelector(
    state => state.user.data,
  );

  useEffect(() => {
    if (!showOutageOption) {
      history.push(INELIGIBLE_CREDITS);
    } else {
      trackFiveStandards({
        id: 'OUTAGE_CREDIT_REQUEST',
        accountNumber,
        custGuid,
        interactionType: 'BILLING_PAYMENTS',
      });
    }
  }, [accountNumber, custGuid, history, showOutageOption]);

  return (
    <Switch>
      <Route exact path={OUTAGE_DETAILS} component={OutageDetails} />
      <Route exact path={OUTAGE_REVIEW} component={OutageReview} />
      <Route exact path={OUTAGE_CONFIRMATION} component={OutageConfirmation} />
    </Switch>
  );
};
export default OutageCreditsFlow;
