import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { PageSection } from 'adc-ui-components';
import { useSelector } from 'react-redux';
import ConfirmationIcon from '../../../components/svgs/IssueReportingConfirmationIcon';
import KeyValueGroup from '../../../components/KeyValueGroup';
import KeyValue from '../../../components/KeyValue';
import { replaceCMSText } from '../../../helpers/cms';

import { formatDate } from '../../../helpers/date';
import PageTitle from '../../../components/layout/PageTitle';
import { trackFiveStandards } from '../../../helpers/logger/fiveStandards';

const OutageConfirmation = () => {
  const {
    outage: {
      outageStartDate,
      outageEndDate,
      outageEligibleLOBs,
      creditAmount,
    },
  } = useSelector(state => state.credits) || {};

  // Check destination URL for query string that indicates user navigated from the billing flow
  const { search } = useLocation();
  const isBillingFlow = new URLSearchParams(search).get('src') === 'billing';

  const {
    credits: {
      creditAppliedPage: { title, text },
    },
  } = useSelector(state => state.cms?.credits);
  const { customerGuid: custGuid, accountNumber } = useSelector(
    state => state.user.data,
  );

  const confirmationData = [
    {
      label: 'Issue Start Date',
      value: formatDate(outageStartDate, 'EEEE, MMMM d'),
    },
    {
      label: 'Issue End Date',
      value: formatDate(outageEndDate, 'EEEE, MMMM d'),
    },
    { label: 'Services Impacted', value: outageEligibleLOBs.join(', ') },
  ];

  useEffect(() => {
    trackFiveStandards({
      id: 'OUTAGE_CREDIT_CONFIRMED',
      accountNumber,
      custGuid,
      interactionType: 'BILLING_PAYMENTS',
    });
  }, [accountNumber, custGuid]);

  return (
    <PageSection className="page-section--banner">
      <div className="mb24">
        <ConfirmationIcon />
      </div>
      <PageTitle
        title={title}
        subtitle="You'll receive an email and/or text confirmation within the next few minutes. The credit will be reflected in your account, on the billing page, within 10 minutes. You may need to refresh the page to see the applied credit."
      />
      <div className="card mb24 card--flex-start card--action-right">
        <div className="card__content">
          <p className="body2">
            {replaceCMSText(
              text,
              '$creditAmount',
              `$${Math.abs(creditAmount).toFixed(2)}`,
            )}
          </p>
          <hr />
          <KeyValueGroup itemClass="mb0">
            {confirmationData.map(item => (
              <KeyValue
                className="key-value--inline"
                key={item.label}
                {...item}
              />
            ))}
          </KeyValueGroup>
        </div>
      </div>
      <div className="text-center">
        <div className="action__item">
          <a
            className="button button--primary"
            href={
              isBillingFlow
                ? 'https://customer.xfinity.com/billing/services'
                : 'https://xfinity.com/billing'
            }
            rel="noopener noreferrer"
          >
            View Credit
          </a>
        </div>
      </div>
    </PageSection>
  );
};

export default OutageConfirmation;
