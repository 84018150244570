import React from 'react';
import { FormControl } from 'adc-ui-components';
import { Field } from 'formik';
import { RequiredFactory } from '../../../helpers/validation/required';
import AddressTypeAhead from '../../form-elements/AddressTypeAhead';

const vRequired = RequiredFactory();

const StreetAddress = ({ label, className, ...props }) => (
  <FormControl inputId="streetAddress" label={label} className={className}>
    <Field
      id="streetAddress"
      type="text"
      name="streetAddress"
      component={AddressTypeAhead}
      tokenized
      placeholder="Street Address, P/O Box, company name, c/o"
      validate={value =>
        vRequired('Please enter a valid street address')(value)
      }
      {...props}
    />
  </FormControl>
);

export default StreetAddress;
