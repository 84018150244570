import Cookies from 'js-cookie';
import { decodeToken, validateSSMToken } from './jwt';

import getConfig from '../config';
import { dispatchSetModal } from '../shared/actions/userMessagesActions';
import { TIMEOUT_ERROR } from '../components/modals/ModalHandler';

const { sessionCookieName } = getConfig();

export const storeSession = (session, dispatch) => {
  const { access_token: token } = session;
  const { exp } = decodeToken(token);
  Cookies.set(sessionCookieName, token, {
    expires: new Date(exp * 1000),
  });
  setSessionTimer(exp, dispatch);
};

export const getStoredSession = () => {
  const token = Cookies.get(sessionCookieName);
  if (validateSSMToken(token)) {
    return token;
  }
  return undefined;
};

export const deleteStoredSession = () => {
  const token = getStoredSession();
  Cookies.remove(sessionCookieName);
  return token;
};

export const setSessionTimer = (exp, dispatch) => {
  if (window.sessionTimer) {
    clearTimeout(window.sessionTimer);
  }

  const token = getStoredSession();
  if (exp && token) {
    window.sessionTimer = setTimeout(() => {
      console.log('Session Timed Out'); // eslint-disable-line no-console
      deleteStoredSession();
      dispatch(dispatchSetModal({ showModal: true, type: TIMEOUT_ERROR }));
    }, exp * 1000 - new Date().getTime()); // Sets session timout for number of ms between current time and when the cookie expires
  }
};
