import React from 'react';

import { joinClass } from '../helpers/component';

const KeyValue = props => {
  const { label, value, className, valueClass } = props;
  return (
    <div className={joinClass('key-value', className)}>
      {label && (
        <div className="key-value__key">
          <h4 className="body2">{label}</h4>
        </div>
      )}
      <div className={joinClass('key-value__value', valueClass)}>
        <span className="key-value__value-item">{value}</span>
      </div>
    </div>
  );
};

export default KeyValue;
