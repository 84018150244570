import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Loading } from 'adc-ui-components';
import { Switch, Route, useHistory } from 'react-router-dom';

import BereavementEntry from './views/BereavementEntry';
import AccountHolderInfo from './views/AccountHolderInfo';
import Confirmation from './views/Confirmation';
import Disconnection from './views/Disconnection';
import DocumentUpload from './views/DocumentUpload';
import FinalStatement from './views/FinalStatement';
import Review from './views/FinalReview';
import {
  getSession,
  destroySession,
} from '../../shared/actions/sessionActions';
import RepresentativeInfo from './views/RepresentativeInfo';

import {
  BEREAVEMENT_UPLOAD_DOCUMENTS,
  BEREAVEMENT_ACCOUNT_HOLDER_INFO,
  BEREAVEMENT_REPRESENTATIVE_INFO,
  BEREAVEMENT_DISCONNECT,
  BEREAVEMENT_STATEMENT,
  BEREAVEMENT_REVIEW,
  BEREAVEMENT_CONFIRMATION,
  ERROR,
} from '../../helpers/routes';
import { BaseDataLayer, PageTracking } from '../../helpers/dataLayer';

const Bereavement = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [editInfo, setEditInfo] = useState(false);
  useEffect(() => {
    const fetchSession = async () => {
      try {
        await dispatch(getSession());
      } catch (error) {
        history.push(ERROR);
      }
    };
    fetchSession();
    return () => {
      dispatch(destroySession());
    };
  }, [dispatch, history]);

  const loading = useSelector(state => state.loading);
  if (loading) {
    return <Loading />;
  }

  return (
    <>
      <BaseDataLayer />
      <PageTracking />
      <Switch>
        <Route
          exact
          path={BEREAVEMENT_UPLOAD_DOCUMENTS}
          render={matchProps => (
            <DocumentUpload
              {...matchProps}
              editInfo={editInfo}
              setEditInfo={setEditInfo}
            />
          )}
        />
        <Route
          exact
          path={BEREAVEMENT_ACCOUNT_HOLDER_INFO}
          component={AccountHolderInfo}
        />
        <Route
          exact
          path={BEREAVEMENT_REPRESENTATIVE_INFO}
          render={matchProps => (
            <RepresentativeInfo
              {...matchProps}
              editInfo={editInfo}
              setEditInfo={setEditInfo}
            />
          )}
        />
        <Route exact path={BEREAVEMENT_DISCONNECT} component={Disconnection} />
        <Route exact path={BEREAVEMENT_STATEMENT} component={FinalStatement} />
        <Route
          exact
          path={BEREAVEMENT_REVIEW}
          render={matchProps => (
            <Review {...matchProps} setEditInfo={setEditInfo} />
          )}
        />
        <Route exact path={BEREAVEMENT_CONFIRMATION} component={Confirmation} />
        <Route component={BereavementEntry} />
      </Switch>
    </>
  );
};

export default Bereavement;
