import DOMPurify from 'dompurify';
import parse from 'html-react-parser';

const defaultSanitizationOptions = (
  allowedTags = [],
  allowedAttributes = [],
) => ({
  ALLOWED_TAGS: allowedTags,
  ALLOWED_ATTR: allowedAttributes,
});

/**
 * Sanitizes the HTML string for only known HTML attributes and tags,
 * @param {*} htmlString html string to sanitize
 * @param {*} allowedTags Allowed Tags during sanitization. [Default] Dont Allow anything.
 * @param {*} allowedAttributes allowed attributes during sanitization. [Default] Dont Allow anything.
 * @returns sanitized html string
 */
export const sanitizeHtmlString = (
  htmlString,
  allowedTags,
  allowedAttributes,
) => {
  const purify = DOMPurify(window);
  return purify.sanitize(
    htmlString,
    defaultSanitizationOptions(allowedTags, allowedAttributes),
  );
};

/**
 * Returns a sanitized HTML string as React Component
 * @returns HTML Parsed String
 */
export const HtmlParsedText = ({
  htmlString,
  allowedTags = [],
  allowedAttributes = [],
}) => {
  return parse(sanitizeHtmlString(htmlString, allowedTags, allowedAttributes));
};
