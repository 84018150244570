import React from 'react';

export default ({ className = '', ...props }) => (
  <span className={`svg-icon ${className}`} {...props}>
    <svg xmlns="http://www.w3.org/2000/svg" role="img" aria-hidden="true">
      <g fill="none" fillRule="evenodd">
        <path fill="#65D0A7" d="M21 0a21 21 0 100 42 21 21 0 000-42" />
        <path
          fill="#FFF"
          fillRule="nonzero"
          d="M31.5 12.8a1 1 0 011.49 1.31l-.08.1-15 15a1 1 0 01-1.32.08l-.1-.08-6-6a1 1 0 011.32-1.5l.1.08 5.29 5.3 14.3-14.3z"
        />
      </g>
    </svg>
  </span>
);
