import merge from 'deepmerge';

import common from './common';
import local from './local';
import dev from './dev';
import preProd from './pre-prod';
import stg from './stg';
import prod from './prod';
import qa from './qa';
import qa2 from './qa-two';

const getEnvFromUrl = () =>
  [
    [/local\.xfinity/, 'local'],
    [/mtm-dev\.dss-ops/, 'dev'],
    [/mtm-test\.dss-ops/, 'qa'],
    [/mtm-test-two\.dss-ops/, 'qa2'],
    [/prodtest\.www\.int\.xfinity/, 'pre-prod'],
    // www-support.xfinity.com is for Akamai staging, should use prod config
    [/www\.stg\.xfinity/, 'stg'],
    [/www-support\.xfinity/, 'prod'],
    [/www\.xfinity/, 'prod'],
  ].reduce(
    (str, environment) =>
      environment[0].test(window.location.host) ? environment[1] : str,
    'local',
  );

const getConfig = () => {
  let config;
  const env = getEnvFromUrl();

  switch (env) {
    case 'local':
      config = merge.all([common, local]);
      break;
    case 'qa':
      config = merge.all([common, qa]);
      break;
    case 'qa2':
      config = merge.all([common, qa2]);
      break;
    case 'pre-prod':
      config = merge.all([common, preProd]);
      break;
    case 'stg':
      config = merge.all([common, stg]);
      break;
    case 'prod':
      config = merge.all([common, prod]);
      break;
    case 'dev':
    default:
      config = merge.all([common, dev]);
  }

  return config;
};

export default getConfig;
