import React from 'react';
import { Form, Formik } from 'formik';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import FocusErrorField from '../../helpers/FocusErrorField';
import StreetAddress from '../form-controls/fields/StreetAddress';
import StreetAddress2 from '../form-controls/fields/StreetAddress2';
import Country from '../form-controls/fields/Country';
import City from '../form-controls/fields/City';
import State from '../form-controls/fields/State';
import ZipCode from '../form-controls/fields/ZipCode';
import { SPORTS_REFUND } from '../../helpers/routes';
import NavigationButtons from '../NavigationButtons';
import { submitPreferredBillingInfo } from '../../apps/SportsRefund/Actions/UpdateBillingAddress';

const PreferredBillingForm = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const initialValues = {
    streetAddress: '',
    streetAddress2: '',
    city: '',
    state: '',
    zipCode: '',
    country: 'USA',
  };

  const onSubmit = formValues => {
    const values = {
      streetAddress: formValues.streetAddress?.trim(),
      streetAddress2: formValues.streetAddress2?.trim(),
      city: formValues.city?.trim(),
      state: formValues.state?.trim(),
      zipCode: formValues.zipCode?.trim(),
      country: formValues.country?.trim(),
    };

    dispatch(submitPreferredBillingInfo(values));
    history.push(SPORTS_REFUND);
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validateOnBlur={false}
    >
      {() => (
        <Form noValidate>
          <div className="card-group text-align-left mb30">
            <div className="card-group__item">
              <div className="card">
                <div className="card__content">
                  <div className="form-control-group">
                    <div className="form-control-group__item">
                      <StreetAddress label="Address line 1" />
                    </div>
                  </div>

                  <div className="form-control-group">
                    <div className="form-control-group__item">
                      <StreetAddress2 label="Address line 2 (optional)" />
                    </div>
                  </div>

                  <div className="form-control-group">
                    <div className="form-control-group__item">
                      <Country label="Country" />
                    </div>
                  </div>

                  <div className="form-control-group">
                    <div className="form-control-group__item">
                      <City label="City" />
                    </div>
                  </div>

                  <div className="form-control-group form-control-group--flex-at-768">
                    <div className="form-control-group__item">
                      <State label="State" />
                    </div>
                    <div className="form-control-group__item">
                      <ZipCode label="Zip Code" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <FocusErrorField fieldOrders={Object.keys(initialValues)} />

          <NavigationButtons
            labelForNext="Save"
            labelForPrevious="Cancel"
            navLinkForBack={SPORTS_REFUND}
          />
        </Form>
      )}
    </Formik>
  );
};

export default PreferredBillingForm;
