import React, { useEffect, useState } from 'react';
import { PageSection, FormBoolean, Loading } from 'adc-ui-components';
import { Field, Form, Formik } from 'formik';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  ERROR,
  VIDEO_REVIEW,
  INELIGIBLE_CREDITS,
} from '../../../../helpers/routes';
import {
  dispatchSelectedVideoAsset,
  getVideoAsset,
  getVideoCreditEligibility,
} from '../VideoCreditActions';
import { dispatchSetModal } from '../../../../shared/actions/userMessagesActions';
import { UNHANDLED_ERROR } from '../../../../components/modals/ModalHandler';
import BooleanField from '../../../../components/form-elements/BooleanField';
import NavigationButtons from '../../../../components/NavigationButtons';
import VideoAssetContent from '../../../../components/VideoAssetContent';
import FocusErrorFirstBoolean from '../../../../helpers/FocusErrorFirstBoolean';
import PageTitle from '../../../../components/layout/PageTitle';
import { trackFiveStandards } from '../../../../helpers/logger/fiveStandards';

const VideoDetails = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const dateFormat = 'EEEE, MMMM d';
  const { video = {} } = useSelector(state => state.credits);
  const { customerGuid: custGuid, accountNumber } = useSelector(
    state => state.user.data,
  );
  const { videoAssets, loading } = video;
  const { assets } = videoAssets || {};
  const noOfVodAssetsToIncrement = 4;
  const [noOfVodAssetsVisible, setNoOfVodAssetsVisible] = useState(
    noOfVodAssetsToIncrement,
  );

  useEffect(() => {
    trackFiveStandards({
      id: 'VOD_CREDIT_REQUESTED',
      accountNumber,
      custGuid,
      interactionType: 'BILLING_PAYMENTS',
    });
    try {
      dispatch(getVideoAsset());
      if (!loading && !assets?.length) {
        trackFiveStandards({
          id: 'VOD_CREDIT_DENIED',
          accountNumber,
          custGuid,
          interactionType: 'BILLING_PAYMENTS',
          error:
            "We haven't seen any On Demand rentals or purchases made in the last 120 days.",
        });
      }
    } catch {
      history.push(ERROR);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, history]);

  const handleSubmit = async values => {
    const selectedAssetForCredit = assets.find(
      assetOption => assetOption.purchaseTokenID === values.assetOption,
    );
    dispatch(dispatchSelectedVideoAsset(selectedAssetForCredit));
    try {
      const { refundableFlag } = await dispatch(
        getVideoCreditEligibility(values.assetOption, selectedAssetForCredit),
      );
      // @todo: This will be routed to the actual sorry no credit available page.
      const pageToNavigate = refundableFlag ? VIDEO_REVIEW : INELIGIBLE_CREDITS;
      return history.push(pageToNavigate);
    } catch (error) {
      return dispatch(
        dispatchSetModal({ showModal: true, type: UNHANDLED_ERROR }),
      );
    }
  };

  const generateVideoAssetLabel = (
    type,
    title,
    price,
    orderDateTime,
    entityId,
  ) => (
    <VideoAssetContent
      title={title}
      titleClass="body2 mb0"
      orderDateTime={orderDateTime}
      dateFormat={dateFormat}
      type={type}
      price={price}
      page="details"
      priceClass="body4 fw500"
      typeClass="body3"
      entityId={entityId}
    />
  );

  const validateForm = ({ assetOption }) => {
    const errors = {};
    if (!assetOption) {
      errors.chooseVideoOption = 'Please choose an option to continue';
    }
    return errors;
  };

  /*
    @todo This will be replaced by cms response object and its corresponding loader variable
    as page is not to be rendered until those are available.
  */
  if (!videoAssets || loading) {
    return <Loading />;
  }

  let assetsIds = [];

  if (videoAssets && !loading) {
    assetsIds = assets.map(asset => asset.purchaseTokenID);
  }

  return (
    <PageSection className="page-section--banner">
      {/* TODO: render the header from CMS API once defined */}
      {assets.length > 0 && (
        <PageTitle
          title="Select a rental or purchase"
          subtitle="We'll check to see if you're eligible for a credit."
        />
      )}
      {assets.length === 0 && (
        <PageTitle
          title="You have no recent rentals or purchases"
          subtitle="We haven't seen any On Demand rentals or purchases made in the last 120 days."
        />
      )}
      <Formik
        onSubmit={handleSubmit}
        validate={validateForm}
        initialValues={{ assetOption: '' }}
      >
        {({ errors }) => (
          <Form>
            {errors.chooseVideoOption && (
              <div
                className="form-control__error error-top"
                id="err-msg"
                tabIndex="-1"
              >
                {errors.chooseVideoOption}
              </div>
            )}
            {assets && (
              <div className="card-group card-group--media mb24 text-align-left">
                {/* @TODO: Change the key value from titleAssetId to purchaseTokenID
             once mock response in removed from SSM */}
                {assets
                  .slice(0, noOfVodAssetsVisible)
                  .map(
                    ({
                      type,
                      title,
                      purchaseTokenID,
                      price,
                      orderDateTime,
                      entityId,
                    }) => (
                      <div className="card-group__item" key={purchaseTokenID}>
                        <div className="card card--has-media">
                          <FormBoolean
                            label={generateVideoAssetLabel(
                              type,
                              title,
                              price,
                              orderDateTime,
                              entityId,
                            )}
                            inputId={purchaseTokenID}
                            labelClass="body2"
                            className="radio"
                          >
                            <Field
                              id={purchaseTokenID}
                              name="assetOption"
                              component={BooleanField}
                              type="radio"
                              value={purchaseTokenID}
                              forceError={!!errors.chooseVideoOption}
                              aria-invalid={!!errors.chooseVideoOption}
                              aria-describedby="err-msg"
                            />
                          </FormBoolean>
                        </div>
                      </div>
                    ),
                  )}
                {noOfVodAssetsVisible < assets.length && (
                  <div className="card-group__item">
                    <div className="card card--view-more">
                      <div className="card__content">
                        <button
                          type="button"
                          onClick={() =>
                            setNoOfVodAssetsVisible(
                              noOfVodAssetsVisible + noOfVodAssetsToIncrement,
                            )
                          }
                          className="button button--text"
                        >
                          + View more
                        </button>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            )}
            <FocusErrorFirstBoolean booleanValues={assetsIds} />

            {assets.length > 0 && (
              <NavigationButtons
                navToPreviousPage
                labelForNext="Continue"
                labelForPrevious="Back"
                submitting={loading}
              />
            )}
            {assets.length === 0 && (
              <div className="text-center">
                <div className="action__item">
                  <a
                    className="button button--primary"
                    href="https://customer.xfinity.com/#/billing"
                    rel="noopener noreferrer"
                  >
                    Thanks, I&apos;m Done
                  </a>
                </div>
              </div>
            )}
          </Form>
        )}
      </Formik>
    </PageSection>
  );
};

export default VideoDetails;
