import React, { useState } from 'react';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
import { joinClass } from '../../helpers/component';
import {
  isWithinNDays,
  addDays,
  subtractDays,
  formatDate,
} from '../../helpers/date';
import DatePickerOverlay from './DatePickerOverlay';
import { getResolution } from '../../helpers/resolution';

const createClassName = (
  outageHistoryDate,
  field,
  shouldShowErrorHighlight,
) => {
  return joinClass(
    outageHistoryDate ? `${field.name}_outage_history_autopopulated` : '',
    shouldShowErrorHighlight && 'error',
  );
};

const DatePicker = ({
  selectedDay,
  setSelectedDay,
  setFieldValue,
  outageHistoryDate = '',
  field = {},
  className = '',
  forceError,
  id,
  name,
  after = 60,
  prior = 120,
  form: { submitCount, errors = {}, values = {}, touched = {}, handleBlur },
  ...props
}) => {
  const isFormSubmitted = !!submitCount;
  const hasAttemptedInput = !values[field.name] || touched[field.name];
  const shouldShowError =
    (isFormSubmitted || hasAttemptedInput) && errors[field.name];
  const shouldShowErrorHighlight = shouldShowError || forceError;
  const errorMessageId = `error_${id}`;
  const today = Date.now();
  let inputType;
  let format;
  if (getResolution() === 'mobile') {
    inputType = 'date';
    format = 'yyyy-MM-dd';
  } else {
    inputType = 'text';
    format = 'MM/dd/yyyy';
  }
  const [placeholder, setPlaceholder] = useState();
  const handleReset = (fieldName, setFormat, fieldId) => {
    if (fieldName.value === outageHistoryDate) {
      const todayDate = formatDate(today, setFormat);
      setFieldValue(fieldId || 'effectiveDate', todayDate);
      setSelectedDay(todayDate);
    }
  };
  return (
    <>
      {inputType === 'text' && (
        <DayPickerInput
          formatDate={formatDate}
          format={format}
          dayPickerProps={{
            modifiers: {
              // Day is set to 0 hours for the current day and then addition and subtraction is done.
              disabled: day => !isWithinNDays(day, after, prior),
              selected: selectedDay,
            },
          }}
          aria-invalid={!!shouldShowError}
          aria-describedby={errorMessageId}
          onDayChange={dayValue => {
            dayValue &&
              setFieldValue(id || 'effectiveDate', dayValue) &&
              setSelectedDay(dayValue);
          }}
          inputProps={{
            ...field,
            ...props,
            autoComplete: 'off',
            id,
            placeholder,
            type: inputType,
            format: { format },
            className: createClassName(
              outageHistoryDate,
              field,
              shouldShowErrorHighlight,
            ),
            onFocus: e => {
              handleReset(field, format, id);
              setPlaceholder(formatDate(today, format));
            },
            onBlur: e => {
              handleReset(field, format, id);
              setPlaceholder(formatDate(today, format));
            },
          }}
          {...field}
          {...props}
          overlayComponent={DatePickerOverlay}
          blur
        />
      )}
      {inputType === 'date' && (
        <input
          id={id}
          name="name"
          type={inputType}
          className={createClassName(
            outageHistoryDate,
            field,
            shouldShowErrorHighlight,
          )}
          aria-invalid={!!shouldShowError}
          aria-describedby={errorMessageId}
          // This adds the 60th day after and 120 days before it.
          max={formatDate(addDays(today, after), format)}
          min={formatDate(subtractDays(today, prior), format)}
          {...props}
          {...field}
          value={selectedDay}
          onChange={({ target: { value: dayValue } }) => {
            dayValue && setFieldValue(id, dayValue) && setSelectedDay(dayValue);
          }}
          onFocus={event => {
            handleReset(field, format, id);
            setPlaceholder(formatDate(today, format));
          }}
          onBlur={event => {
            handleReset(field, format, id);
            setPlaceholder(formatDate(today, format));
          }}
        />
      )}
      {shouldShowError && (
        <span id={errorMessageId} className="form-control__error" role="alert">
          {errors[field.name]}
        </span>
      )}
    </>
  );
};

export default DatePicker;
