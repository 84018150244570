export const detailsOutageMock = {
  cms: {
    reportIssue: {
      outageInfo: {
        description:
          'If you are in a current eligible outage, please try again once it has been resolved. Please note: outages caused by weather, power outage, or maintenance are ineligible for a credit.',
      },
    },
    credits: {
      credits: {
        creditInfoPage: {
          datesQuestion: 'Tell us more about your outage or loss of service.',
          servicesQuestion: 'Which services were affected?',
        },
      },
    },
  },
};

export const eligibilityApiMock = {
  startDate: '2020-01-01',
  endDate: '2020-01-02',
  lobs: 'VIDEO',
};
