import React from 'react';
import { PageSection } from 'adc-ui-components';
import IconCircleExclamation from '../../components/svgs/IconCircleExclamation';

const GenericError = () => {
  return (
    <PageSection className="ui-grey page-section--banner">
      <IconCircleExclamation className="mb6 svg-icon--42" />

      <div className="hgroup">
        <p className="heading4">ERROR</p>
        <h1 className="display3" tabIndex="-1">
          Sorry, This Isn’t Working Right Now
        </h1>
        <p className="body1">We are working to resolve this issue.</p>
      </div>

      <a
        className="button button--primary"
        href="https://www.xfinity.com/support/"
      >
        Back to Help and Support
      </a>
      <a className="button button--primary" href="https://xfinity.com">
        Xfinity Home
      </a>
    </PageSection>
  );
};

export default GenericError;
