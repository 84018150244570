import React from 'react';
import { FormControl } from 'adc-ui-components';
import { Field } from 'formik';
import InputField from '../../form-elements/InputField';
import { RequiredFactory } from '../../../helpers/validation/required';
import { MinFactory, MaxFactory } from '../../../helpers/validation/minMax';
import { hasNoDigit } from '../../../helpers/validation/nonDigit';

const vRequired = RequiredFactory();
const vMinLength = MinFactory();
const vMaxLength = MaxFactory();
const vNonDigit = hasNoDigit();

const minValidationString = 'name must be at least 2 characters';
const maxValidationString = 'name cannot exceed 30 characters';

const FirstNameField = ({ label, className, ...props }) => (
  <FormControl inputId="firstName" className={className} label={label}>
    <Field
      id="firstName"
      type="text"
      name="firstName"
      component={InputField}
      validate={value =>
        vRequired('Please enter first name')(value) ||
        vNonDigit('Please enter a valid first name')(value) ||
        vMinLength(2, `First ${minValidationString}`)(value) ||
        vMaxLength(30, `First ${maxValidationString}`)(value)
      }
      {...props}
    />
  </FormControl>
);

export default FirstNameField;
