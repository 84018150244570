import mtmClient from '../../../fetch/apiClient';
import { getIdmAuth } from '../../../helpers/auth';
import { SportsRefund } from '../../../api/apis';

export const GET_SPORTS_REFUND = 'GET_SPORTS_REFUND';
export const GET_SPORTS_REFUND_SUCCESS = 'GET_SPORTS_REFUND_SUCCESS';
export const GET_SPORTS_REFUND_FAILURE = 'GET_SPORTS_REFUND_FAILURE';

export const getSportsRefund = queryString => async dispatch => {
  try {
    const { accessToken } = await getIdmAuth();
    const response = await dispatch(
      dispatchGetSportsRefund(accessToken, queryString),
    ).payload;
    return dispatch(dispatchGetSportsRefundSuccess(response));
  } catch (error) {
    dispatch(dispatchGetSportsRefundFailure(error));
    throw error;
  }
};

const dispatchGetSportsRefund = (accessToken, queryString = '') => {
  const url = SportsRefund.concat(queryString);
  return {
    type: GET_SPORTS_REFUND,
    payload: mtmClient.fetch(url, {
      method: 'GET',
      sessionRequired: false,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }),
  };
};

const dispatchGetSportsRefundSuccess = payload => ({
  type: GET_SPORTS_REFUND_SUCCESS,
  payload,
});

const dispatchGetSportsRefundFailure = payload => ({
  type: GET_SPORTS_REFUND_FAILURE,
  payload,
});
