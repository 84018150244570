/* eslint-disable react/jsx-curly-brace-presence */
import React from 'react';
import { FormBoolean } from 'adc-ui-components';
import { Field, useFormikContext } from 'formik';
import BooleanField from '../form-elements/BooleanField';

const AccountActions = () => {
  const { errors } = useFormikContext();
  return (
    <>
      <div className="account-actions mb24">
        <div
          className={`card card--action-right-at-768 ${
            errors.type ? `card--error` : ''
          }`}
        >
          <div className="card__content">
            <FormBoolean
              label="Close the account permanently"
              inputId="closeAccountAction"
              labelClass="body2"
            >
              <Field
                id="closeAccountAction"
                name="type"
                component={BooleanField}
                type="radio"
                value="disconnect"
              />
            </FormBoolean>
          </div>
        </div>
        <div className={`card-group ${errors.type ? `card--error` : ''}`}>
          <div className="text-align-left card-group__item">
            <div className="card card__content">
              <FormBoolean
                label="Transfer account ownership to me"
                inputId="transferOwnershipAction"
                labelClass="body2"
              >
                <Field
                  id="transferOwnershipAction"
                  name="type"
                  component={BooleanField}
                  type="radio"
                  value="transfer"
                />
              </FormBoolean>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default AccountActions;
