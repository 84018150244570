export default {
  env: 'qa',
  kibanaApi: 'https://api-support-test.xfinity.com/logger/',
  apis: {
    ssm: 'https://api-staging.sc.xfinity.com',
    xapi: 'https://xapi.stg.xfinity.com',
  },
  oauthOptions: {
    idmClientId: 'dss-refund-preprod',
    clientId: 'mtm-account-change-dev',
  },
};
