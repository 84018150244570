export default {
  env: 'local',
  kibanaApi: 'https://api-support-test.xfinity.com/logger/',
  apis: {
    ssm: 'https://api-qa.sc.xfinity.com',
    xapi: 'https://xapi.stg.xfinity.com',
  },
  oauthOptions: {
    idmClientId: 'dss-refund-development',
    clientId: 'mtm-account-change-dev',
    iframeRedirectUri: '/token.html',
  },
};
