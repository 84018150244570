async function getJSON(response) {
  const { status } = response;
  if (status === 204 || status === 202) {
    return {};
  }
  const responseForError = response.clone();
  try {
    const json = await response.json();
    return json;
  } catch (error) {
    const body = await responseForError.text();
    const errObj = new Error('failed to parse response as JSON');
    errObj.details = { status, data: { body } };
    throw errObj;
  }
}

export default ({ fetch = window.fetch, logger = console }) => {
  const middleware = async (url, options = {}) => {
    const response = await fetch(url, options);
    const { ok, status } = response;
    const data = await getJSON(response);
    const responseObj = { data, status };

    if (ok && status < 400) {
      return data;
    }

    // throw if we didn't already return because OK
    throw responseObj;
  };

  return {
    fetch: middleware,
    logger,
  };
};
