import * as React from 'react';
import { useSelector } from 'react-redux';
import { PageSection } from 'adc-ui-components';

import { SPORTS_REFUND } from '../../../helpers/routes';
import { fallbackCancelUrl } from '../../../helpers/SportsRefundHelpers';

import PageTitle from '../../../components/layout/PageTitle';
import IconCircleExclamation from '../../../components/svgs/IconCircleExclamation';

const Error = () => {
  const { cancelUrl } = useSelector(state => state.sportsRefund);
  const cancelDest = cancelUrl || fallbackCancelUrl;
  return (
    <PageSection className="page-section--banner">
      <IconCircleExclamation className="mb12 svg-icon--42" />
      <PageTitle title="Looks like something went wrong" noFocus />
      <a className="button" href={cancelDest}>
        Back
      </a>
      <a
        className="button button--primary"
        href={`/support/account-management${SPORTS_REFUND}`}
      >
        Try Again
      </a>
    </PageSection>
  );
};
export default Error;
