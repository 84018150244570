import React from 'react';
import { FormControl } from 'adc-ui-components';
import { Field } from 'formik';
import InputField from '../../form-elements/InputField';
import { RequiredFactory } from '../../../helpers/validation/required';
import { MinFactory, MaxFactory } from '../../../helpers/validation/minMax';
import { hasNoDigit } from '../../../helpers/validation/nonDigit';

const vRequired = RequiredFactory();
const vMinLength = MinFactory();
const vMaxLength = MaxFactory();
const vNonDigit = hasNoDigit();

const minValidationString = 'name must be at least 2 characters';
const maxValidationString = 'name cannot exceed 30 characters';

const LastNameField = ({ label, className, ...props }) => (
  <FormControl inputId="lastName" label={label} className={className}>
    <Field
      id="lastName"
      type="text"
      name="lastName"
      component={InputField}
      validate={value =>
        vRequired('Please enter last name')(value) ||
        vNonDigit('Please enter a valid last name')(value) ||
        vMinLength(2, `Last ${minValidationString}`)(value) ||
        vMaxLength(30, `Last ${maxValidationString}`)(value)
      }
      {...props}
    />
  </FormControl>
);

export default LastNameField;
