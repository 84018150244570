export const generateEROneRequest = bereavementDetails => {
  const { customer, requester } = bereavementDetails;
  let { transaction = {}, billingAddress } = bereavementDetails;
  transaction = { ...transaction, category: 'bereavement' };
  const { type = '' } = transaction;
  if (type !== 'disconnect') {
    transaction = { ...transaction, effectiveDate: '' };
    billingAddress = { fullStreetAddress: '' };
  }
  return {
    customer,
    requester,
    transaction,
    billingAddress,
  };
};
