import React from 'react';
import { useLocation } from 'react-router-dom';
import CimaAuth from '../CimaAuth';
import CreditsApp from './CreditsApp';

const Credits = () => {
  const { pathname: redirectUrl, search: queryParams } = useLocation();
  const redirectWithParams = queryParams
    ? redirectUrl.concat(queryParams)
    : redirectUrl;
  return (
    <CimaAuth redirectTo={redirectWithParams}>
      <CreditsApp />
    </CimaAuth>
  );
};

export default Credits;
