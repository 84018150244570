import React, { useState } from 'react';
import { FormBoolean, FormControl } from 'adc-ui-components';
import { Field, Form, Formik } from 'formik';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  BEREAVEMENT_REPRESENTATIVE_INFO,
  BEREAVEMENT_STATEMENT,
} from '../../helpers/routes';
import { RequiredFactory } from '../../helpers/validation/required';
import BooleanField from '../form-elements/BooleanField';
import IconCalendar from '../svgs/Calendar';
import DatePicker from '../form-elements/DatePicker';
import {
  vDateSelectionRange,
  vValidDate,
} from '../../helpers/validation/dateValidation';
import NavigationButtons from '../NavigationButtons';
import { formatDate } from '../../helpers/date';
import { submitDisconnectDetails } from '../../apps/Bereavement/bereavementActions';
import FocusErrorField from '../../helpers/FocusErrorField';
import { getResolution } from '../../helpers/resolution';

const vRequired = RequiredFactory();

const DisconnectForm = () => {
  const mobileDateFormat = 'yyyy-MM-dd';
  const desktopDateFormat = 'MM/dd/yyyy';
  const bereavementDetails = useSelector(state => state.bereavement);
  const { disconnectDetails } = bereavementDetails || null;
  let disconnectInitialValues = { ...disconnectDetails };
  // Formatting the date to show as per the resolution as in store it is saved in MM/dd/yyyy format.
  if (disconnectDetails) {
    disconnectInitialValues = {
      ...disconnectInitialValues,
      effectiveDate:
        getResolution() === 'mobile'
          ? formatDate(disconnectInitialValues.effectiveDate, mobileDateFormat)
          : formatDate(
              disconnectInitialValues.effectiveDate,
              desktopDateFormat,
            ),
    };
  } else {
    disconnectInitialValues = {
      disconnectOption: 'disconnectNow',
      effectiveDate:
        getResolution() === 'mobile'
          ? formatDate(new Date(), mobileDateFormat)
          : formatDate(new Date(), desktopDateFormat),
    };
  }

  const [selectedDay, setSelectedDay] = useState(
    new Date(disconnectInitialValues.effectiveDate),
  );

  const dispatch = useDispatch();
  const history = useHistory();

  const handleSubmit = values => {
    // Formating the date is requried on the basis of option selected to disconnect now or other date.
    const payload = {
      ...values,
      effectiveDate:
        values.disconnectOption === 'disconnectNow'
          ? formatDate(new Date(), desktopDateFormat)
          : formatDate(values.effectiveDate, desktopDateFormat),
    };
    // Dispatching the form values to save it in bereavement store.
    dispatch(submitDisconnectDetails(payload));
    history.push(`${BEREAVEMENT_STATEMENT}`);
  };

  const handleValidate = values => {
    const errors = {};
    let validationText;
    if (values.disconnectOption === 'disconnectLater') {
      validationText =
        vRequired('Please select a date')(values.effectiveDate) ||
        vValidDate(values.effectiveDate) ||
        vDateSelectionRange(values.effectiveDate);
    }
    if (validationText) {
      errors.effectiveDate = validationText;
    } else {
      setSelectedDay(new Date(values.effectiveDate));
    }
    return errors;
  };

  return (
    <Formik
      id="disconnectForm"
      initialValues={disconnectInitialValues}
      validate={handleValidate}
      onSubmit={handleSubmit}
      validateOnChange
    >
      {({ values, setFieldValue }) => (
        <Form id="disconnectForm">
          <div className="card-group mb24">
            <div className="card-group__item">
              <div className="card">
                <div className="card__content">
                  <FormBoolean
                    label="Disconnect now"
                    inputId="disconnectNow"
                    labelClass="body2"
                  >
                    <Field
                      id="disconnectNow"
                      name="disconnectOption"
                      component={BooleanField}
                      type="radio"
                      value="disconnectNow"
                    />
                  </FormBoolean>
                </div>
              </div>
            </div>
            <div className="card-group__item">
              <div className="card">
                <div className="card__content text-align-left">
                  <FormBoolean
                    label="Select a different date to disconnect"
                    inputId="disconnectLater"
                    labelClass="body2"
                  >
                    <Field
                      id="disconnectLater"
                      name="disconnectOption"
                      component={BooleanField}
                      type="radio"
                      value="disconnectLater"
                    />
                  </FormBoolean>
                  {values.disconnectOption === 'disconnectLater' && (
                    <>
                      <p className="body2">
                        Select a date up to 120 days before or 60 days after
                        today.
                      </p>
                      <FormControl
                        className="date-picker"
                        inputId="effectiveDate"
                      >
                        <Field
                          id="effectiveDate"
                          name="effectiveDate"
                          component={DatePicker}
                          setFieldValue={setFieldValue}
                          selectedDay={selectedDay}
                          setSelectedDay={setSelectedDay}
                        />
                        <IconCalendar
                          className="date-picker__icon svg-icon--28 svg-icon--non-reactive"
                          aria-hidden
                        />
                      </FormControl>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
          <FocusErrorField fieldOrders={Object.keys(disconnectInitialValues)} />
          <NavigationButtons
            navLinkForBack={BEREAVEMENT_REPRESENTATIVE_INFO}
            labelForNext="Continue"
            labelForPrevious="Back"
          />
        </Form>
      )}
    </Formik>
  );
};
export default DisconnectForm;
