import React, { useRef } from 'react';
import { useLocation } from 'react-router-dom';

import IdmAuth from '../IdmAuth';
import SportsRefundApp from './SportsRefundApp';

import { SPORTS_REFUND } from '../../helpers/routes';
import { handleUrlParams } from '../../helpers/SportsRefundHelpers';

const SportsRefund = () => {
  const location = useLocation();

  const qaParams = useRef(handleUrlParams(location));
  const redirectUrl = location.search
    ? SPORTS_REFUND.concat(location.search)
    : SPORTS_REFUND;

  return (
    <IdmAuth redirectTo={redirectUrl}>
      <SportsRefundApp qaParams={qaParams.current} />
    </IdmAuth>
  );
};

export default SportsRefund;
