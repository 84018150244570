import React from 'react';
import { useHistory } from 'react-router-dom';
import { PageSection } from 'adc-ui-components';

import { useDispatch, useSelector } from 'react-redux';
import { issueLateFeeCredit } from '../LateFeeActions';

import NavigationButtons from '../../../../components/NavigationButtons';
import KeyValue from '../../../../components/KeyValue';

import { LATE_FEE_CONFIRMATION, CREDITS } from '../../../../helpers/routes';
import { formatDate } from '../../../../helpers/date';
import { dispatchSetModal } from '../../../../shared/actions/userMessagesActions';
import { UNHANDLED_ERROR } from '../../../../components/modals/ModalHandler';

const LateFeeLanding = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  // @TODO - Integrate with SSM APIs
  // These will be useSelector
  const { eligibility: { lateFee, lateFeeDate } = {} } =
    useSelector(state => state.credits.lateFee) || {};

  const dateFormat = 'MMMM d, yyyy';
  const formattedPaymentDate = formatDate(
    new Date(`${lateFeeDate} 00:00:00`), // Hard coding time to adjust for UTC
    dateFormat,
  );

  const requestData = [
    {
      label: 'Type of Request',
      value: 'Late Fee Credit',
    },
    {
      label: null,
      value: `You were charged a $${lateFee} late fee for missing a payment on ${formattedPaymentDate}.`,
    },
    {
      label: 'Credit Amount',
      value: `$${lateFee}`,
    },
  ];

  const handleGetAccountCredit = async () => {
    try {
      await dispatch(issueLateFeeCredit(lateFee));
      history.push(LATE_FEE_CONFIRMATION);
    } catch (error) {
      dispatch(dispatchSetModal({ showModal: true, type: UNHANDLED_ERROR }));
    }
  };

  return (
    <PageSection className="page-section--banner">
      <h1 className="mb24" tabIndex="-1">
        Review late fee credit
      </h1>
      <div className="card-group text-align-left">
        {requestData.map((item, idx) => (
          <div className="card-group__item" key={item.label}>
            <div className="card">
              <div className="card__content">
                {item.label ? (
                  // If the item has a label, return a key value component
                  // Else, return the text of the value
                  <KeyValue
                    className={idx !== 0 && 'key-value--inline'} // Don't apply key-value--inline class to first element per ux
                    key={item.label}
                    labelWrapperClass="mb0"
                    valueClass="fw600 mb0"
                    {...item}
                  />
                ) : (
                  <p className="body2">{item.value}</p>
                )}
              </div>
            </div>
          </div>
        ))}
      </div>
      <NavigationButtons
        navExternalLink={false}
        navLinkForBack={CREDITS}
        labelForNext="Get Account Credit"
        labelForPrevious="Back"
        handleSubmit={handleGetAccountCredit}
      />
    </PageSection>
  );
};

export default LateFeeLanding;
