import React from 'react';

/**
 * A green checkmark icon
 * ```jsx
 * <IconCheckGreen />
 * ```
 */
const IconCheckGreen = ({ className = '', ...props }) => {
  return (
    <span className={`svg-icon ${className}`} {...props}>
      <svg
        width="60"
        height="60"
        viewBox="0 0 60 60"
        xmlns="http://www.w3.org/2000/svg"
        role="img"
        aria-hidden="true"
      >
        <g fill="none" fillRule="evenodd">
          <path
            d="M29.992 59.5C46.29 59.5 59.5 46.29 59.5 29.992 59.5 13.695 46.29.484 29.992.484 13.695.484.484 13.694.484 29.992S13.694 59.5 29.992 59.5z"
            fill="#67D1A8"
            fillRule="nonzero"
          />
          <path
            stroke="#FFF"
            strokeWidth="2.5"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M14.996 32.58l8.293 8.294 20.73-20.732"
          />
        </g>
      </svg>
    </span>
  );
};

export default IconCheckGreen;
