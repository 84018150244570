import { SessionAPI } from '../../api/apis';
import mtmClient from '../../fetch/apiClient';
import {
  storeSession,
  getStoredSession,
  deleteStoredSession,
} from '../../helpers/session';

export const GET_SESSION = 'GET_SESSION';
export const GET_SESSION_SUCCESS = 'GET_SESSION_SUCCESS';
export const GET_SESSION_FAILURE = 'GET_SESSION_FAILURE';
export const DESTROY_SESSION = 'DESTROY_SESSION';
export const DESTROY_SESSION_SUCCESS = 'DESTROY_SESSION_SUCCESS';
export const DESTROY_SESSION_FAILURE = 'DESTROY_SESSION_FAILURE';

const dispatchGetSession = (data = {}) => {
  return {
    type: GET_SESSION,
    payload: mtmClient.fetch(SessionAPI, {
      method: 'POST',
      sessionRequired: false,
    }),
  };
};

const dispatchGetSessionSuccess = data => {
  return {
    type: GET_SESSION_SUCCESS,
    payload: data,
  };
};

const dispatchGetSessionFailure = error => ({
  type: GET_SESSION_FAILURE,
  payload: error,
});

const dispatchDestroySession = (data = {}) => {
  return {
    type: DESTROY_SESSION,
    payload: mtmClient.fetch(SessionAPI, { method: 'DELETE' }),
  };
};

const dispatchDestroySessionSuccess = data => {
  return {
    type: DESTROY_SESSION_SUCCESS,
    payload: data,
  };
};

const dispatchDestroySessionFailure = error => ({
  type: DESTROY_SESSION_FAILURE,
  payload: error,
});

export const getSession = () => async dispatch => {
  try {
    const storedSession = getStoredSession();
    if (storedSession) {
      return dispatch(dispatchGetSessionSuccess(storedSession));
    }
    storedSession && deleteStoredSession();
    const response = await dispatch(dispatchGetSession()).payload;
    storeSession(response, dispatch);
    return dispatch(dispatchGetSessionSuccess(response));
  } catch (error) {
    dispatch(dispatchGetSessionFailure(error));
    throw error;
  }
};

export const destroySession = () => async dispatch => {
  try {
    const session = getStoredSession();
    let payload;
    if (session) {
      payload = await dispatch(dispatchDestroySession()).payload;
    } else {
      payload = session;
    }
    return dispatch(dispatchDestroySessionSuccess(payload));
  } catch (error) {
    if (
      error.errorCode === 401 &&
      error.errorMessage === 'unauthorized: Session expired or not found'
    ) {
      return dispatch(dispatchDestroySessionSuccess(error)); // Destroy Session is successful if token  is already expired
    }
    return dispatch(dispatchDestroySessionFailure(error));
  } finally {
    deleteStoredSession();
  }
};
