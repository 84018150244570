import _ from 'lodash';
import md5 from 'crypto-js/md5';

// Generates payload for SSM request
export const generateRequest = async file => {
  const { name } = file;
  if (name) {
    const data = await encodeFile(file);
    const hashDigest = md5(data).toString(); // generates an MD5 hash
    return {
      file_name: name,
      documentStream: data,
      hash: hashDigest,
    };
  }
  return { error: 'Invalid File' };
};

// Generates Chunked Payloads
export const generateDocumentPayload = async file => {
  // Generate Common Payload Data
  const { name } = file;
  const data = await encodeFile(file);
  const hashDigest = md5(data).toString();

  // Break Data into Chunks
  const chunkLength = parseInt(4 * (4 / 3) * 2 ** 20, 10); // 4MB * (4/3 - conversion factor for base64 length) * 2^20 bytes per MB
  const chunks = _.chunk(data, chunkLength).map(chunk => chunk.join(''));

  // Generate Payloads
  const payloads = chunks.map((chunk, idx) => ({
    documentGUID: hashDigest,
    documentName: name,
    totalChunks: chunks.length,
    chunkSequenceId: idx + 1,
    documentStreamChunk: chunk,
  }));

  return payloads;
};

// Validates file size and type
export const validateFile = file => {
  const maxSize = 10 * 2 ** 20; // 10MB max * 1mb = 2^20 bytes
  const fileTypes = ['application/pdf', 'image/jpeg', 'image/jpg', 'image/png'];

  const { size, type } = file;

  return size < maxSize && fileTypes.includes(type);
};

// Generates a base64 document stream from the file
// Returns a promise; i.e. must be used in an async function
export const encodeFile = file =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
