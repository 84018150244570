import React from 'react';

import { useSelector, useDispatch } from 'react-redux';
import Routes from '../routes';
import { dispatchResetUserMessages } from '../shared/actions/userMessagesActions';
import ModalHandler from '../components/modals/ModalHandler';

const App = () => {
  const dispatch = useDispatch();
  const { showModal, modals } = useSelector(state => state.userMessages);
  return (
    <div>
      <div id="page-app" className="page-app" tabIndex="-1">
        {showModal && (
          <ModalHandler
            dismissable
            closeModal={() => dispatch(dispatchResetUserMessages())}
            modals={modals}
          />
        )}
        <Routes />
      </div>
    </div>
  );
};

export default App;
