import React from 'react';
import { Formik, Form } from 'formik';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import FirstLastName from '../form-controls/FirstLastNameField';
import Email from '../form-controls/fields/Email';
import ConfirmEmail from '../form-controls/fields/ConfirmEmail';
import Mobilephone from '../form-controls/fields/MobilePhone';
import Address from '../form-controls/fields/Address';

import {
  BEREAVEMENT_ACCOUNT_HOLDER_INFO,
  BEREAVEMENT_DISCONNECT,
  BEREAVEMENT_REVIEW,
} from '../../helpers/routes';
import NavigationButtons from '../NavigationButtons';
import { submitRepresentativeInfo } from '../../apps/Bereavement/bereavementActions';
import { formatPhone } from '../../helpers/validation/phone';
import FocusErrorField from '../../helpers/FocusErrorField';

const RepresentativeUserForm = ({ editInfo, setEditInfo }) => {
  const history = useHistory();
  const bereavementDetails = useSelector(state => state.bereavement);
  const { loading } = useSelector(state => state.typeAhead);
  const { requester: repInfo, transaction } = bereavementDetails || {};
  const { type } = transaction || '';
  const dispatch = useDispatch();
  // Order required to show the focus in particular error field
  const initialFormValues = {
    email: '',
    confirmEmailAddress: '',
    phone: '',
    firstName: '',
    lastName: '',
    fullStreetAddress: '',
  };
  let initialValues;
  if (!repInfo) {
    initialValues = initialFormValues;
  } else {
    initialValues = {
      email: repInfo.email,
      confirmEmailAddress: repInfo.email,
      firstName: repInfo.firstName,
      lastName: repInfo.lastName,
      fullStreetAddress: repInfo.fullStreetAddress,
      phone: formatPhone(repInfo.phone),
    };
  }

  const onSubmit = repFormValues => {
    const { email, phone, firstName, lastName, fullStreetAddress } =
      repFormValues || '';
    const values = {
      email: email?.toLowerCase().trim(),
      phone: phone?.replace(/-/g, '').trim(),
      firstName: firstName?.trim(),
      lastName: lastName?.trim(),
      fullStreetAddress: fullStreetAddress?.trim(),
    };
    const formValuesToSubmit = {};
    // Filtering out the blank key values.
    for (const key of Object.keys(values)) {
      if (values[key] !== '') {
        formValuesToSubmit[key] = values[key];
      }
    }
    // Dispatching the form values to get it saved into the store.
    dispatch(submitRepresentativeInfo(formValuesToSubmit));
    if (editInfo) {
      setEditInfo(false);
      return history.goBack();
    }
    if (type === 'transfer') {
      return history.push(`${BEREAVEMENT_REVIEW}`);
    }
    return history.push(`${BEREAVEMENT_DISCONNECT}`);
  };

  const validate = formValues => {
    const errors = {};
    const values = {
      email: formValues.email?.toLowerCase().trim(),
      confirmEmailAddress: formValues.confirmEmailAddress?.toLowerCase().trim(),
      firstName: formValues.firstName?.trim(),
      lastName: formValues.lastName?.trim(),
      fullStreetAddress: formValues.fullStreetAddress?.trim(),
      phone: formValues.phone?.trim(),
    };
    if (!values.email) {
      errors.email = 'Please enter a valid email address';
    }
    if (!values.confirmEmailAddress) {
      errors.confirmEmailAddress = 'Please enter matching email addresses';
    }
    if (!values.firstName) {
      errors.firstName = 'Please enter the first name';
    }
    if (!values.lastName) {
      errors.lastName = 'Please enter the last name';
    }

    if (!values.fullStreetAddress) {
      errors.fullStreetAddress = 'Please enter the home address';
    }
    if (!values.phone) {
      errors.phone = 'Please enter your phone number';
    }
    if (values.phone && values.phone.length < 12) {
      errors.phone = 'Please enter a valid phone number';
    }
    if (values.email && values.confirmEmailAddress) {
      if (values.email !== values.confirmEmailAddress) {
        errors.confirmEmailAddress = 'Please enter matching email addresses';
      }
    }
    return errors;
  };
  return (
    <Formik
      initialValues={initialValues}
      validate={validate}
      onSubmit={onSubmit}
      validateOnBlur={false}
    >
      {() => (
        <Form noValidate>
          <div className="card-group text-align-left mb30">
            <div className="card-group__item">
              <div className="card">
                <div className="card__content">
                  <div className="form-control-group form-control-group--flex-at-768">
                    <div className="form-control-group__item">
                      <Email label="Your email address" />
                    </div>
                    <div className="form-control-group__item">
                      <ConfirmEmail label="Confirm your email address" />
                    </div>
                  </div>
                  <div className="form-control-group__item">
                    <Mobilephone
                      className="mb0"
                      label="Your phone number"
                      formatPhone={formatPhone}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="card-group__item">
              <div className="card">
                <div className="card__content">
                  <FirstLastName
                    classLastName="mb0"
                    labelFirstName="Your first name"
                    labelLastName="Your last name"
                  />
                </div>
              </div>
            </div>
            <div className="card-group__item">
              <div className="card">
                <div className="card__content">
                  <div
                    className={`${loading &&
                      'form-control-group form-control-group-loading--flex-at-768'}`}
                  >
                    <div className={`${loading && 'form-control-group__item'}`}>
                      <Address
                        className="pb6 mb0"
                        label="Your home address"
                        origin="valasis"
                      />
                    </div>
                    {loading && (
                      <div className="form-control-group__item-loading">
                        <div className="loading-circle" />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <FocusErrorField fieldOrders={Object.keys(initialFormValues)} />
          <NavigationButtons
            labelForNext="Continue"
            labelForPrevious="Back"
            navLinkForBack={BEREAVEMENT_ACCOUNT_HOLDER_INFO}
          />
        </Form>
      )}
    </Formik>
  );
};

export default RepresentativeUserForm;
