export default {
  'MISAPPLIED PAYMENT': [
    {
      option: 'My payment has not posted to my account',
      id: 'paymentNotPosted',
    },
    {
      option: 'My payment was applied to the wrong account',
      id: 'paymentWrongAcct',
    },
  ],
  'HAZARDOUS WIRES': [
    {
      option: 'Outside cable wires present a tripping or injury risk',
      id: 'wireInjury',
    },
    {
      option:
        'Outside cable wires are laying across streets, walkways or vehicles',
      id: 'wireLying',
    },
    {
      option: 'Outside cable wires are obstructing traffic',
      id: 'wireTraffic',
    },
  ],
  REFUND: [
    {
      option: 'I accidentally paid my bill twice',
      id: 'refundMultiplePayment',
    },
    {
      option: 'I used the wrong credit card to pay my bill',
      id: 'refundPaymentMethod',
    },
    {
      option: 'I paid more than I intended',
      id: 'refundOverpayment',
    },
    {
      option: 'I did not receive my refund check',
      id: 'refundNotReceived',
    },
    {
      option: 'My refund check was issued in the wrong name',
      id: 'refundWrongName',
    },
  ],
};
