import Config from '../config/index';

/**
 * Read feature flag value from config
 * @param {*} key key of the feature flag / feature flag name
 * @param {*} defaultValue defaultValue to return, if feature flag is NOT FOUND
 */
export const getFeatureFlagValue = (key, defaultValue = false) => {
  const isFeatureFlagAvailable = key in Config().features;
  if (!isFeatureFlagAvailable) {
    return defaultValue;
  }
  return Config().features[key];
};
