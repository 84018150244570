export const track = ({ componentName, description, instanceID, eventPage }) =>
  document.body.dispatchEvent(
    new CustomEvent('c-tracking-log-dom', {
      detail: {
        eventMethod: 'component-Track',
        component: [
          {
            componentInfo: {
              componentID: 'mtm-ineligible-credits',
              componentName,
              description,
              instanceID,
            },
          },
        ],
        type: 'c-tracking-log-dom',
        eventName: 'aside c-tracking-log-dom',
        eventPage,
        eventPhase: 3,
      },
      composed: true,
      bubbles: true,
    }),
  );
