import React from 'react';
import { PageSection } from 'adc-ui-components';
import PageTitle from '../layout/PageTitle';

const Ineligible = () => {
  return (
    <PageSection className="page-section--banner">
      <PageTitle
        title="Looks like you're a current customer"
        titleClass="mb12"
        noFocus
      />
      <p className="body2 mb24">
        Great! That means your Regional Sports Network courtesy adjustment will
        automatically be processed to your account. You can go to My Account
        billing to check its availability, or learn more about courtesy
        adjustments.
      </p>
      <a
        href="https://xfinity.com/sportsadjustments"
        rel="noopener noreferrer"
        className="button p24"
      >
        Learn More
      </a>
      <a
        href="https://customer.xfinity.com/#/billing"
        rel="noopener noreferrer"
        className="button button--primary"
      >
        Go to Billing
      </a>
    </PageSection>
  );
};

export default Ineligible;
