import React, { useLayoutEffect, useRef } from 'react';

const PageTitle = ({
  title,
  titleClass = 'mb24',
  subtitle,
  subtitleClass = 'mb24 body2',
  noFocus,
}) => {
  const titleRef = useRef(null);

  useLayoutEffect(() => {
    if (titleRef.current && !noFocus) {
      titleRef.current.focus();
    }
  }, [noFocus]);

  return (
    <>
      <h1 className={`${titleClass}`} ref={titleRef} tabIndex="-1">
        {title}
      </h1>
      {subtitle && <p className={`${subtitleClass}`}>{subtitle}</p>}
    </>
  );
};

export default PageTitle;
