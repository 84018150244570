import {
  SUBMIT_TRANSACTION,
  SUBMIT_ACCOUNT_HOLDER_INFO,
  SUBMIT_REPRESENTATIVE_INFO,
  SUBMIT_DISCONNECT_DETAILS,
  CREATE_ER_ONE_TICKET,
  CREATE_ER_ONE_TICKET_SUCCESS,
  CREATE_ER_ONE_TICKET_FAILURE,
  SUBMIT_FINAL_STATEMENT_ADDRESS,
} from './bereavementActions';

const INITIAL_STATE = {};

export default (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
    // Store transaction type
    case SUBMIT_TRANSACTION:
      return {
        ...state,
        transaction: {
          ...state.transaction,
          ...action.payload,
        },
      };
    // Saving the account holder details into the store.
    case SUBMIT_ACCOUNT_HOLDER_INFO:
      return {
        ...state,
        customer: action.payload,
      };
    // Saving the representative details into the store.
    case SUBMIT_REPRESENTATIVE_INFO:
      return {
        ...state,
        requester: action.payload,
      };
    // Saving the disconnect details for populating the disconnect form and updating effectiveDate in transaction for SSM
    case SUBMIT_DISCONNECT_DETAILS:
      return {
        ...state,
        disconnectDetails: action.payload,
        transaction: {
          ...state.transaction,
          effectiveDate: action.payload.effectiveDate,
        },
      };
    // Saving the billing address selected on final statement page.
    case SUBMIT_FINAL_STATEMENT_ADDRESS:
      return {
        ...state,
        billingAddress: {
          fullStreetAddress: action.payload,
        },
      };
    case CREATE_ER_ONE_TICKET:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case CREATE_ER_ONE_TICKET_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        messageId: action.payload.MessageId,
      };
    case CREATE_ER_ONE_TICKET_FAILURE:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};
