import React from 'react';
import UnhandledError from './UnhandledError';
import TimeoutHandleError from './TimeoutHandleError';
import RetryError from './RetryError';

export const TIMEOUT_ERROR = 'TIMEOUT_ERROR';
export const RETRY_ERROR = 'RETRY_ERROR';
export const UNHANDLED_ERROR = 'UNHANDLED_ERROR';

const ModalHandler = ({ modals, closeModal, dismissable }) => {
  switch (modals.type) {
    case TIMEOUT_ERROR:
      return <TimeoutHandleError />;
    case RETRY_ERROR:
      return <RetryError />;
    case UNHANDLED_ERROR:
    default:
      return (
        <UnhandledError closeModal={closeModal} dismissable={dismissable} />
      );
  }
};
export default ModalHandler;
