export const showAddressList = (
  address,
  manuallyClosed,
  addressSelected,
  renderResults,
) => !manuallyClosed && address && !addressSelected && renderResults;

export const updateAddress = (
  value,
  debounceGetAddresses,
  setAddress,
  manuallyClosed,
  setManuallyClosed,
  setRenderResults,
) => {
  if (manuallyClosed) {
    setManuallyClosed(false);
  }
  if (value) {
    debounceGetAddresses(value, origin);
    setRenderResults(true);
  }
  setAddress(value);
};

export const setFieldValues = (
  name,
  setAddress,
  setFieldValue,
  result = {
    fullStreetAddress: '',
    houseNumber: '',
    streetName: '',
    streetSuffix: '',
    city: '',
    state: '',
    zip5: '',
  },
) => {
  let address1 = '';
  const buildAddressString = val => {
    if (val) {
      address1 += address1.length > 0 ? ` ${val}` : `${val}`;
    }
  };
  buildAddressString(result.houseNumber);
  buildAddressString(result.streetName);
  buildAddressString(result.streetSuffix);
  setAddress(address1);
  setFieldValue(name, address1);

  let address2 = '';
  const buildAddress2String = (unitType, unitValue) => {
    if (unitType && unitValue) {
      address2 +=
        address2.length > 0
          ? ` ${unitType} ${unitValue}`
          : `${unitType} ${unitValue}`;
    }
  };
  buildAddress2String(result.unitType1, result.unitValue1);
  buildAddress2String(result.unitType2, result.unitValue2);
  setFieldValue('streetAddress2', address2);

  setFieldValue('city', result.city);
  setFieldValue('state', result.state);
  setFieldValue('zipCode', result.zip5);
};
