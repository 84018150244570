import React, { useEffect, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { PageSection } from 'adc-ui-components';
import { track } from '../../../helpers/logger/impressionTracking';
import { trackFiveStandards } from '../../../helpers/logger/fiveStandards';
import { HtmlParsedText } from '../../../helpers/htmlParser';
import { getFeatureFlagValue } from '../../../helpers/featureFlags';

const ThanksIAmDone = `Thanks, I'm done`;
/**
 * https://etwiki.sys.comcast.net/pages/viewpage.action?spaceKey=HS&title=Outage+Credit+-+Credit+Ineligible%3A+New+Ineligible+Reasons
 */
const AdjustmentResolutionErrorCode = {
  '4101': 'ADJUSTMENT-RESOLUTION-4101',
};

const Ineligible = () => {
  const {
    eligibility: {
      ineligibleReason: eligibilityIneligibleReason = null,
      ineligibleCode: eligibilityIneligibleCode,
      showVideoOption,
    } = {},
    outage: {
      ineligibleReason: outageIneligibleReason = null,
      ineligibleCode: outageIneligibleCode,
    } = {},
    video: {
      ineligibleReason: videoIneligibleReason = null,
      ineligibleCode: videoIneligibleCode,
    },
  } = useSelector(state => state.credits);
  const { customerGuid: custGuid, accountNumber } = useSelector(
    state => state.user.data,
  );

  // Check destination URL for query string that indicates user navigated from the billing flow
  const { search } = useLocation();
  const isBillingFlow = new URLSearchParams(search).get('src') === 'billing';

  const description =
    eligibilityIneligibleReason ||
    outageIneligibleReason ||
    videoIneligibleReason ||
    "We're unable to process your request.";

  const isOutageCreditIneligibleCode4101 =
    outageIneligibleCode === AdjustmentResolutionErrorCode[4101];

  const featureFlagFor4101FormattedMessageAndMultipleCTAs = getFeatureFlagValue(
    'showFormattedMessageAndMultipleCTAsFor4101IneligibleCode',
    false,
  );

  const enableTextUpdatesCTAFor4101 =
    featureFlagFor4101FormattedMessageAndMultipleCTAs &&
    isOutageCreditIneligibleCode4101;

  const defaultOutageCTAButtonText = defaultText =>
    enableTextUpdatesCTAFor4101 ? ThanksIAmDone : defaultText;

  const outageCTAButtonClass = `button ${
    enableTextUpdatesCTAFor4101 ? '' : 'button--primary'
  }`;

  // for any ineligible reason that is not outage
  const defaultCTAButton = (
    <a
      href={
        isBillingFlow
          ? 'https://customer.xfinity.com/billing/services'
          : 'https://customer.xfinity.com/'
      }
      rel="noopener noreferrer"
      className="button button--primary"
    >
      Back to My Account
    </a>
  );
  const outageCTAButton = (
    <a
      href={
        isBillingFlow
          ? 'https://customer.xfinity.com/billing/services'
          : 'https://www.xfinity.com/support/status/'
      }
      rel="noopener noreferrer"
      className={outageCTAButtonClass}
    >
      {defaultOutageCTAButtonText(
        isBillingFlow ? 'Back to My Account' : 'Visit Status Center',
      )}
    </a>
  );

  const sendMeTextUpdatesCTAButton = (
    <a
      href="https://www.xfinity.com/support/enroll-sms/"
      rel="noopener noreferrer"
      className="button button--primary"
    >
      Send me text updates
    </a>
  );

  // Might not NEED to but doing this, but using useCallback in case this component rerenders
  // it won't create a new unnecessary function unless one of the codes change
  const impressionTrackingInfo = useCallback(() => {
    switch (true) {
      case !!eligibilityIneligibleCode:
        return {
          componentName: 'Ineligible',
          description: 'Ineligible',
          instanceID: eligibilityIneligibleCode,
          eventPage: 'resi|selfservice|help & support|credits|ineligible',
        };
      case !!outageIneligibleCode:
        return {
          componentName: 'Outage Ineligible',
          description: 'Outage Ineligible',
          instanceID: outageIneligibleCode,
          eventPage:
            'resi|selfservice|help & support|credits|outage ineligible',
        };
      case !!videoIneligibleCode:
        return {
          componentName: 'Video Ineligible',
          description: 'Video Ineligible',
          instanceID: videoIneligibleCode,
          eventPage: 'resi|selfservice|help & support|credits|video ineligible',
        };
      default:
        return null;
    }
  }, [eligibilityIneligibleCode, outageIneligibleCode, videoIneligibleCode]);

  const trackingInfo = impressionTrackingInfo();
  useEffect(() => {
    trackingInfo && track(trackingInfo);

    if (outageIneligibleCode) {
      trackFiveStandards({
        id: 'OUTAGE_CREDIT_DENIED',
        accountNumber,
        custGuid,
        interactionType: 'BILLING_PAYMENTS',
        error: outageIneligibleReason,
      });
    } else if (videoIneligibleCode || !showVideoOption) {
      trackFiveStandards({
        id: 'VOD_CREDIT_DENIED',
        accountNumber,
        custGuid,
        interactionType: 'BILLING_PAYMENTS',
        error: videoIneligibleReason,
      });
    }
  }, [
    accountNumber,
    custGuid,
    eligibilityIneligibleCode,
    impressionTrackingInfo,
    outageIneligibleCode,
    outageIneligibleReason,
    showVideoOption,
    trackingInfo,
    videoIneligibleCode,
    videoIneligibleReason,
  ]);

  return (
    <PageSection className="page-section--banner">
      <h1 className="mb24">
        Your account isn&apos;t eligible for a credit at this time
      </h1>
      <p className="body2 mb24">
        {featureFlagFor4101FormattedMessageAndMultipleCTAs ? (
          <HtmlParsedText
            htmlString={description}
            allowedTags={['b', 'br', 'strong']}
          />
        ) : (
          description
        )}
      </p>
      {/* Navigation Buttons / CTAs */}
      <div className="d-flex align-items-center justify-content-center flex-wrap">
        {outageIneligibleCode ? (
          <>
            {outageCTAButton}
            {enableTextUpdatesCTAFor4101 && sendMeTextUpdatesCTAButton}
          </>
        ) : (
          <>{defaultCTAButton}</>
        )}
      </div>
    </PageSection>
  );
};

export default Ineligible;
