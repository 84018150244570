import React, { Component } from 'react';
import GenericError from './GenericError';

export default class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error) {
    this.setState({
      hasError: true,
    });
  }

  render() {
    const { hasError } = this.state;

    if (hasError) {
      return <GenericError />;
    }
    const { children } = this.props;

    return children;
  }
}
