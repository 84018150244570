import React from 'react';
import { useSelector } from 'react-redux';
import { PageSection } from 'adc-ui-components';
import ConfirmationIcon from '../../../../components/svgs/IssueReportingConfirmationIcon';
// import NavigationButtons from '../../../../components/NavigationButtons';

const LateFeeConfirmation = () => {
  const { eligibility: { lateFee } = {} } =
    useSelector(state => state.credits.lateFee) || {};

  const confimationData = [
    {
      id: 'creditText',
      value: `We have credited $${lateFee} to your account balance due to a previous late payment.`,
    },
    {
      id: 'setUpAutopay',
      heading: `Never miss a payment again`,
      value: `Sign up for automatic payments in My Account. `,
      link: `https://payments.xfinity.com/autopay`,
      linkText: `Set Up Automatic Payments`,
    },
  ];
  return (
    <PageSection className="page-section--banner">
      <div className="mb24">
        <ConfirmationIcon />
      </div>
      <h1 className="mb24">Credit Applied</h1>
      <div className="card-group text-align-left">
        {confimationData.map(item => (
          <div className="card-group__item" key={item.id}>
            <div className="card">
              <div className="card__content">
                {item.heading && <p className="heading3 mb6">{item.heading}</p>}
                <div className="body2">
                  {item.value}
                  {item.link && (
                    <a href={item.link} rel="noopener noreferrer">
                      {item.linkText ? item.linkText : item.link}
                    </a>
                  )}
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className="text-center">
        <div className="action__item">
          <a
            className="button button--primary"
            href="https://xfinity.com/billing"
            rel="noopener noreferrer"
          >
            Thanks, I&apos;m Done
          </a>
        </div>
      </div>
    </PageSection>
  );
};

export default LateFeeConfirmation;
