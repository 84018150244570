import React from 'react';
import IconCheckGreen from './svgs/IconCheckGreen';
import { joinClass } from '../helpers/component';

const Pushdown = ({ className, accountNumber, serviceAddress }) => (
  <div className={joinClass('pushdown', className)}>
    <div className="pushdown__wrapper">
      <div className="pushdown__icon">
        <IconCheckGreen className="svg-icon--36" />
      </div>
      <div className="pushdown__content">
        <div className="hgroup">
          <h3 className="body2">Great! We&apos;ve confirmed your account</h3>
          <p className="body3">{`Account Number: ${accountNumber}`}</p>
          <p className="body3">{`Service Address: ${serviceAddress}`}</p>
        </div>
      </div>
    </div>
  </div>
);

export default Pushdown;
