import React, { useState, createRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormikContext } from 'formik';

import { joinClass } from '../../helpers/component';
import Upload from '../svgs/Upload';
import { uploadDocument } from '../../apps/Bereavement/uploadDocumentsActions';
import { validateFile } from '../../helpers/documentUpload';
import { UploadDetails } from './UploadDetails';

const DocumentUploader = ({ className }) => {
  // "useFormikContext() is a custom React hook that will return all Formik state and helpers via React Context."
  // Here, it gives access to validation errors, connecting the uploader to the Formik state.
  // See https://jaredpalmer.com/formik/docs/api/useFormikContext for more information.
  const { errors } = useFormikContext();
  const [status, setStatus] = useState('');
  const [errorText, setErrorText] = useState('');

  // Grab documents object from store and convert to an array of values
  const documents = useSelector(state =>
    Object.values(state.uploadDocuments.documents),
  );

  // Create static ref for fileInput
  const fileInput = createRef();
  const dispatch = useDispatch();

  // Set uploader-specific errors each time Formik is validated
  useEffect(() => {
    if (errors) {
      setErrorText(errors.document);
    }
  }, [errors]);

  const onDragEnter = event => {
    event.preventDefault();
    event.stopPropagation();
  };

  const onDragLeave = event => {
    setStatus('dragLeave');
    event.preventDefault();
  };

  const onDragOver = event => {
    setStatus('dragOver');
    event.preventDefault();
  };

  const onSelect = async event => {
    event.preventDefault();
    event.stopPropagation();
    const {
      target: { files },
    } = event;
    await uploadFiles(files);
  };

  const onDrop = async event => {
    setStatus('drop');
    event.preventDefault();
    const {
      dataTransfer: { files },
    } = event;
    await uploadFiles(files);
  };

  const uploadFiles = async files => {
    // Reset error text each time a user tries to upload a new file
    setErrorText('');

    // Max files = 10
    if (files.length > 10 - documents.length) {
      setErrorText('You may only upload a maximum of 10 files at this time.');
      return;
    }

    // Filter files not meeting validation criteria, and proceed to upload valid files.
    const badFiles = [...files].filter(file => !validateFile(file));
    if (badFiles.length) {
      setErrorText(
        'One or more of your files was not uploaded because it exceeds 10MB or is an unsupported file type.',
      );
    }
    const validFiles = [...files].filter(file => validateFile(file));
    validFiles.forEach(file => {
      if (validateFile(file)) {
        dispatch(uploadDocument(file));
      }
    });
  };

  return (
    <div
      className="mb6"
      onDragEnter={onDragEnter}
      onDragLeave={onDragLeave}
      onDragOver={onDragOver}
      onDrop={onDrop}
    >
      <div className={joinClass('card', 'document-uploader', className)}>
        <div className="card__content">
          <div
            className={`card__drop-zone ${
              status === 'dragOver' ? 'dragging' : ''
            }${errorText ? 'error' : ''}`}
            onClick={() => fileInput.current.click()}
            onKeyPress={e =>
              e.key === 'Enter' ? fileInput.current.click() : null
            }
            role="button"
            tabIndex="0"
          >
            <Upload className="svg-icon--40 mb12" />
            <p>
              Drag and drop files here or
              <span role="link" tabIndex="0" className="link">
                &nbsp;browse files&nbsp;
              </span>
              to upload
            </p>
            <input
              className="visuallyhidden"
              tabIndex="-1"
              type="file"
              accept=".pdf, .jpg, .jpeg, .png"
              onChange={e => {
                onSelect(e);
                e.target.value = null;
              }}
              ref={fileInput}
            />
            <p className="body4">
              Max: 10 files / 10MB per file
              <br />
              File type: PDF, JPG, PNG
            </p>
          </div>
          {errorText && (
            <span className="form-control__error body4">{errorText}</span>
          )}
          <UploadDetails documents={documents} />
        </div>
      </div>
    </div>
  );
};

export default DocumentUploader;
