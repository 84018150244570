import React from 'react';
import { FormControl } from 'adc-ui-components';
import { Field } from 'formik';
import InputField from '../../form-elements/InputField';
import { AccountValidation } from '../../../helpers/validation/accountNumber';

const vAccount = AccountValidation();
const vMessage = 'Please enter a valid 16-digit account number';

export const defaultAccountValidation = vAccount(vMessage);
export const defaultValidate = value => defaultAccountValidation(value);

const AccountNumber = ({
  label,
  className,
  validate = defaultValidate,
  optional,
  ...props
}) => (
  <FormControl className={className}>
    <div className="form-control__label">
      <label htmlFor="accountNumber">
        {label}
        {optional && (
          <span className="form-control__label--helper">(optional)</span>
        )}
      </label>
    </div>
    <Field
      id="accountNumber"
      type="text"
      name="accountNumber"
      component={InputField}
      validate={validate}
      {...props}
    />
  </FormControl>
);

export default AccountNumber;
