import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Formik, Form } from 'formik';
import { PageSection } from 'adc-ui-components';

import { useHistory } from 'react-router-dom';
import NavigationButtons from '../../../components/NavigationButtons';
import AccountActions from '../../../components/form-controls/AccountActions';
import TermsAndConditionsCheckbox from '../../../components/form-controls/TermsAndConditionsCheckbox';
import DocumentUploader from '../../../components/DocumentUploader';
import { dispatchSubmitTransaction } from '../bereavementActions';
import {
  BEREAVEMENT,
  BEREAVEMENT_ACCOUNT_HOLDER_INFO,
} from '../../../helpers/routes';
import PageTitle from '../../../components/layout/PageTitle';

const DocumentUpload = ({ editInfo, setEditInfo }) => {
  const documents = useSelector(state => state.uploadDocuments.documents);
  const { type = 'disconnect', acceptTerms = false, termsViewed = false } =
    useSelector(state => state?.bereavement?.transaction) || {};
  const history = useHistory();
  const dispatch = useDispatch();

  const validateForm = values => {
    const errors = {};
    if (
      Object.values(documents).filter(document => document.status === 'done')
        .length < 1
    ) {
      errors.document = 'You must provide at least one document.';
    }

    if (
      Object.values(documents).filter(
        document => document.status === 'uploading',
      ).length > 0
    ) {
      errors.document =
        'Please allow for your uploads to complete before continuing.';
    }

    if (!values.type) {
      errors.type = 'You must select what you would like to do to the account.';
    }

    if (!values.acceptTerms) {
      errors.terms = 'You must agree to the terms and conditions.';
    }

    if (!values.termsViewed) {
      errors.terms = 'You must review the terms and conditions.';
    }

    return errors;
  };

  const onSubmit = values => {
    dispatch(dispatchSubmitTransaction(values));
    if (editInfo) {
      setEditInfo(false);
      history.goBack();
    } else {
      history.push(BEREAVEMENT_ACCOUNT_HOLDER_INFO);
    }
  };

  return (
    <PageSection className="page-section--banner">
      <PageTitle title="Choose what you would like to do" />
      <div className="mb24">
        <Formik
          validate={validateForm}
          validateOnChange={false}
          validateOnBlur={false}
          onSubmit={onSubmit}
          initialValues={{
            type,
            acceptTerms,
            termsViewed,
          }}
        >
          <Form>
            <AccountActions />
            <h2 className="mb24">Help us verify some information</h2>
            <p className="mb24">
              Please provide either a valid form of your identification (like
              your state-issued driver&apos;s license, ID card, or U.S.
              passport) or the account holder&apos;s death certificate
            </p>
            <DocumentUploader />
            <TermsAndConditionsCheckbox />
            <NavigationButtons
              navLinkForBack={BEREAVEMENT}
              labelForNext="Continue"
              labelForPrevious="Cancel"
            />
          </Form>
        </Formik>
      </div>
    </PageSection>
  );
};

export default DocumentUpload;
