import authHeaderMiddleware from './middleware/authHeader';
import parseResponseMiddleware from './middleware/parseResponse';
import loggingMiddleware from './middleware/logging';
import logger from '../helpers/logger';

// this is equivalent to
// const logger = loggingMiddleware({ fetch: window.fetch, logger})
// const authHeaders = authHeaderMiddleware(logger);
// const client = parseResponseMiddleware(authHeaders)
// logger returns an object with its middleware as fetch
// authHeaderMiddlware takes loggingMiddleware's return as props and returns an object
// parseResponseMiddleware takes authHeaderMiddleware return as props
const client = [
  loggingMiddleware,
  authHeaderMiddleware,
  parseResponseMiddleware,
].reduce(
  (fetchClient, middleware) => {
    return middleware(fetchClient);
  },
  { fetch: window.fetch, logger },
);

export default {
  fetch: client.fetch,
};
