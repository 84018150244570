import React from 'react';
import { PageSection } from 'adc-ui-components';
import { useSelector } from 'react-redux';
import ConfirmationIcon from '../../../components/svgs/ConfirmationIcon';
import PageTitle from '../../../components/layout/PageTitle';

const Confirmation = () => {
  const pageInfo = {
    title: 'Equipment Return',
    body:
      'You can return equipment associated with the closed account to any Xfinity Store or UPS partner location. Find the one nearest to you via the link below.',
    subtext:
      'Note: Equipment must be returned to receive refunds associated with the account.',
    info: `We'll be in touch by email when the process is complete, or if there's any additional information needed.`,
    xfinityLink: 'https://www.xfinity.com/local/store-offers',
    upsLink: 'https://www.theupsstore.com/tools/find-a-store',
    redirect: 'https://www.xfinity.com/support/account-management',
  };
  const bereavementDetails = useSelector(state => state.bereavement);
  const { transaction } = bereavementDetails || {};
  const { type } = transaction || '';
  const showReturnInstructions = type === 'disconnect';
  return (
    <PageSection className="page-section--banner">
      <div className="mb24">
        <ConfirmationIcon />
      </div>
      <PageTitle
        title="Thank you for taking the time to complete these steps. You've done
        all you need."
        subtitle={pageInfo.info}
      />
      <div className="text-align-left mb24">
        {showReturnInstructions && (
          <div className="card card--return-info">
            <div className="card__content">
              <h3>{pageInfo.title}</h3>
              <p className="body2">{pageInfo.body}</p>
              <p className="body2">{pageInfo.subtext}</p>
              <div className="ma-grid">
                <div className="ma-grid__col--1of3 ">
                  <a
                    href={pageInfo.xfinityLink}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Find Xfinity Locations
                  </a>
                </div>
                <div className="ma-grid__col--1of3 ">
                  <a
                    href={pageInfo.upsLink}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Find UPS Locations
                  </a>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="text-center">
        <div className="action__item">
          <a
            className="button button--primary"
            href={pageInfo.redirect}
            rel="noopener noreferrer"
          >
            Close
          </a>
        </div>
      </div>
    </PageSection>
  );
};

export default Confirmation;
