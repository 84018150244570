import React, { useEffect } from 'react';
import { PageSection } from 'adc-ui-components';
import { useSelector } from 'react-redux';
import ConfirmationIcon from '../../../../components/svgs/IssueReportingConfirmationIcon';
import VideoAssetContent from '../../../../components/VideoAssetContent';
import PageTitle from '../../../../components/layout/PageTitle';
import { trackFiveStandards } from '../../../../helpers/logger/fiveStandards';

const VideoConfirmation = () => {
  const { video = {} } = useSelector(state => state.credits);
  const { customerGuid: custGuid, accountNumber } = useSelector(
    state => state.user.data,
  );
  const { selectedAssetForCredit = {} } = video;
  const {
    title = '',
    type = '',
    orderDateTime = '',
    entityId = '',
    issuedAmount = '',
  } = selectedAssetForCredit;
  const dateFormat = 'EEEE, MMMM d';

  useEffect(() => {
    trackFiveStandards({
      id: 'VOD_CREDIT_CONFIRMED',
      accountNumber,
      custGuid,
      interactionType: 'BILLING_PAYMENTS',
    });
  }, [accountNumber, custGuid]);

  return (
    <PageSection className="page-section--banner">
      <div className="mb24">
        <ConfirmationIcon />
      </div>
      {/* TODO: render the header from CMS API once defined */}
      <PageTitle
        title="Credit Applied"
        subtitle="You'll receive an email and/or text confirmation within the next few minutes. The credit will be reflected in your account, on the billing page, within 10 minutes. You may need to refresh the page to see the applied credit."
      />
      <div className="card-group text-align-left mb24">
        <div className="card-group__item">
          <div className="card">
            <div className="card__content">
              {/* TODO: render the below content from CMS API once defined */}
              <div className="body2">
                {`We have credited $${Math.abs(issuedAmount)}
                to your account balance due to your On Demand issue. We
                apologize for any inconvenience.`}
              </div>
            </div>
          </div>
        </div>
        <div className="card-group__item card-group__item--asset-confirmation">
          <div className="card card--has-media">
            <VideoAssetContent
              title={title}
              titleClass="body2"
              orderDateTime={orderDateTime}
              dateFormat={dateFormat}
              type={type}
              typeClass="body2"
              entityId={entityId}
            />
          </div>
        </div>
        <div className="card-group__item">
          <div className="card">
            <div className="card__content">
              {/* TODO: render the below content from CMS API once defined */}
              <div className="body2 fw500">Protect your future purchases</div>
              <div className="body2">
                Create a pin to help you make secure On Demand purchases.
                {/* TODO: update the Link once the URL is known */}
                <a
                  className="button button--text"
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.xfinity.com/support/articles/x1-parental-controls-turn-puchase-protection-on-off"
                >
                  &nbsp;Set Up Pin
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="text-center">
        <div className="action__item">
          <a
            className="button button--primary"
            href="https://xfinity.com/billing"
            rel="noopener noreferrer"
          >
            Thanks, I&apos;m Done
          </a>
        </div>
      </div>
    </PageSection>
  );
};

export default VideoConfirmation;
