import { SET_MODAL, RESET_USER_MESSAGES } from '../actions/userMessagesActions';

const INITIAL_STATE = {};

export default function(state = INITIAL_STATE, action = {}) {
  switch (action.type) {
    case SET_MODAL:
      return {
        ...state,
        modals: {
          ...state.modals,
          type: action.payload.type,
        },
        showModal: action.payload.showModal,
      };
    case RESET_USER_MESSAGES:
      return {
        ...state,
        modals: {},
        showModal: false,
      };
    default:
      return state;
  }
}
