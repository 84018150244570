import {
  UPLOAD_DOCUMENT,
  UPLOAD_DOCUMENT_SUCCESS,
  UPLOAD_DOCUMENT_FAILURE,
  DELETE_DOCUMENT,
  DELETE_DOCUMENT_FAILURE,
  DELETE_DOCUMENT_SUCCESS,
} from './uploadDocumentsActions';

const INITIAL_STATE = {
  documents: {},
  loading: false,
  error: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UPLOAD_DOCUMENT:
      return {
        ...state,
        loading: true,
        error: false,
        documents: {
          ...state.documents,
          ...action.payload,
        },
      };
    case UPLOAD_DOCUMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        documents: {
          ...state.documents,
          ...action.payload,
        },
      };
    case UPLOAD_DOCUMENT_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        documents: {
          ...state.documents,
          [action.payload.hash]: {
            ...state.documents[action.payload.hash],
            loading: false,
            status: 'failed',
          },
        },
      };
    case DELETE_DOCUMENT:
      return {
        ...state,
        loading: true,
        error: false,
        documents: {
          ...state.documents,
          [action.payload]: {
            ...state.documents[action.payload],
            status: 'deleting',
          },
        },
      };
    case DELETE_DOCUMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        documents: {
          ...state.documents,
        },
      };
    case DELETE_DOCUMENT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        ...state,
        documents: {
          ...state.documents,
          [action.payload.hash]: {
            ...state.documents[action.payload.hash],
            status: 'done',
          },
        },
      };
    default:
      return state;
  }
};
