import React from 'react';
import { FormControl } from 'adc-ui-components';
import { Field } from 'formik';
import AddressTypeAhead from '../../form-elements/AddressTypeAhead';

const Address = ({ label, className, ...props }) => (
  <FormControl inputId="fullStreetAddress" label={label} className={className}>
    <Field
      id="fullStreetAddress"
      type="text"
      name="fullStreetAddress"
      component={AddressTypeAhead}
      {...props}
    />
  </FormControl>
);

export default Address;
