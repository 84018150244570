import React from 'react';
import { joinClass } from '../helpers/component';

const KeyValueGroup = props => {
  const { children, itemClass, className } = props;
  const childArray = Array.isArray(children) ? children : [children];
  return (
    <div className={joinClass('key-value-group', className)}>
      {childArray.map(child => (
        <div
          className={joinClass('key-value-group__item', itemClass)}
          key={child.key}
        >
          {child}
        </div>
      ))}
    </div>
  );
};
export default KeyValueGroup;
