import React from 'react';

const InfoNoticeCard = ({ authorization, ...props }) => {
  if (!authorization) {
    return (
      <div className="card card--warning card--action-right mb24">
        <div className="card__content">
          <p>
            Please note: Any checks will be mailed to the billing address we
            have on file if it differs from the service address listed above. To
            change the billing address call 1-800-Xfinity.
          </p>
        </div>
      </div>
    );
  }
  return (
    <div className="card card--success card--action-right mb24">
      <div className="card__content">
        <p>
          If you need more information about the courtesy adjustment, you can
          <a href="https://xfinity.com/sportsadjustments">
            &nbsp;learn more on our FAQ page
          </a>
          .
        </p>
      </div>
    </div>
  );
};

export default InfoNoticeCard;
