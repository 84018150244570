import { useEffect } from 'react';
import { useFormikContext } from 'formik';

const FocusErrorField = ({ fieldOrders = [] }) => {
  const { errors, isSubmitting, isValidating } = useFormikContext();

  useEffect(() => {
    if (isSubmitting && !isValidating) {
      /* 
      Getting the first error field from the fieldOrders and matching it
      against the errors object since formikcontext is prone to random orders. 
      */
      const errorField = fieldOrders.find(field =>
        Object.keys(errors).includes(field),
      );
      const selector = `[name=${errorField}]`;
      const errorElement = document.querySelector(selector);
      if (errorElement) {
        errorElement.focus();
      }
    }
  });
  return null;
};

export default FocusErrorField;
