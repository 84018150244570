import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { PageSection } from 'adc-ui-components';
import ReviewCard from '../../../components/ReviewCard';
import NavigationButtons from '../../../components/NavigationButtons';
import { postOutageCredit } from './OutageCreditActions';
import { dispatchSetModal } from '../../../shared/actions/userMessagesActions';
import { UNHANDLED_ERROR } from '../../../components/modals/ModalHandler';

import { OUTAGE_DETAILS, OUTAGE_CONFIRMATION } from '../../../helpers/routes';

import { formatDate } from '../../../helpers/date';
import PageTitle from '../../../components/layout/PageTitle';

const OutageReview = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  // Check destination URL for query string that indicates user navigated from the billing flow
  const { search } = useLocation();
  const isBillingFlow = new URLSearchParams(search).get('src') === 'billing';

  const {
    outage: {
      startDate: userSelectedStartDate,
      endDate: userSelectedEndDate,
      outageStartDate,
      outageEndDate,
      outageEligibleLOBs,
      creditAmount,
      loading,
    },
  } = useSelector(state => state.credits) || {};

  const {
    credits: {
      creditReviewPage: { title },
    },
  } = useSelector(state => state.cms?.credits);

  /** @TODO these three variables will be created in the reducer and passed to this component.
   * The details page will make an eligibility check and store the info
   */

  const formattedStartDate = formatDate(outageStartDate, 'EEEE, MMMM d');
  const formattedEndDate = formatDate(outageEndDate, 'EEEE, MMMM d');

  const outageReviewData = [
    { label: 'Type of Request', value: 'Outage or Loss of Service Credit' },
    [
      { label: 'Issue Start Date', value: formattedStartDate },
      { label: 'Issue End Date', value: formattedEndDate },
      { label: 'Services Impacted', value: outageEligibleLOBs.join(', ') },
    ],
    { label: 'Credit Amount', value: `$${Math.abs(creditAmount).toFixed(2)}` },
  ];

  const handleSubmit = async () => {
    const finalOutageData = {
      startDate: formatDate(userSelectedStartDate, 'yyyy-MM-dd'),
      endDate: formatDate(userSelectedEndDate, 'yyyy-MM-dd'),
      outageEligibleLOBs,
      creditAmount,
    };
    try {
      await dispatch(postOutageCredit(finalOutageData));
      history.push(OUTAGE_CONFIRMATION);
    } catch (error) {
      dispatch(
        dispatchSetModal({
          showModal: true,
          type: UNHANDLED_ERROR,
        }),
      );
    }
  };

  return (
    <PageSection className="page-section--banner">
      <PageTitle title={title} />
      <ReviewCard cardData={outageReviewData} />
      <NavigationButtons
        labelForNext="Get Account Credit"
        labelForPrevious="Back"
        handleSubmit={handleSubmit}
        navLinkForBack={
          isBillingFlow ? OUTAGE_DETAILS + search : OUTAGE_DETAILS
        }
        navExternalLink={false}
        submitting={loading}
      />
    </PageSection>
  );
};

export default OutageReview;
