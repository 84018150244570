import {
  GET_SESSION,
  GET_SESSION_SUCCESS,
  GET_SESSION_FAILURE,
  DESTROY_SESSION,
  DESTROY_SESSION_SUCCESS,
  DESTROY_SESSION_FAILURE,
} from '../actions/sessionActions';

const INITIAL_STATE = { loading: true };

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_SESSION:
      return { ...state, loading: true, error: false };
    case GET_SESSION_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
      };
    case GET_SESSION_FAILURE:
      return { ...state, loading: false, error: action.payload };
    case DESTROY_SESSION:
      return { ...state, loading: true };
    case DESTROY_SESSION_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
      };
    case DESTROY_SESSION_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
