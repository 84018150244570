import { useEffect } from 'react';
import { useFormikContext } from 'formik';

const FocusErrorFirstBoolean = ({ booleanValues = [] }) => {
  const { isSubmitting, isValidating } = useFormikContext();

  useEffect(() => {
    if (isSubmitting && !isValidating) {
      const errorValue = booleanValues[0];

      const selector = `[value='${errorValue}']`;
      const errorElement = document.querySelector(selector);
      if (errorElement) {
        errorElement.focus();
      }
    }
  });
  return null;
};

export default FocusErrorFirstBoolean;
