import isFunction from 'lodash/isFunction';

const metricToJSON = metric =>
  isFunction(metric.toJSON) ? metric.toJSON() : metric;

const markStartName = id => `${id}-start`;
const markEndName = id => `${id}-end`;

const markData = logger => {
  return {
    collectTimings: () => {
      window.setTimeout(() => {
        if (
          window.performance &&
          window.performance.getEntries instanceof Function
        ) {
          window.performance
            .getEntries()
            .filter(
              t => t.entryType === 'paint' || t.entryType === 'navigation',
            )
            .filter(t => t.type === 'navigate')
            .map(metricToJSON)
            .forEach(metric =>
              logger.logServiceCall({ ...metric, event: 'collectTimings' }),
            );
        }
      }, 500);
    },

    markStart: markId => {
      window.performance.mark(markStartName(markId));
    },

    markEnd: (markId, logData = {}) => {
      const startName = markStartName(markId);
      const endName = markEndName(markId);

      window.performance.mark(endName);

      try {
        performance.measure(markId, startName, endName);
        const metric = metricToJSON(performance.getEntriesByName(markId).pop());
        logger.logServiceCall({ ...metric, event: 'measure', data: logData });
      } catch (e) {} // eslint-disable-line no-empty
    },
  };
};

export default markData;
